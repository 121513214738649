import { DOCS_DEL } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// удаляем документ
export const fetchDocDel = async (docsid) => {
  try {
    // отправляем запрос на удаление документа
    const responsedocsdel = await fetch(`${DOCS_DEL}${docsid}`, {
      method: 'GET',
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await responsedocsdel.json();
    console.log('data без обновления токенов: ', data);

    // в случае протухания токена обновляем пару "token - rtoken"
    if (responsedocsdel.status === 401 || data.loginstatus === 7) {
      // перезапись токенов
      await updateTokens();

      // снова отправляем запрос 
      const responsedocsdel = await fetch(`${DOCS_DEL}${docsid}`, {
        method: 'GET',
        headers: {
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        },
      });
      const data = await responsedocsdel.json();
      console.log('data с обновлением токенов: ', data);
    }
  } catch (err) {
    console.log(err.message);
  }
};
