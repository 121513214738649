// форматирует дату до гоблинского формата
export const antigoblin = (array) => {
  const arr = array.split('-');

  arr.shift();

  arr.push(array.split('-')[0]);

  return arr.join('-')
};
