// контролируем инпуты, валидируем ввод, показываем предупреждение и вкл откл кнопку
const handleInputChange = (e, pattern, setShow, set) => {
  if (pattern.test(e.target.value) && e.target.value.length !== 0) {
    setShow(true);
  } else {
    setShow(false);
  }
  set(e.target.value);
};

// контролируем инпуты, запрет ввода неразрешенных символов, 
export const handleInputChangeStop = (e, pattern, set) => {
  if (pattern.test(e.target.value)) {
    set(e.target.value);
  }
};

export default handleInputChange;
