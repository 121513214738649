import st from './NewsLists.module.css';
import { Newscard } from '../Newscard/Newscard.jsx';
import { v4 as uuidv4 } from 'uuid';
// import { news_list } from '../../data/news_list_json.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsList } from 'src/store/news/getNewsList';
import { Helmet } from 'react-helmet-async';


export const NewsLists = (currentList) => {
  const { newsList } = useSelector(state => state.newsListReducer.dataNewsList);
  const { status } = useSelector(state => state.newsListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!newsList) {
      dispatch(fetchNewsList());
      // console.log('=====================');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество элементов на странице
  const newsQuantity = 6;

  // индекс последней новости
  const lastNewsIndex = currentPageNumber * newsQuantity;

  // индекс первой заявки
  const firstNewsIndex = lastNewsIndex - newsQuantity;
  // ===== ↑ пагинация ↑ =====

  if (status === 'success') {
    // ===== пагинация продолжение по условию=====
    // список элементов текущей страницы
    const newsCurrentList = newsList.slice(firstNewsIndex, lastNewsIndex);

    // общее количество элементов
    let totalNewsQuantity = newsList.length;

    // список номеров-кнопок страниц
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalNewsQuantity / newsQuantity); i++) {
      pages.push(i);
    }

    // функция кнопки следующая страница
    const nextPage = () => {
      setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
    };

    // функция кнопки предыдущая страница
    const prevPage = () => {
      setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
    };
    // ===== ↑ пагинация ↑ =====

    return (
      <>
        <Helmet>
          <title>Новости компании | LIFTUP</title>
          <meta
            name="description"
            content="Новости компании, мероприятия, награды и успехи LIFTUP."
          />
        </Helmet>

        <main className={`${st.news} container`}>
          <h1 className={st.title}>Новости</h1>

          <div className={st.flashlight1}></div>
          <div className={st.flashlight2}></div>

          <ul className={st.newscard_list}>
            {
              newsCurrentList.map(item =>
                <li className={st.newscard_item} key={uuidv4()}>
                  <Newscard
                    newsid={item.newsid}
                    imagelink={item.imagelink}
                    newsdate={item.newsdate.slice(0, -9).split('-').reverse().join('-')}
                    newsname={item.newsname}
                    newspreview={item.newspreview}
                  />
                </li>)
            }
          </ul>

          {/* пагинация */}
          <div className={st.pagination}>
            <button
              className={newsQuantity < totalNewsQuantity ? st.pagination_btn_prev : st.hidden}
              onClick={() => {
                prevPage();
                window.scrollTo(0, 0);
              }}>
            </button>
            {
              pages.map((item) => {
                return (
                  <button
                    key={uuidv4()}
                    onClick={() => {
                      setCurrentPageNumber(item);
                      window.scrollTo(0, 0);
                    }}
                    className={
                      pages.length === 1 ? st.hidden :
                        item === currentPageNumber
                          ? st.pagination_item_active
                          : st.pagination_item
                    }>
                    {item}
                  </button>
                );
              })
            }
            <button
              className={newsQuantity < totalNewsQuantity ? st.pagination_btn_next : st.hidden}
              onClick={() => {
                nextPage();
                window.scrollTo(0, 0);
              }}>
            </button>
          </div>
        </main>
      </>
    )
  } else {
    return (
      <main className={`${st.news} container`}>
        <h1 className={st.title}>Новости</h1>

        <div className={st.flashlight2}></div>

        <div className={st.preloader}><p>загрузка новостей...</p></div>
      </main>
    )
  }
};
