import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EQUIP_LIST_ORG } from 'src/consts.js'; // ручка не рабочая
import { updateTokens } from 'src/utils/updateTokens.js';

// получаем список оборудования организации
export const fetchEquipmentList = createAsyncThunk(
  'equipmentList/fetchEquipmentList',

  async (orgUuid) => {
    const res = await fetch(`${EQUIP_LIST_ORG}${orgUuid}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
        // заголовок с токеном для проверки
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      }
    });

    const data = await res.json();

    // в случае просроченного токена обновляем пару "token - rtoken"
    if (res.status === 401 || data.loginstatus === 7) {
      // перезапись токенов
      await updateTokens();

      const res = await fetch(`${EQUIP_LIST_ORG}${orgUuid}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF8',
          'User-Agent': 'LiftupSite/1.0.0',
          // заголовок с токеном для проверки
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        }
      }
      );
      const data = await res.json();
      return data;
    } else if (res.ok) {
      return data
    } else {
      return { 'equipmentOfOrganizatioList': [] };
    }

    // console.log('res: ', res);
    // return data;
  }
);

const initialState = {
  status: 'idle',
  dataEquipmentList: {},
  error: null,
};

const equipmentListSlice = createSlice({
  name: 'equipmentList',
  initialState,
  reducers: {
    removeEquipmentList: (state) => {
      state.dataEquipmentList = {};
    }
  },

  extraReducers: (builder) => {
    builder
      // при загрузке сайта
      .addCase(fetchEquipmentList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchEquipmentList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataEquipmentList = action.payload;
      })
      .addCase(fetchEquipmentList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataEquipmentList = action.error.message;
      })
  }
});

export const { removeEquipmentList } = equipmentListSlice.actions;

export default equipmentListSlice.reducer;
