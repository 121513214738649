import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOGIN_GET, LOGIN_GET2, LOGIN_POST } from '../../consts.js';
import { updateTokens } from 'src/utils/updateTokens.js';
import getCookie from 'src/utils/getCookie.js';

// ПРИ АВТОРИЗАЦИИ
// ПОЛУЧАЕМ ТОКЕНЫ и ЮЗЕРА ОТПРАВИВ ЛОГИН и ПАРОЛЬ по кнопке "ВОЙТИ"
export const fetchAuth = createAsyncThunk(
  'auth/fetchAuth',

  async (bodyObj) => {
    const response = await fetch(LOGIN_POST, {
      method: 'POST',
      body: JSON.stringify(bodyObj),
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Content-type': 'application/json; charset=UTF-8',
      }
    });

    // в data лежат token и rtoken
    const data = await response.json();

    // получаем одноразовый ключ для сквозной авторизации
    const headerlink = response.headers.get('link');

    // помещаем токен в локалсторадж
    if (data.token !== null) {
      localStorage.setItem('tokenlu', data.token);
    }
    // помещаем токен в куки на срок один месяц
    if (data.rtoken !== null) {
      document.cookie = `rtokenlu=${data.rtoken}; path=/; max-age=2592000`;
    }
    // помещаем одноразовый ключ в локалсторадж
    if (headerlink !== null) {
      localStorage.setItem('otk', headerlink);
    }

    if (response.status === 200 && data.loginstatus === 5) {
      // отправляем GET запрос с tokenlu на получение юсера
      const res = await fetch(LOGIN_GET, {
        headers: {
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        },
      });

      const data = await res.json();

      if (res.status === 401 || data.loginstatus === 7) {
        // не менять это на "updateTokens()"
        // получаем rtokenlu из кук
        const cookie = getCookie();
        const rtoken = cookie.rtokenlu;

        // отправляем GET запрос с rtokenlu на получение юсера
        const response = await fetch(LOGIN_GET, {
          headers: {
            'User-Agent': 'LiftupSite/1.0.0',
            'Authorization': `Bearer ${rtoken}`
          },
        });

        const data = await response.json();

        // помещаем токен в локалсторадж
        if (data.token.token !== null) {
          localStorage.setItem('tokenlu', data.token.token);
        }
        // помещаем рефрештокен в куки на срок один месяц
        if (data.token.rtoken !== null) {
          document.cookie = `rtokenlu=${data.token.rtoken}; path=/; max-age=2592000`;
        }

        console.log('отработал rtokenlu, перезаписаны значения токенов в хранилищах');

        return data;
      }

      console.log('отработал tokenlu');

      return data;
    }

    return data;
  }
);

// ПРИ ПЕРЕЗАГРУЗКЕ СТРАНИЦЫ
// ПОЛУЧАЕМ ЮЗЕРА ОТПРАВИВ 'tokenlu' и ЕСЛИ ОН ПРОСРОЧЕН, ТО БЕРЕМ 'rtoken'
export const fetchUser = createAsyncThunk(
  'auth/fetchUser',

  async () => {
    const token = localStorage.getItem('tokenlu');

    const response = await fetch(LOGIN_GET, {
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        Authorization: `Bearer ${token}`
      },
    });

    const data = await response.json();

    if (response.status === 401 || data.loginstatus === 7) {
      // не менять это на "updateTokens()"
      // получаем rtokenlu из кук
      const cookie = getCookie();
      const rtoken = cookie.rtokenlu;

      // отправляем GET запрос с rtokenlu на получение юсера
      const response = await fetch(LOGIN_GET, {
        headers: {
          'User-Agent': 'LiftupSite/1.0.0',
          Authorization: `Bearer ${rtoken}`,
        },
      });

      const data = await response.json();

      // если rtokenlu просрочен или не валиден, очищаем localStorage и cookie, чтобы избежать повторных запросов при перезагрузках
      if (response.status === 401 || data.loginstatus === 7) {
        localStorage.removeItem('tokenlu');

        document.cookie = `rtokenlu=; expires=${new Date(0)}; path=/;`;

        window.location.href = '/signin/author';
      }

      // помещаем токен в локалсторадж
      if (data.token.token !== null) {
        localStorage.setItem('tokenlu', data.token.token);
      }
      // помещаем токен в куки на срок один месяц
      if (data.token.rtoken !== null) {
        document.cookie = `rtokenlu=${data.token.rtoken}; path=/; max-age=2592000`;
      }

      console.log('отработал rtokenlu, перезаписаны значения токенов в хранилищах');

      return data;
    }

    console.log('отработал tokenlu');

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataAuth: {},
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeAuth: (state) => {
      state.dataAuth = {};
    }
  },

  extraReducers: (builder) => {
    builder
      // при авторизации по кнопке "Войти"
      .addCase(fetchAuth.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAuth.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataAuth = action.payload;
      })
      .addCase(fetchAuth.rejected, (state, action) => {
        state.status = 'failed';
        state.dataAuth = action.error.message;
      })
      // при перезагрузке страницы
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataAuth = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.dataAuth = action.error.message;
      })
  }
});

export const { removeAuth } = authSlice.actions;

export default authSlice.reducer;
