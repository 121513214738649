import st from './DeptClBase.module.css';
import { useEffect, useState } from 'react';
import { ReactComponent as IconSetting } from '../../../../img/icon_setting.svg';
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from 'src/components/Overlay/Overlay';
import { ClBaseAdd } from './components/ClBaseAdd/ClBaseAdd.jsx';
import { ClBasePreview } from './components/ClBasePreview/ClBasePreview.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientbaseList } from 'src/store/clientbase/getClientbaseList';

export const DeptClBase = () => {
  const dispatch = useDispatch();
  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);

  // список организаций в store
  let { organizationList } = useSelector(state => state.clientbaseListReducer.dataClientbaseList);

  // статус  выполнения запроса на получение списка организаций
  const { status } = useSelector(state => state.clientbaseListReducer);

  // состояние списка для функций фильтрации
  const [orgListToFilter, setOrgListToFilter] = useState([]);

  // если "organizationList" отсутствует в store, получаем и диспатчим его
  useEffect(() => {
    if (!organizationList) {
      dispatch(fetchClientbaseList());
    }

    if (status === 'success' && organizationList) {
      // сортируем  массив по ключу "id" (по порядковому номеру поля)
      setOrgListToFilter(Array.from(organizationList).sort((a, b) => {
        return (a.id > b.id) - 0.5;
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organizationList]);

  // поля фильтрации и их показа
  const [filter_list_show, setFilter_list_show] = useState(false);
  const [filterForId, setFilterForId] = useState('');
  const [filterForTitle, setFilterForTitle] = useState('');
  const [filterForInn, setFilterForInn] = useState('');
  const [filterForName, setFilterForName] = useState('');
  const [filterForPhone, setFilterForPhone] = useState('');
  const [filterForMail, setFilterForMail] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество организаций на странице
  const statementsQuantity = 4;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  if (status === 'success' && organizationList) {
    // ===== пагинация продолжение по условию=====
    // список элементов текущей страницы
    const statementsCurrentList = orgListToFilter.slice(firstStatementIndex, lastStatementIndex);

    // общее количество организаций
    const totalStatementsQuantity = orgListToFilter.length;

    // список номеров-кнопок страниц
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
      pages.push(i);
    }

    // функция кнопки следующая страница
    const nextPage = () => {
      setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
    };

    // функция кнопки предыдущая страница
    const prevPage = () => {
      setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
    };
    // ===== пагинация =====


    // функция очистки фильтров и возврат к исходному списку
    const clearFilter = () => {
      setFilterForId('');
      setFilterForTitle('');
      setFilterForInn('');
      setFilterForName('');
      setFilterForPhone('');
      setFilterForMail('');
      setFilterStatus('');

      setOrgListToFilter(Array.from(organizationList).sort((a, b) => {
        return (a.id > b.id) - 0.5;
      }));
    };

    // функция поиска по id
    const searchId = (e) => {
      setFilterForId(e.target.value);
      let newListClBase = [];

      setFilterForTitle('');
      setFilterForInn('');
      setFilterForName('');
      setFilterForPhone('');
      setFilterForMail('');
      setFilterStatus('');

      organizationList.forEach(item => {
        if (String(item.id).includes(e.target.value)) {
          newListClBase.push(item);
        }
      });

      setOrgListToFilter(newListClBase.sort((a, b) => {
        return (a.id > b.id) - 0.5;
      }));

      setCurrentPageNumber(1);
    };

    // функция поиска по наименованию
    const searchTitle = (e) => {
      setFilterForTitle(e.target.value);
      let newListClBase = [];

      setFilterForId('');
      setFilterForInn('');
      setFilterForName('');
      setFilterForPhone('');
      setFilterForMail('');
      setFilterStatus('');

      organizationList.forEach(item => {
        if (item.shortname.includes(e.target.value)) {
          newListClBase.push(item);
        }
      });

      setOrgListToFilter(newListClBase.sort((a, b) => {
        return (a.id > b.id) - 0.5;
      }));

      setCurrentPageNumber(1);
    };

    // функция поиска по инн
    const searchInn = (e) => {
      setFilterForInn(e.target.value);
      let newListClBase = [];

      setFilterForId('');
      setFilterForTitle('');
      setFilterForName('');
      setFilterForPhone('');
      setFilterForMail('');
      setFilterStatus('');

      organizationList.forEach(item => {
        if (item.inn.includes(e.target.value)) {
          newListClBase.push(item);
        }
      });

      setOrgListToFilter(newListClBase.sort((a, b) => {
        return (a.id > b.id) - 0.5;
      }));

      setCurrentPageNumber(1);
    };

    // функция поиска по ФИО
    const searchName = (e) => {
      setFilterForName(e.target.value);
      let newListClBase = [];

      setFilterForId('');
      setFilterForTitle('');
      setFilterForInn('');
      setFilterForPhone('');
      setFilterForMail('');
      setFilterStatus('');

      organizationList.forEach(item => {
        if (item.name.includes(e.target.value)) {
          newListClBase.push(item);
        }
      });

      setOrgListToFilter(newListClBase.sort((a, b) => {
        return (a.id > b.id) - 0.5;
      }));

      setCurrentPageNumber(1);
    };

    // функция поиска по телефону
    const searchPhone = (e) => {
      setFilterForPhone(e.target.value);
      // let newListClBase = [];

      setFilterForId('');
      setFilterForTitle('');
      setFilterForInn('');
      setFilterForName('');
      setFilterForMail('');
      setFilterStatus('');

      // organizationList.forEach(item => {
      //   if (item.clbasePhone.includes(e.target.value)) {
      //     newListClBase.push(item);
      //   }
      // });

      // setOrgListToFilter(newListClBase.sort((a, b) => {
      //   return (a.id > b.id) - 0.5;
      // }));
    };

    // функция поиска по e-mail
    const searchMail = (e) => {
      setFilterForMail(e.target.value);
      // let newListClBase = [];

      setFilterForId('');
      setFilterForTitle('');
      setFilterForInn('');
      setFilterForName('');
      setFilterForPhone('');
      setFilterStatus('');

      // organizationList.forEach(item => {
      //   if (item.clbaseMail.includes(e.target.value)) {
      //     newListClBase.push(item);
      //   }
      // });

      // setOrgListToFilter(newListClBase.sort((a, b) => {
      //   return (a.id > b.id) - 0.5;
      // }));
    };

    // функция поиска по статусу
    const searchStatus = (e) => {
      setFilterStatus(e.target.value);
      let newListClBase = [];

      setFilterForId('');
      setFilterForTitle('');
      setFilterForInn('');
      setFilterForName('');
      setFilterForPhone('');
      setFilterForMail('');

      organizationList.forEach(item => {
        if (item.status === e.target.value) {
          newListClBase.push(item);
        }

        if (e.target.value === 'Все') {
          newListClBase.push(item);
        }
      });

      setOrgListToFilter(newListClBase.sort((a, b) => {
        return (a.id > b.id) - 0.5;
      }));

      setCurrentPageNumber(1);
    };

    return (
      <>
        <div className={st.deptclbase}>
          <div className={st.title_and_btn}>
            <h3 className={st.title}>База клиентов</h3>

            <div className={st.btn_group}>
              <button className={st.btn_add_news} onClick={() => setModalAdd(true)}>
                <svg width="20" height="20" viewBox="0  0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.5 1.75v16.5M17.75 10H1.25" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round" />
                </svg>
                Добавить клиента</button>

              <button
                className={st.btn_filter}
                onClick={() => { setFilter_list_show(!filter_list_show) }}>
                Фильтры
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 6.75h21M5.25 12h13.5m-9 5.25h4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
          </div>

          {/* названия колонок */}
          <ul className={st.table_header}>
            <li className={st.header_item}>ID</li>
            <li className={st.header_item}>Наименование</li>
            <li className={st.header_item}>ИНН</li>
            <li className={st.header_item}>ФИО</li>
            <li className={st.header_item}>Телефон</li>
            <li className={st.header_item}>E-mail</li>
            <li className={st.header_item}>Статус</li>
            <li className={st.header_item}>Реквизиты</li>
            <li className={st.header_item}>Оборуд.</li>
            <li className={st.header_item}><IconSetting /></li>
          </ul>

          {/* список поиска организаций */}
          <ul className={!filter_list_show ? st.filter_list_hidden : st.filter_list}>
            {/* поиск по id */}
            <li className={st.filter_list_item}>
              <input
                className={st.filter_input}
                type='text'
                name='filterForId'
                value={filterForId}
                onChange={searchId} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </li>

            {/* поиск по наименованию организации */}
            <li className={st.filter_list_item}>
              <input
                className={st.filter_input}
                type='text'
                name='filterForTitle'
                value={filterForTitle}
                onChange={searchTitle} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </li>

            {/* поиск по ИНН организации */}
            <li className={st.filter_list_item}>
              <input
                className={st.filter_input}
                type='text'
                name='filterForInn'
                value={filterForInn}
                onChange={searchInn} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </li>

            {/* поиск по ФИО ответств лица организации */}
            <li className={st.filter_list_item}>
              <input
                className={st.filter_input}
                type='text'
                name='filterForName'
                value={filterForName}
                onChange={searchName} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </li>

            {/* поиск по телефону организации */}
            <li className={st.filter_list_item}>
              <input
                className={st.filter_input}
                type='text'
                name='filterForPhone'
                value={filterForPhone}
                onChange={searchPhone} />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </li>

            {/* поиск по email организации */}
            <li className={st.filter_list_item}>
              <input
                className={st.filter_input}
                type='text'
                name='filterForMail'
                value={filterForMail}
                onChange={searchMail}
              />
              <span
                className={st.clear_input}
                onMouseDown={clearFilter}
              >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
            </li>

            {/* поиск по статусу организации */}
            <li className={st.filter_list_select}>
              <select
                className={st.filter_select}
                name='filterStatus'
                value={filterStatus}
                onChange={searchStatus}
              >
                <option value='Все'>Все</option>
                <option value='VERIFIED'>Проверен</option>
                <option value='NOTVERIFIED'>Саморегистрация</option>
              </select>
            </li>
          </ul>

          {/* список "база киентов" */}
          <ul className={st.clbase_list}>
            {statementsCurrentList.map(item =>
              <ClBasePreview
                key={uuidv4()}
                uuid={item.uuid || ''}
                id={item.id || ''}

                nameorg={item.name || ''}
                respusername={item.respusername || ''}
                phone={item.phone || ''}
                email={item.email || ''}
                statusorg={item.status}

                fullname={item.fullname || ''}
                shortname={item.shortname || ''}
                inn={item.inn || ''}
                serialOGRN={item.serial || ''}
                fnsinnKPP={item.fnsinn || ''}
                // legaladdress={item.address.legaladdress || ''}
                // physicaladdress={item.address.physicaladdress || ''}
                legaladdressextfias={item.address.legaladdressextfias || {}}
                physicaladdressextfias={item.address.physicaladdressextfias || {}}
                directorname={item.persons.directorname || ''}
                bookername={item.persons.bookername || ''}

                accountnumber={item.bank.accountnumber || ''}
                bankaccountnumber={item.bank.bankaccountnumber || ''}
                bankname={item.bank.bankname || ''}
                bankid={item.bank.bankid || ''}
              />
            )}
          </ul>

          {/* пагинация */}
          <div className={st.pagination}>
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
              onClick={() => {
                prevPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
            {
              pages.map((item) => {
                return (
                  <button
                    key={uuidv4()}
                    onClick={() => {
                      setCurrentPageNumber(item);
                      window.scrollTo(0, 0);
                    }}
                    className={
                      pages.length === 1 ? st.hidden :
                        item === currentPageNumber
                          ? st.pagination_item_active
                          : st.pagination_item
                    }>
                    {item}
                  </button>
                );
              })
            }
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
              onClick={() => {
                nextPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
          </div>
        </div>

        <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
          <ClBaseAdd
            closeModal={() => setModalAdd(false)}
            organizationList={organizationList}
            status={status}
          />
        </Overlay>
      </>
    )
  } else {
    return (
      <div className={st.deptclbase}>
        <div className={st.title_and_btn}>
          <h3 className={st.title}>База клиентов</h3>
        </div>

        <p>загрузка Базы клиентов...</p>
      </div>
    );
  }
};
