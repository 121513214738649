import st from './LkDocs.module.css';
import { useEffect, useState } from 'react';
// import { docs_list } from './data/docs_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { DocCard } from './components/DocCard/DocCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocsAllPayList } from 'src/store/docsfin/getDocsFin';

export const LkDocs = () => {
  const dispatch = useDispatch();

  // uuid текущей организации
  const { uuid } = useSelector(state => state.authReducer.dataAuth.organization);
  // console.log('uuid: ', uuid);

  // список фин документов в store
  // сделано так потому что 500-й ответ сервера вызывает ошибку
  const { docs } = useSelector(
    state => state.docsFinListReducer.dataDocsPayAllList ?
      state.docsFinListReducer.dataDocsPayAllList :
      state.docsFinListReducer
  );

  // если в сторе нет массива, то получаем его и диспатчим в стор
  useEffect(() => {
    // если "docs" нет в store, при первой загрузке то диспатчим его в store 
    if (!docs) {
      // uuid это uuid текущей организации
      dispatch(fetchDocsAllPayList(uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 4;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const statementsCurrentList = docs?.slice(firstStatementIndex, lastStatementIndex) || [];

  // общее количество заявок
  const totalStatementsQuantity = docs?.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = () => {
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = () => {
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация =====

  return (
    <div className={st.docs}>
      <h3 className={st.title}>Финансовые документы</h3>

      {/* список заголовков таблицы */}
      <ul className={st.table_header}>
        <li className={st.header_item}>Счет</li>
        <li className={st.header_item}>Сумма</li>
        <li className={st.header_item}>Статус</li>
        <li className={st.header_item}>Сумма&nbsp;оплаты</li>
        <li className={st.header_item}>
          <p>Сумма&nbsp;актов</p>
          <div className={st.icon_setting}></div>
        </li>
      </ul>

      {/* список документов */}
      <ul className={st.docs_list}>
        {
          statementsCurrentList.map(item =>
            <DocCard
              key={uuidv4()}

              invoice={item.docnum}
              invoicesum={item.sumdoc}
              status={item.docstatus}
              paymentsum={
                item.paydocList.map(item => item.sumdoc).reduce((acc, current) => acc + current, 0)
              }
              actssum={item.awesum}
              docLink={item.files || ''}
              actLink={item.alldoclist[0]?.imagelink || ''}
            />
          )
        }
      </ul>

      {/* пагинация */}
      <div className={st.pagination}>
        <button
          className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
          onClick={() => {
            prevPage();
            window.scrollTo(0, 0);
          }}
        >
        </button>
        {
          pages.map((item) => {
            return (
              <button
                key={uuidv4()}
                onClick={() => {
                  setCurrentPageNumber(item);
                  window.scrollTo(0, 0);
                }}
                className={
                  pages.length === 1 ? st.hidden :
                    item === currentPageNumber
                      ? st.pagination_item_active
                      : st.pagination_item
                }>
                {item}
              </button>
            );
          })
        }
        <button
          className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
          onClick={() => {
            nextPage();
            window.scrollTo(0, 0);
          }}
        >
        </button>
      </div>
    </div>
  )
};
