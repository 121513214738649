import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DOCS_LIST } from 'src/consts.js';

// получаем список слайдов
export const fetchDocsList = createAsyncThunk(
  'docsList/fetchDocsList',

  async () => {
    const response = await fetch(DOCS_LIST, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    }
    );

    const data = await response.json();

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataDocsList: {},
  error: null,
};

const docsListSlice = createSlice({
  name: 'docsList',
  initialState,
  // reducers: {
  //   removeDocsList: (state) => {
  //     state.dataDocsList = {};
  //   }
  // },

  extraReducers: (builder) => {
    builder
      // при загрузке сайта
      .addCase(fetchDocsList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDocsList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataDocsList = action.payload;
      })
      .addCase(fetchDocsList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataDocsList = action.error.message;
      })
  }
});


// export const { removeDocsList } = docsListSlice.actions;

export default docsListSlice.reducer;
