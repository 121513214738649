import { ORG_ADD, USER_VERIFY_SMS } from 'src/consts.js';

// здесь отправляется два запроса: первый с кодом подтверждения, присланным на телефон, 
// второй, если код подтвержден, на добавление новой организации

// два запроса фетч
export const fetchConfirmCode = async (codeFromSMS, newOrg) => {
  // запрос на подтверждение кода из смс
  const response = await fetch(USER_VERIFY_SMS, {
    method: 'POST',
    body: JSON.stringify(codeFromSMS),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0'
    }
  });

  const data1 = await response.json();
  console.log('data: ', data1);

  if (data1.status === true) {
    const response = await fetch(ORG_ADD, {
      method: 'POST',
      body: JSON.stringify(newOrg),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    });

    const data2 = await response.json();
    console.log('data2: ', data2);
    console.log('сработал запрос на добавление новой организации');

    return data2;
  } else {
    console.log('запрос на добавление новой организации не отправлен, т.к. data1.status !== true');
  }
};
