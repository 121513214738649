import st from './EquipmentAdd.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEquipmentNoDocAddEdit, fetchEquipmentWithDocAddEdit } from '../../fetches/fetchAddEdit';
import { fetchEquipmentList } from 'src/store/equipment/getEquipmentList';
import { handleInputChangeStop } from 'src/utils/handleInputChange';

export const EquipmentAdd = ({ closeModal }) => {
  // ссылка на инпут с кнопкой для выбора паспорта для загрузки с ПК
  const passportSelection = useRef(null);

  // Первая деталь = Электродвигатель главного привода
  // Вторая деталь = Частотный преобразователь
  // Третья деталь = Главная плата управления лифтом
  // Четвертая деталь = Плата управления приводом дверей кабины

  // добавления оборудования инпуты
  const [equipFactorynum, setEquipFactorynum] = useState('');
  const [equipStatusWarranty, setStatusWarranty] = useState('');
  const [equipEquipment, setEquipEquipment] = useState('');
  const [equipManufacture, setEquipManufacture] = useState('');
  const [equipBrand, setEquipBrand] = useState('');

  // добавление технические характеристики инпуты
  const [equipPurpose, setEquipPurpose] = useState('');
  const [equipSpeed, setEquipSpeed] = useState('');
  const [equipLoadcapacity, setEquipLoadcapacity] = useState('');
  const [equipProductDate, setEquipProductDate] = useState('');
  const [equipLaunchDate, setEquipLaunchDate] = useState('');
  const [passportPreview, setPassportPreview] = useState(null);
  const [cabinType, setCabinType] = useState('');
  const [numberStops, setNumberStops] = useState('');

  // добавление детали 1
  const [addDet1Id, setAddDet1Id] = useState('');
  const [addDet1ProdDate, setAddDet1ProdDate] = useState('');
  const [addDet1AcceptwarrantyDate, setAddDet1AcceptwarrantyDate] = useState('');
  const [addDet1ExpiratwarrantyDate, setAddDet1ExpiratwarrantyDate] = useState('');
  // добавление детали 2
  const [addDet2Id, setAddDet2Id] = useState('');
  const [addDet2ProdDate, setAddDet2ProdDate] = useState('');
  const [addDet2AcceptwarrantyDate, setAddDet2AcceptwarrantyDate] = useState('');
  const [addDet2ExpiratwarrantyDate, setAddDet2ExpiratwarrantyDate] = useState('');
  // добавление детали 3
  const [addDet3Id, setAddDet3Id] = useState('');
  const [addDet3ProdDate, setAddDet3ProdDate] = useState('');
  const [addDet3AcceptwarrantyDate, setAddDet3AcceptwarrantyDate] = useState('');
  const [addDet3ExpiratwarrantyDate, setAddDet3ExpiratwarrantyDate] = useState('');
  // добавление детали 4
  const [addDet4Id, setAddDet4Id] = useState('');
  const [addDet4ProdDate, setAddDet4ProdDate] = useState('');
  const [addDet4AcceptwarrantyDate, setAddDet4AcceptwarrantyDate] = useState('');
  const [addDet4ExpiratwarrantyDate, setAddDet4ExpiratwarrantyDate] = useState('');

  // добавление адрес установки
  const [region, setRegion] = useState('');
  const [town, setTown] = useState('');
  const [street, setStreet] = useState('');
  const [housenumber, setHousenumber] = useState('');
  const [entrancenumber, setEntrancenumber] = useState('');

  // окно Добавление оборудования
  const [fieldset1, setFieldset1] = useState(true);
  // окно добавление технические характеристики
  const [fieldset2, setFieldset2] = useState(false);
  // окно добавление деталей
  const [fieldset3, setFieldset3] = useState(false);
  // окно добавления адреса установки оборудования
  const [fieldset4, setFieldset4] = useState(false);

  const dispatch = useDispatch();

  // 'uuid' и 'name' выбранной организации для построения списка ее оборудования и вывода ее названия
  let { сurrentOrganization } = useSelector(state => state.currentOrganizationReducer);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  // паттерн для ввода числа остановок
  const patternNumberStops = /^(?:[2-9]|[1-7][0-9]|80|)$/;

  // данные для основного запроса
  const equipmentDataBody = {
    // поля не помеченные !!!ОБЗАТЕЛЬНОЕ!!! можно отправить с нулем или пустой строкой
    'efouid': '', // uuid оборудования??? добавляется на сервере
    'efointid': 0, // оборудование организации int id for HZ (хер знает)
    'uuid': сurrentOrganization[0], // передаем только uuid организации
    'equid': '', // uuid оборудования??? добавляется на сервере

    // дата производства !!!ОБЗАТЕЛЬНЫЙ ФОРМАТ!!!(формат: месяц-число-год полностью)
    'dateofcommissioning': equipProductDate,
    // дата принятия на гарантию !!!ОБЗАТЕЛЬНЫЙ ФОРМАТ!!!(формат: месяц-число-год полностью)
    'dateofacceptanceforwarranty': equipLaunchDate,
    // дата истечения гарантии !!!ОБЗАТЕЛЬНЫЙ ФОРМАТ!!!(формат: месяц-число-год полностью)
    'warrantyexpirationdate': '4446-01-01',

    'installationaddress': { // адрес установки оборудования
      'region': region, // регион
      'city': town, // город
      'street': street, // улица
      'house': housenumber, // номер дома
      'entrance': entrancenumber // подъезд
    },

    // новый объект детали
    'partsList': [
      {
        'partid': '',
        'efouid': '',
        'prnuid': '',
        'equipment':
        {
          'equid': '',
          'equintid': 0,
          // 'factorynumber': equipFactorynum,
          'factorynumber': '',
          'manufacturer': { // производитель
            'id': '',
            // 'manufacturername': equipManufacture
            'manufacturername': ''
          },
          'brand': { // марка
            'id': '',
            // 'brandname': equipBrand,
            'brandname': '',
            'manufacturerid': ''
          },
          'model': { // (хер знает)
            'id': '',
            'modelname': '',
            'brandid': ''
          },
        },
        // 'partname': 'Первая деталь',
        'partname': 'Электродвигатель главного привода',
        'selfnumber': addDet1Id,
        'equipmentDate':
        {
          'dateofcommissioning': addDet1ProdDate,
          'dateofacceptanceforwarranty': addDet1AcceptwarrantyDate,
          'warrantyexpirationdate': addDet1ExpiratwarrantyDate
        }
      },
      {
        'partid': '',
        'efouid': '',
        'prnuid': '',
        'equipment':
        {
          'equid': '',
          'equintid': 0,
          // 'factorynumber': equipFactorynum,
          'factorynumber': '',
          'manufacturer': { // производитель
            'id': '',
            // 'manufacturername': equipManufacture
            'manufacturername': ''
          },
          'brand': { // марка
            'id': '',
            // 'brandname': equipBrand,
            'brandname': '',
            'manufacturerid': ''
          },
          'model': { // (хер знает)
            'id': '',
            'modelname': '',
            'brandid': ''
          },
        },
        // 'partname': 'Вторая деталь',
        'partname': 'Частотный преобразователь',
        'selfnumber': addDet2Id,
        'equipmentDate':
        {
          'dateofcommissioning': addDet2ProdDate,
          'dateofacceptanceforwarranty': addDet2AcceptwarrantyDate,
          'warrantyexpirationdate': addDet2ExpiratwarrantyDate
        }
      },
      {
        'partid': '',
        'efouid': '',
        'prnuid': '',
        'equipment':
        {
          'equid': '',
          'equintid': 0,
          // 'factorynumber': equipFactorynum,
          'factorynumber': '',
          'manufacturer': { // производитель
            'id': '',
            // 'manufacturername': equipManufacture
            'manufacturername': ''
          },
          'brand': { // марка
            'id': '',
            // 'brandname': equipBrand,
            'brandname': '',
            'manufacturerid': ''
          },
          'model': { // (хер знает)
            'id': '',
            'modelname': '',
            'brandid': ''
          },
        },
        // 'partname': 'Третья деталь',
        'partname': 'Главная плата управления лифтом',
        'selfnumber': addDet3Id,
        'equipmentDate':
        {
          'dateofcommissioning': addDet3ProdDate,
          'dateofacceptanceforwarranty': addDet3AcceptwarrantyDate,
          'warrantyexpirationdate': addDet3ExpiratwarrantyDate
        }
      },
      {
        'partid': '',
        'efouid': '',
        'prnuid': '',
        'equipment':
        {
          'equid': '',
          'equintid': 0,
          // 'factorynumber': equipFactorynum,
          'factorynumber': '',
          'manufacturer': { // производитель
            'id': '',
            // 'manufacturername': equipManufacture
            'manufacturername': ''
          },
          'brand': { // марка
            'id': '',
            // 'brandname': equipBrand,
            'brandname': '',
            'manufacturerid': ''
          },
          'model': { // (хер знает)
            'id': '',
            'modelname': '',
            'brandid': ''
          },
        },
        // 'partname': 'Четвертая деталь',
        'partname': 'Плата управления приводом дверей кабины',
        'selfnumber': addDet4Id,
        'equipmentDate':
        {
          'dateofcommissioning': addDet4ProdDate,
          'dateofacceptanceforwarranty': addDet4AcceptwarrantyDate,
          'warrantyexpirationdate': addDet4ExpiratwarrantyDate
        }
      }
    ],
    //'status' на гарантии или нет !!!ОБЗАТЕЛЬНОЕ!!!
    'status': equipStatusWarranty,
    'factorynumber': equipFactorynum, // заводской номер
    //'typeOfEquipment' тип оборудования !!!ОБЗАТЕЛЬНОЕ!!!
    'typeOfEquipment': equipEquipment,
    'manufacturer': { // производитель
      'id': '',
      'manufacturername': equipManufacture
    },

    "cabintype": cabinType,
    "numberofstops": +numberStops,

    'brand': { // марка
      'id': '',
      'brandname': equipBrand,
      'manufacturerid': ''
    },
    'model': { // (хер знает)
      'id': '',
      'modelname': '',
      'brandid': ''
    },
    //'purpose' назначение (пассажирский, грузовой, грузопассажирский) 
    // !!!ОБЗАТЕЛЬНОЕ!!!(формат: "Passenger" "Cargo" "CargoPassenger")
    'purpose': equipPurpose,
    // 'speed': equipSpeed, // скорость
    'speed': equipSpeed, // скорость
    'loadcapacity': +equipLoadcapacity, // грузоподъемность
    // 'passportlinkuid': 'string', // добавляется в fetch после первого запроса
    // 'passportlink': '' // возвращается после второго запроса как ссылка на загруженный pdf документ
  };


  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);
    console.log(formData.uploadedFile);

    // первое это отправка с фото второе - отправка без фото
    if (selectedFileDouble !== '') {
      await fetchEquipmentWithDocAddEdit(formData, equipmentDataBody);
    } else {
      await fetchEquipmentNoDocAddEdit(equipmentDataBody);
    }

    dispatch(fetchEquipmentList(сurrentOrganization[0]))

    closeModal();
    console.log('форма отправлена');
  };

  // выбирает новый пасспорт на ПК и вставляет в превью
  const previewPassport = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('application')) {
        setPassportPreview(selectedFile.name);
      }
    };
  };

  // удаляем поле "превью пасспорта"
  const clearPreviewPassport = (e) => {
    e.preventDefault();
    setPassportPreview(null);
  };


  return (
    <div className={st.clientsadd_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.clientsadd}>
        <form className={st.form}>
          {/* окно Добавление оборудования */}
          <fieldset className={fieldset1 ? st.fieldset1 : st.hidden}>
            <div className={st.title_wrap}>
              <h3 className={st.title}>Добавление оборудования</h3>
              <div className={st.title_img1}></div>
            </div>

            <div className={st.wrap}>
              {/* инпут ввода заводского номера */}
              <div className={st.wrap_title}>
                <p className={equipFactorynum !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Заводской номер</p>

                <div className={st.input_wrap_2}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_title}
                      type='text'
                      name='equipFactorynum'
                      value={equipFactorynum}
                      placeholder='Заводской номер'
                      maxLength='22'
                      onChange={(e) => setEquipFactorynum(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              {/* инпут выбора статуса оборудования */}
              <div className={st.wrap_title}>
                <p className={equipStatusWarranty !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Статус</p>

                <div className={st.input_wrap_2}>
                  <label className={st.label_title}>
                    <select
                      className={st.input_select}
                      name='equipStatusWarranty'
                      value={equipStatusWarranty}
                      onChange={(e) => setStatusWarranty(e.target.value)}>
                      <option value=''>Статус</option>
                      <option value='Entered'>Заведен</option>
                      <option value='UnderWarranty'>Стандартна гарантия</option>
                      <option value='Extended'>Расширенная гарантия</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            {/* инпут выбора оборудования */}
            <div className={st.wrap_title}>
              <p className={equipEquipment !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Оборудование</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='equipEquipment'
                    value={equipEquipment}
                    onChange={(e) => setEquipEquipment(e.target.value)}>
                    <option value=''>Оборудование</option>
                    <option value='Elevator'>Лифт</option>
                    <option value='Travolator'>Травалатор</option>
                    <option value='Escalator'>Эскалатор</option>
                  </select>
                </label>
              </div>
            </div>

            {/* инпут выбора производителя */}
            <div className={st.wrap_title}>
              <p className={equipManufacture !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Производитель</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='equipManufacture'
                    value={equipManufacture}
                    onChange={(e) => setEquipManufacture(e.target.value)}>
                    <option value=''>Производитель</option>
                    <option value='BLT'>BLT</option>
                    <option value='KONE'>KONE</option>
                    <option value='OTIS'>OTIS</option>
                  </select>
                </label>
              </div>
            </div>

            {/* инпут ввода марки оборудования */}
            <div className={st.wrap_title}>
              <p className={equipBrand !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Марка</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='equipBrand'
                    value={equipBrand}
                    placeholder='Марка'
                    onChange={(e) => setEquipBrand(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <button
              className={st.btn_change}
              onClick={(e) => {
                e.preventDefault();
                setFieldset1(false);
                setFieldset2(true);
              }}
            >Далее</button>
          </fieldset>

          {/* окно Технические характеристики */}
          <fieldset className={fieldset2 ? st.fieldset2 : st.hidden}>
            <div className={st.title_wrap}>
              <h3 className={st.title}>Технические характеристики</h3>
              <div className={st.title_img2}></div>
            </div>

            {/* инпут выбора назначения оборудования */}
            <div className={st.wrap_title}>
              <p className={equipPurpose !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Назначение</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='equipPurpose'
                    value={equipPurpose}
                    onChange={(e) => setEquipPurpose(e.target.value)}>
                    <option value=''>Назначение</option>
                    <option value='Passenger'>Пассажирский</option>
                    <option value='Cargo'>Грузовой</option>
                    <option value='CargoPassenger'>Грузопассажирский</option>
                  </select>
                </label>
              </div>
            </div>

            {/* инпут ввода скорости */}
            <div className={st.wrap_title}>
              <p className={equipSpeed !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Скорость</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='equipSpeed'
                    value={equipSpeed}
                    onChange={(e) => setEquipSpeed(e.target.value)}>
                    <option value=''>Скорость</option>
                    <option value='SLOWEST'>1,0 м/с</option>
                    <option value='MEDIUMSLOW'>1,6 м/с</option>
                    <option value='SLOW'>1,75 м/с</option>
                    <option value='MEDIUM'>2,0 м/с</option>
                    <option value='FAST'>2,5 м/с</option>
                    <option value='SUPERFAST'>Свыше 2,5 м/с</option>
                  </select>
                </label>
              </div>
            </div>


            {/* инпут ввода типа кабины */}
            <div className={st.wrap_title}>
              <p className={cabinType !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Тип кабины</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='cabinType'
                    value={cabinType}
                    onChange={(e) => setCabinType(e.target.value)}>
                    <option value=''>Тип кабины</option>
                    <option value='walkthrough'>Проходная</option>
                    <option value='nonwalkthrough'>Непроходная</option>
                  </select>
                </label>
              </div>
            </div>

            {/* инпут ввода грузоподъемности */}
            <div className={st.wrap_title}>
              <p className={equipLoadcapacity !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Грузоподъёмность</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <select
                    className={st.input_select}
                    name='equipLoadcapacity'
                    value={equipLoadcapacity}
                    onChange={(e) => setEquipLoadcapacity(e.target.value)}>
                    <option value=''>Грузоподъёмность</option>
                    <option value='400'>400 кг</option>
                    <option value='600'>600 кг</option>
                    <option value='1000'>1000 кг</option>
                    <option value='1600'>1600 кг</option>
                  </select>
                </label>
              </div>
            </div>

            {/* инпут указания количества остановок */}
            <div className={st.wrap_title}>
              <p className={numberStops !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Количество остановок
              </p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='numberStops'
                    value={numberStops}
                    placeholder='Количество остановок (от 2 до 80)'
                    onChange={(e) => handleInputChangeStop(e, patternNumberStops, setNumberStops)}
                  />
                </label>
              </div>
            </div>



            {/* инпут выбора даты производства */}
            <div className={st.wrap_title}>
              <p className={equipProductDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Дата производства</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_calendar}
                    type='date'
                    name='equipProductDate'
                    value={equipProductDate}
                    onChange={(e) => setEquipProductDate(e.target.value)} />

                  <div className={st.input_after}></div>

                  <p className={equipProductDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                    Дата производства
                  </p>

                </label>
              </div>
            </div>

            {/* инпут выбора даты запуска в эксплуатацию */}
            <div className={st.wrap_title}>
              <p className={equipLaunchDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Дата запуска в эксплуатацию</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_calendar}
                    type='date'
                    name='equipLaunchDate'
                    value={equipLaunchDate}
                    onChange={(e) => setEquipLaunchDate(e.target.value)} />

                  <div className={st.input_after}></div>

                  <p className={equipLaunchDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                    Дата запуска в эксплуатацию
                  </p>
                </label>
              </div>
            </div>

            {/* инпут с кнопкой для выбора паспорта для загрузки с ПК */}
            <div className={st.passportdoc}>
              <label className={st.label_image}>
                <input
                  ref={passportSelection}
                  className={st.input_image}
                  type='file'
                  name='passport'
                  accept='application/pdf'
                  onChange={previewPassport}
                />

                {passportPreview !== null
                  ? <div className={st.wrap_prev}>
                    <p><span>{passportPreview}</span></p>
                    <button onClick={clearPreviewPassport}>x</button></div>
                  : <p>Загрузить паспорт...</p>}

                <BtnLoad />
              </label>
            </div>

            <div className={st.btn_change_wrap}>
              <div
                className={st.btn_back}
                onClick={() => {
                  setFieldset2(false);
                  setFieldset1(true);
                }}
              ></div>

              <button
                className={st.btn_change}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldset2(false);
                  setFieldset3(true);
                }}
              >Далее</button>
            </div>
          </fieldset>

          {/* окно Детали */}
          <fieldset className={fieldset3 ? st.fieldset3 : st.hidden}>
            <div className={st.title_wrap}>
              <h3 className={st.title}>Детали</h3>
              <div className={st.title_img3}></div>
            </div>

            {/* группа добавления детали 1 */}
            <div className={st.wrap_input}>
              <p className={st.det_title}>Электродвигатель главного привода</p>

              {/* инпут добавления id детали */}
              <div className={st.wrap_title}>
                <p className={addDet1Id !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Индивидуальный номер
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_title}
                      type='text'
                      name='addDet1Id'
                      value={addDet1Id}
                      placeholder='Индивидуальный номер'
                      onChange={(e) => setAddDet1Id(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              {/* инпут добавления даты производства */}
              <div className={st.wrap_title}>
                <p className={addDet1ProdDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата производства
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet1ProdDate'
                      value={addDet1ProdDate}
                      onChange={(e) => setAddDet1ProdDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet1ProdDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата производства
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты приемки на гарантию */}
              <div className={st.wrap_title}>
                <p className={addDet1AcceptwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата приемки на гарантию
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet1AcceptwarrantyDate'
                      value={addDet1AcceptwarrantyDate}
                      onChange={(e) => setAddDet1AcceptwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet1AcceptwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата приемки на гарантию
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты окончания гарантии */}
              <div className={st.wrap_title}>
                <p className={addDet1ExpiratwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата окончания гарантии
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet1ExpiratwarrantyDate'
                      value={addDet1ExpiratwarrantyDate}
                      onChange={(e) => setAddDet1ExpiratwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet1ExpiratwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата окончания гарантии
                    </p>
                  </label>
                </div>
              </div>
            </div>

            {/* группа добавления детали 2 */}
            <div className={st.wrap_input}>
              <p className={st.det_title}>Частотный преобразователь</p>

              {/* инпут добавления id детали */}
              <div className={st.wrap_title}>
                <p className={addDet2Id !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Индивидуальный номер
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_title}
                      type='text'
                      name='addDet2Id'
                      value={addDet2Id}
                      placeholder='Индивидуальный номер'
                      onChange={(e) => setAddDet2Id(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              {/* инпут добавления даты производства */}
              <div className={st.wrap_title}>
                <p className={addDet2ProdDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата производства
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet2ProdDate'
                      value={addDet2ProdDate}
                      onChange={(e) => setAddDet2ProdDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet2ProdDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата производства
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты приемки на гарантию */}
              <div className={st.wrap_title}>
                <p className={addDet2AcceptwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата приемки на гарантию
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet2AcceptwarrantyDate'
                      value={addDet2AcceptwarrantyDate}
                      onChange={(e) => setAddDet2AcceptwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet2AcceptwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата приемки на гарантию
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты окончания гарантии */}
              <div className={st.wrap_title}>
                <p className={addDet2ExpiratwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата окончания гарантии
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet2ExpiratwarrantyDate'
                      value={addDet2ExpiratwarrantyDate}
                      onChange={(e) => setAddDet2ExpiratwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet2ExpiratwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата окончания гарантии
                    </p>
                  </label>
                </div>
              </div>
            </div>

            {/* группа добавления детали 3 */}
            <div className={st.wrap_input}>
              <p className={st.det_title}>Главная плата управления</p>

              {/* инпут добавления id детали */}
              <div className={st.wrap_title}>
                <p className={addDet3Id !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Индивидуальный номер
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_title}
                      type='text'
                      name='addDet3Id'
                      value={addDet3Id}
                      placeholder='Индивидуальный номер'
                      onChange={(e) => setAddDet3Id(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              {/* инпут добавления даты производства */}
              <div className={st.wrap_title}>
                <p className={addDet3ProdDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата производства
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet3ProdDate'
                      value={addDet3ProdDate}
                      onChange={(e) => setAddDet3ProdDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet3ProdDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата производства
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты приемки на гарантию */}
              <div className={st.wrap_title}>
                <p className={addDet3AcceptwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата приемки на гарантию
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet3AcceptwarrantyDate'
                      value={addDet3AcceptwarrantyDate}
                      onChange={(e) => setAddDet3AcceptwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet3AcceptwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата приемки на гарантию
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты окончания гарантии */}
              <div className={st.wrap_title}>
                <p className={addDet3ExpiratwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата окончания гарантии
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDe32ExpiratwarrantyDate'
                      value={addDet3ExpiratwarrantyDate}
                      onChange={(e) => setAddDet3ExpiratwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet3ExpiratwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата окончания гарантии
                    </p>
                  </label>
                </div>
              </div>
            </div>

            {/* группа добавления детали 4 */}
            <div className={equipEquipment === 'Elevator' ? st.wrap_input : st.hidden}>
              <p className={st.det_title}>Плата управления приводом дверей кабины</p>

              {/* инпут добавления id детали */}
              <div className={st.wrap_title}>
                <p className={addDet4Id !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Индивидуальный номер
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_title}
                      type='text'
                      name='addDet4Id'
                      value={addDet4Id}
                      placeholder='Индивидуальный номер'
                      onChange={(e) => setAddDet4Id(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              {/* инпут добавления даты производства */}
              <div className={st.wrap_title}>
                <p className={addDet4ProdDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата производства
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet4ProdDate'
                      value={addDet4ProdDate}
                      onChange={(e) => setAddDet4ProdDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet4ProdDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата производства
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты приемки на гарантию */}
              <div className={st.wrap_title}>
                <p className={addDet4AcceptwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата приемки на гарантию
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet4AcceptwarrantyDate'
                      value={addDet4AcceptwarrantyDate}
                      onChange={(e) => setAddDet4AcceptwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet4AcceptwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата приемки на гарантию
                    </p>
                  </label>
                </div>
              </div>

              {/* инпут добавления даты окончания гарантии */}
              <div className={st.wrap_title}>
                <p className={addDet4ExpiratwarrantyDate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                  Дата окончания гарантии
                </p>

                <div className={st.input_wrap}>
                  <label className={st.label_title}>
                    <input
                      className={st.input_calendar}
                      type='date'
                      name='addDet4ExpiratwarrantyDate'
                      value={addDet4ExpiratwarrantyDate}
                      onChange={(e) => setAddDet4ExpiratwarrantyDate(e.target.value)} />

                    <div className={st.input_after}></div>

                    <p className={addDet4ExpiratwarrantyDate === '' ? st.placeholder : st.wrap_title_p_hide}>
                      Дата окончания гарантии
                    </p>
                  </label>
                </div>
              </div>
            </div>

            {/* кнопки назад и далее */}
            <div className={st.btn_change_wrap}>
              <div
                className={st.btn_back}
                onClick={() => {
                  setFieldset3(false);
                  setFieldset2(true);
                }}
              ></div>

              <button
                className={st.btn_change}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldset3(false);
                  setFieldset4(true);
                }}
              >Далее</button>
            </div>
          </fieldset>

          {/* окно Адрес установки */}
          <fieldset className={fieldset4 ? st.fieldset4 : st.hidden}>
            <div className={st.title_wrap}>
              <h3 className={st.title}>Адрес установки</h3>
              <div className={st.title_img4}></div>
            </div>

            {/* инпут ввода региона установки  */}
            <div className={st.wrap_title}>
              <p className={region !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Регион</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='region'
                    value={region}
                    placeholder='Регион'
                    onChange={(e) => setRegion(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода города установки */}
            <div className={st.wrap_title}>
              <p className={town !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Город</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='town'
                    value={town}
                    placeholder='Город'
                    onChange={(e) => setTown(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода улицы */}
            <div className={st.wrap_title}>
              <p className={street !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Улица</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='street'
                    value={street}
                    placeholder='Улица'
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода номера дома */}
            <div className={st.wrap_title}>
              <p className={housenumber !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Номер дома</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='housenumber'
                    value={housenumber}
                    placeholder='Номер дома'
                    onChange={(e) => setHousenumber(e.target.value)}
                  />
                </label>
              </div>
            </div>

            {/* инпут ввода номера подъезда */}
            <div className={st.wrap_title}>
              <p className={entrancenumber !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Номер подъезда</p>

              <div className={st.input_wrap}>
                <label className={st.label_title}>
                  <input
                    className={st.input_title}
                    type='text'
                    name='entrancenumber'
                    value={entrancenumber}
                    placeholder='Номер подъезда'
                    onChange={(e) => setEntrancenumber(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div className={st.btn_change_wrap}>
              <div
                className={st.btn_back}
                onClick={() => {
                  setFieldset4(false);
                  setFieldset3(true);
                }}
              ></div>

              <button
                className={st.btn_change}
                onClick={handleFormSubmit}
              >Сохранить</button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
};
