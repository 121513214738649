import { SLIDES_DEL } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// удаляем слайд
export const fetchSlideDel = async (slideid) => {

  // отправляем запрос на удаление слайда
  const responseslidedel = await fetch(`${SLIDES_DEL}${slideid}`, {
    method: 'GET',
    headers: {
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const data = await responseslidedel.json();
  console.log('data1: ', data);

  // в случае протухания токена обновляем пару "token - rtoken"
  if (data.loginstatus === 7) {
    // перезапись токенов
    await updateTokens();

    // снова отправляем запрос 
    const responseslidedel = await fetch(`${SLIDES_DEL}${slideid}`, {
      method: 'GET',
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const data = await responseslidedel.json();
    console.log('data2: ', data);
  }
};
