import st from './LkAboutCompany.module.css';
import handleInputChange from 'src/utils/handleInputChange';
import { useEffect, useState } from 'react';
import passwordShowHide from 'src/utils/passwordShowHide';
import { Overlay } from 'src/components/Overlay/Overlay';
import { EditData } from './components/EditData/EditData.jsx';
import { EditRequisites } from './components/EditRequisites/EditRequisites.jsx';
import phoneNumberMask from 'src/utils/phoneNumberMask';
import { EditBankRequisites } from './components/EditBankRequisites/EditBankRequisites';
import { useDispatch, useSelector } from 'react-redux';
import hashPassword from 'src/utils/hashPassword';
import { fetchChangePassThunk, removeChangePassData } from 'src/store/changePass/changePass';

export const LkAboutCompany = () => {
  const dispatch = useDispatch();

  const { dataAuth } = useSelector(state => state.authReducer);
  const { loginstatus } = useSelector(state => state.changePassReducer.dataChangePass);

  // сообщение о смене пароля
  const [notification, setNotification] = useState('');

  // модальные окна: "данные", "реквизиты" "банковские реквизиты"
  const [modalEditData, setModalEditData] = useState(false);
  const [modalEditRequisites, setModalEditRequisites] = useState(false);
  const [modalEditBankRequisites, setModalEditBankRequisites] = useState(false);

  // инпуты смены пароля
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  // предупреждения о неправильном вводе
  const [showNotePass1, setShowNotePass1] = useState('');
  const [showNotePass2, setShowNotePass2] = useState('');

  // показать - скрыть пароль
  const [passwordVisibility, setPasswordVisibility] = useState('password');

  // паттерн для пароля
  const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{-~]).{8,64}$/;

  // данные для запроса смены пароля
  const dataForSHC = {
    'login': dataAuth.user.user.loginName
  };

  // данные для запроса смены пароля
  const dataChangePass = {
    'loginName': dataAuth.user.user.loginName,
    'oldpassHash': hashPassword(oldPassword),
    'passHash': hashPassword(newPassword2),
    'smscode': ''
  };

  const dataForSHCandDataChangePass = [dataForSHC, dataChangePass];

  // отправляет форму со сменой пароля
  const postChangePassword = async (e) => {
    e.preventDefault();

    dispatch(fetchChangePassThunk(dataForSHCandDataChangePass));

    setOldPassword('');
    setNewPassword1('');
    setNewPassword2('');

    console.log('форма смены пароля отправлена');
  };

  // принудительно показывает уведомление о смене пароля
  // const handlerNotification = (e) => {
  //   e.preventDefault();
  //   setNotification(5);
  //   setTimeout(setNotification, 2000);
  // };

  // в зависимости от значения loginstatus сбрасываем changePassReducer на изначальные значения
  useEffect(() => {
    setNotification(loginstatus);

    if (loginstatus === 5 || loginstatus === 6) {
      setTimeout(() => dispatch(removeChangePassData()), 4000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginstatus]);

  return (
    <>
      <div className={st.lk_about_company}>
        {/* общие данные */}
        <div className={st.wrap}>
          <h3 className={st.title}>
            {
              Object.keys(dataAuth).length ?
                dataAuth.organization?.name :
                'нет данных'
            }
          </h3>

          <ul className={st.data_list}>
            <li className={st.data_item}>
              <p className={st.item_name}>Наименование</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.name :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>ФИО ответственного сотрудника</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.respusername :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Номер телефона</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    `+7 ${phoneNumberMask(dataAuth.user?.phonenumber?.slice(1))}` :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>E-mail</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.user?.email :
                    'нет данных'
                }
              </p>
            </li>
          </ul>

          <button
            className={st.list_btn}
            onClick={() => setModalEditData(true)}>Изменить</button>
        </div>

        {/* реквизиты */}
        <div className={st.wrap}>
          <h3 className={st.title}>Реквизиты</h3>

          <ul className={st.data_list}>
            <li className={st.data_item}>
              <p className={st.item_name}>Полное наименование</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.fullname :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Краткое наименование</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.shortname :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>ИНН</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.inn :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>КПП</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.fnsinn :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>ОГРН</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.serial :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Юридический адрес</p>
              <p className={st.item_content}>
                {
                  dataAuth?.organization?.address?.legaladdressextfias ?
                    dataAuth.organization?.address?.legaladdressextfias.full_name :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Фактический адрес</p>
              <p className={st.item_content}>
                {
                  dataAuth?.organization?.address?.physicaladdressextfias ?
                    dataAuth.organization?.address?.physicaladdressextfias.full_name :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Генеральный директор</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.persons?.directorname :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Главный бухгалтер</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.persons?.bookername :
                    'нет данных'
                }
              </p>
            </li>
          </ul>

          <button
            className={st.list_btn}
            onClick={() => setModalEditRequisites(true)}>Изменить</button>
        </div>

        {/* банковские реквизиты */}
        <div className={st.wrap}>
          <h3 className={st.title}>Банковские реквизиты</h3>

          <ul className={st.data_list}>
            <li className={st.data_item}>
              <p className={st.item_name}>Расчетный счет</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.bank?.accountnumber :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Корреспондентский счет</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.bank?.bankaccountnumber :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>Банк</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.bank?.bankname :
                    'нет данных'
                }
              </p>
            </li>

            <li className={st.data_item}>
              <p className={st.item_name}>БИК</p>
              <p className={st.item_content}>
                {
                  Object.keys(dataAuth).length ?
                    dataAuth.organization?.bank?.bankid :
                    'нет данных'
                }
              </p>
            </li>
          </ul>

          <button
            className={st.list_btn}
            onClick={() => setModalEditBankRequisites(true)}>Изменить</button>
        </div>

        {/* смена пароля */}
        <div className={st.wrap}>
          <h3 className={st.title}>Смена пароля</h3>

          <form className={st.autor_form}>

            {/* ввод старого пароля */}
            <div className={st.note_wrap}>

              {/* уведомления о смене пароля */}
              <h4 className={notification === 5 ? st.notification_true : st.notification_hide}>Пароль успешно изменен!</h4>
              <h4 className={notification === 6 ? st.notification_false : st.notification_hide}>Пароль НЕ изменен!</h4>

              <input
                className={st.input}
                type={passwordVisibility}
                name='oldPassword'
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder='Старый пароль'
              />

              <div className={st.input_wrap}>
                <button
                  className={passwordVisibility === 'password' ? st.input_btn_closed : st.input_btn_open}
                  onClick={(e) => passwordShowHide(e, passwordVisibility, setPasswordVisibility)}
                ></button>
              </div>
            </div>

            {/* ввод нового пароля 1 */}
            <div className={st.note_wrap}>
              <p className={showNotePass1 === '' ? st.note_empty : showNotePass1 ? st.note_hide : st.note_pass}>
                Пароль должен содержать не менее 8 символов и состоять из больших латинских букв, маленьких латинских букв, цифр и символов
              </p>

              <input
                className={st.input}
                type={passwordVisibility}
                name='newPassword1'
                value={newPassword1}
                onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass1, setNewPassword1)}
                placeholder='Новый пароль'
              />

            </div>

            {/* ввод нового пароля 2 */}
            <div className={st.note_wrap}>
              <p className={showNotePass2 === '' ? st.note_empty : newPassword1 === newPassword2 ? st.note_hide : st.note_pass2}>
                Пароли не совпадают
              </p>

              <input
                className={st.input}
                type={passwordVisibility}
                name='newPassword2'
                value={newPassword2}
                onChange={(e) => handleInputChange(e, patternPassword, setShowNotePass2, setNewPassword2)}
                placeholder='Повторный ввод пароля'
              />
            </div>

            {/* <button
              className={st.button_submit}
              onClick={handlerNotification}
            >Покажи уведомление</button> */}

            <button
              className={st.button_submit}
              onClick={postChangePassword}
              disabled={
                showNotePass1 &&
                  showNotePass1 === showNotePass2 &&
                  oldPassword !== '' ? false : true
              }
            >Cменить пароль</button>

          </form>
        </div>
      </div>

      <Overlay className={!modalEditData ? st.overlay_hide : st.overlay_show}>
        <EditData
          closeModal={() => setModalEditData(false)}
          dataAuth={dataAuth} />
      </Overlay>

      <Overlay className={!modalEditRequisites ? st.overlay_hide : st.overlay_show}>
        <EditRequisites closeModal={() => setModalEditRequisites(false)} dataAuth={dataAuth} />
      </Overlay>

      <Overlay className={!modalEditBankRequisites ? st.overlay_hide : st.overlay_show}>
        <EditBankRequisites closeModal={() => setModalEditBankRequisites(false)} dataAuth={dataAuth} />
      </Overlay>
    </>
  );
};
