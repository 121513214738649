import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const YandexMetrica = () => {
  // для яндекс метрики
  const location = useLocation();

  // код инициализации Яндекс Метрики
  useEffect(() => {
    (function (m, e, t, r, i, s, d) {
      m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
      m[i].l = 1 * new Date();

      for (var j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) { return; }
      }

      s = e.createElement(t);
      d = e.getElementsByTagName(t)[0];
      s.async = 1;
      s.src = r;
      d.parentNode.insertBefore(s, d);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    // eslint-disable-next-line no-undef
    ym(98775926, 'init', {
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    });
  }, []);

  // отправка информации о каждом переходе
  useEffect(() => {
    // console.log('location.pathname: ', location.pathname);
    // eslint-disable-next-line no-undef
    ym(98775926, 'hit', location.pathname);
  }, [location.pathname]);

  return null; // не рендерим ничего
};
