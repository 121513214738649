import st from './PassRecCode.module.css';
import store from 'src/store/store';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

export const PassRecCode = () => {
  const [codeSimbol1, setCodeSimbol1] = useState('');
  const [codeSimbol2, setCodeSimbol2] = useState('');
  const [codeSimbol3, setCodeSimbol3] = useState('');
  const [codeSimbol4, setCodeSimbol4] = useState('');
  // const [showNoteRecode, setShowNoteRecode] = useState(false);
  // const [showNoteLimitRecode, setShowNoteLimitRecode] = useState(false);
  const [loginstatus, setLoginstatus] = useState(5);
  const navigate = useNavigate();
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();

  // запрет ввода всего кроме цифр и контроль инпутов
  const handleInputCode = (e, stateInput) => {
    stateInput(e.target.value.replace(/\D/g, ''))
  };


  useEffect(() => {
    if (codeSimbol1.length >= 1) {
      inputRef1.current.nextSibling.focus();
    }

    if (codeSimbol2.length >= 1) {
      inputRef2.current.nextSibling.focus();
    }

    if (codeSimbol3.length >= 1) {
      inputRef3.current.nextSibling.focus();
    }

    if (codeSimbol4.length >= 1) {
      inputRef4.current.blur();
    }

    // if (codeSimbol5.length >= 1) {
    //   inputRef5.current.nextSibling.focus();
    // }

    // if (codeSimbol6.length >= 1) {
    //   inputRef6.current.blur();
    // }
  }, [codeSimbol1, codeSimbol2, codeSimbol3, codeSimbol4]);

  // меняет значение в инпуте на отформатированное
  // const phoneNumberFormatter = (e) => {
  //   const formattedTargetValue = phoneNumberMask(e.target.value);

  //   e.target.value = formattedTargetValue;

  //   setPhoneNumRec(e.target.value);
  // }

  // отключает включает кнопку
  // useEffect(() => {
  //   if (phoneNumRec.length === 13) {
  //     setShowNotePhone(true);
  //   } else {
  //     setShowNotePhone(false);
  //   }
  // }, [phoneNumRec.length])

  useEffect(() => {
    // подписка на изменение значения "loginstatus"
    store.subscribe(() => setLoginstatus(store.getState().authReducer?.dataAuth?.loginstatus));
    if (loginstatus === undefined) setLoginstatus(5);
  }, [loginstatus]);

  return (
    <>
      <button className={st.btn_back} onClick={() => navigate(-1)}></button>

      <h2 className={st.title}>Восстановление пароля</h2>

      <p className={st.description}>Введите код из СМС</p>

      <Link className={st.close_btn} to='/'></Link>

      <form className={st.pass_rec_form}>
        <div className={st.note_wrap}>
          <div className={st.input_wrap}>
            <input
              ref={inputRef1}
              className={st.input_code}
              type='text'
              name='codeSimbol1'
              value={codeSimbol1}
              onChange={(e) => handleInputCode(e, setCodeSimbol1)}
              maxLength='1'
            />

            <input
              ref={inputRef2}
              className={st.input_code}
              type='text'
              name='codeSimbol2'
              value={codeSimbol2}
              onChange={(e) => handleInputCode(e, setCodeSimbol2)}
              maxLength='1'
            />

            <input
              ref={inputRef3}
              className={st.input_code}
              type='text'
              name='codeSimbol3'
              value={codeSimbol3}
              onChange={(e) => handleInputCode(e, setCodeSimbol3)}
              maxLength='1'
            />

            <input
              ref={inputRef4}
              className={st.input_code}
              type='text'
              name='codeSimbol4'
              value={codeSimbol4}
              onChange={(e) => handleInputCode(e, setCodeSimbol4)}
              maxLength='1'
            />
          </div>

          <p className={loginstatus !== 5 ? st.note_code : st.note_hide}>Введен неверный код</p>

        </div>

        <button
          className={st.button_submit}
          // onClick={inAuth}
          onClick={() => navigate('/signin/passreccode')}
          disabled={
            codeSimbol1 &&
              codeSimbol2 &&
              codeSimbol3 &&
              codeSimbol4 ? false : true
          }
        >Подтвердить</button>

        <button
          className={st.button_forgot}
          onClick={() => navigate('/signin/passrecphone')}
        >Отправить код ещё раз</button>

        {/* <p className={st.notice}>Новый код для этого номера можно будет запросить через 01:00</p> */}

        {/* <p className={st.notice}>Вы&nbsp;превысили количество запросов кода подтверждения. Попробуйте через 55&nbsp;минут</p> */}

        <button
          className={st.button_submit}
          onClick={() => navigate('/signin/newpassword')}
        >Шаг вперед</button>
      </form>
    </>
  )
};
