import { PHONE_CHECKW } from 'src/consts.js';

// запрос отправляет номер нового телефона для высылки на него проверочного кода
export const fetchConfirmPhone = async (bodyData) => {
  // запрос
  const response = await fetch(PHONE_CHECKW, {
    method: 'POST',
    body: JSON.stringify(bodyData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0'
    }
  });

  const data = await response.json();
  console.log('data: ', data);

  return data;
};
