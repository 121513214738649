import CryptoJS from 'crypto-js';

// функция хэширования пароля
const hashPassword = (password) => {
  let hash = CryptoJS.SHA256(password);

  return hash.toString(CryptoJS.enc.Hex);
};

export default hashPassword;
