import { FEEDBACKCONTACTS_POST } from "src/consts";

// запрос на отправку емайла и телефона для обратной связи
export const fetchFeedbackContacts = async (dataObject, setTracker) => {
  const response = await fetch(FEEDBACKCONTACTS_POST, {
    method: 'POST',
    body: JSON.stringify(dataObject),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0'
    },
  });

  if (response.status === 200) {
    let data = await response.json();
    data = await data.res;
    console.log('data: ', data);

    setTracker(true);

    setTimeout(() => setTracker(false), 5000);
    console.log('Данные успешно отправлены');
  } else console.error('response.status: ', response.status);

  return;
};
