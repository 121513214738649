import st from './SectionWarranty.module.css';
import icon_warr1 from '../../img/icon-park_form-one.svg';
import icon_warr2 from '../../img/icon-park-outline.svg';
import icon_warr3 from '../../img/icon-park-outline_power.svg';
import icon_warr4 from '../../img/icon-park-outline_blocks.svg';
import pinch from '../../img/pinch.svg';
import { useEffect, useRef } from 'react';
import { ReactComponent as SchemaW } from '../../img/schema_w.svg';
import { ReactComponent as SchemaWM } from '../../img/schema_w_mob.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const SectionWarranty = () => {
  // ссылка на список для управления им не через "Swaper"
  const sliderList = useRef(null);

  // функция анимации елементов svg (изменение прозрачности)
  const animationSemicircle = (elem1, elem2, elem3, elem4) => {
    elem1.attributes.opacity.value = '.3';
    setTimeout(() => {
      elem1.attributes.opacity.value = '.16';
    }, 100);

    setTimeout(() => {
      elem2.attributes.opacity.value = '.3';
      setTimeout(() => {
        elem2.attributes.opacity.value = '.12';
      }, 100);
    }, 100);

    setTimeout(() => {
      elem3.attributes.opacity.value = '.3';
      setTimeout(() => {
        elem3.attributes.opacity.value = '.08';
      }, 100);
    }, 200);

    setTimeout(() => {
      elem4.attributes.opacity.value = '.3';
      setTimeout(() => {
        elem4.attributes.opacity.value = '.05';
      }, 100);
    }, 300);
  };

  useEffect(() => {
    const liftupSvg = document.getElementById('liftup_svg');
    const semicircle1 = document.getElementById('semicircle1');
    const semicircle2 = document.getElementById('semicircle2');
    const semicircle3 = document.getElementById('semicircle3');
    const semicircle4 = document.getElementById('semicircle4');

    liftupSvg.addEventListener(
      'mouseenter',
      () => animationSemicircle(semicircle1, semicircle2, semicircle3, semicircle4)
    );

    liftupSvg.addEventListener(
      'mouseleave',
      () => animationSemicircle(semicircle1, semicircle2, semicircle3, semicircle4)
    );
  }, []);

  return (
    <section className={st.sectionwarranty}>

      <div className={`${st.background_1} outer_container`}>
        <div className={st.illuminat}></div>
        <div className='container'>
          <h2 className={st.sectionwarranty_title}>Как оформить гарантию</h2>

          <div className={st.slider_warratny}>
            {/* <div> */}
            <Swiper
              ref={sliderList}
              className='warranty_swiper'
              modules={[Pagination, A11y, Scrollbar]}
              slidesPerView={4}
              spaceBetween={20}
              onSlideChange={() => { }}
              onSwiper={(swiper) => { }}
              // grabCursor={true}
              direction={'horizontal'}
              // loop='true'
              // это кнопки
              // navigation={{
              //   prevEl: btnPrevRef.current,
              //   nextEl: btnNextRef.current,
              // }}
              // для работы кнопок
              // onInit={() => setInit(true)}
              // шаг листания слайдера
              // slidesPerGroup={2}
              breakpoints={
                {
                  1280: {
                    slidesPerView: 4,
                    spaceBetween: 30
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  },
                  600: {
                    slidesPerView: 2
                  },
                  300: {
                    slidesPerView: 1
                  }
                }
              }
              // pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >

              <SwiperSlide>
                <div className={st.warranty_item}>
                  <h4>01</h4>

                  <div className={st.item_wrap}>
                    <div className={st.circle_white}>
                      <div className={st.circle_red}></div>
                      <img src={icon_warr1} alt='' />
                    </div>
                    <div className={st.item_arrow}></div>
                  </div>
                  <h4>Заполнение заявки</h4>
                  <p>На&nbsp;сайте покупатель может заполнить заявку на&nbsp;дополнительную гарантию для <nobr>подъемно-транспортного</nobr> обрудования</p>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={st.warranty_item}>
                  <h4>02</h4>

                  <div className={st.item_wrap}>
                    <div className={st.circle_white}>
                      <div className={st.circle_red}></div>
                      <img src={icon_warr2} alt='' />
                    </div>
                    <div className={st.item_arrow}></div>
                  </div>
                  <h4>Оформление договора</h4>
                  <p>Покупатель с&nbsp;представителем LIFTUP оформляют договор и&nbsp;выбирают способ оплаты</p>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={st.warranty_item}>
                  <h4>03</h4>

                  <div className={st.item_wrap}>
                    <div className={st.circle_white}>
                      <div className={st.circle_red}></div>
                      <img src={icon_warr3} alt='' />
                    </div>
                    <div className={st.item_arrow}></div>
                  </div>
                  <h4>Активация гарантии</h4>
                  <p>Данные покупателя заносятся в&nbsp;базу данных LIFTUP, гарантия активируется, а&nbsp;покупатель получает доступ к&nbsp;личному кабинету</p>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className={st.warranty_item}>
                  <h4>04</h4>

                  <div className={st.item_wrap}>
                    <div className={st.circle_white}>
                      <div className={st.circle_red}></div>
                      <img src={icon_warr4} alt='' />
                    </div>
                    <div className={st.item_arrow_hidden}></div>
                  </div>
                  <h4>Использование гарантии</h4>
                  <p>При возникновении проблем, покупатель может обратиться за&nbsp;помощью по&nbsp;своей гарантии через личный кабинет</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className={`${st.background_2} outer_container`}>
        <div className='container'>
          <h3 className={st.background_2_title}>Как работает гарантия</h3>

          <SchemaW className={st.schema_w} />
          <SchemaWM className={st.schema_wm} />

          <img className={st.pinch} src={pinch} alt='' />
        </div>
      </div>

    </section>
  )
};
