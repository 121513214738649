import { SHS_POST } from 'src/consts.js';
import { CHANGE_MAIL } from 'src/consts.js';

// запрос отправляет данные на смену пароля из панели личного кабинета пользователя
export const fetchChangeEmail = async (bodyData1, bodyData2) => {
  // запрос на получение SHC по номеру телефона
  const response = await fetch(SHS_POST, {
    method: 'POST',
    body: JSON.stringify(bodyData1),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
    },
  });
  const data1 = await response.json();
  console.log('data: ', data1);
  console.log('response.status: ', response.status);

  if (response.status === 200) {
    // второй запрос на смену e-mail
    const response = await fetch(CHANGE_MAIL, {
      method: 'POST',
      body: JSON.stringify(bodyData2),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        'shc': data1.shc,
      },
    });
    const data2 = await response.json();
    console.log('data: ', data2);

    if (response.status === 200) {
      console.log('e-mail успешно изменен пройдите по ссылке в писме, чтобы подтвердить его');
    } else { console.log('e-mail не изменен, ошибка') }

    return;
  }

  console.log('SHC не получен! ошибка');

  return;
};
