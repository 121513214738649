import st from './NewsEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import nophoto from '../../../../../../img/nophoto.svg';
import { useDispatch } from 'react-redux';
import { antigoblin } from 'src/utils/antigoblin';
import { fetchNewsList } from 'src/store/news/getNewsList';
import { fetchNewsNoPhotoAddEdit, fetchNewsWithPhotoAddEdit } from '../../fetches/fetchAddEdit';

export const NewsEdit = ({
  closeModalEdit,
  targetId,
  targetImagelink,
  targetNewsname,
  targetNewsdate,
  targetNewspreview,
  targetNewsvalue,

}) => {
  // инпуты ввода "фото новости", "заголовок новости", "анонс новости", "дата новости"
  const [imagePreviewEdit, setImagePreviewEdit] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);

  const [newsname, setNewsname] = useState('');
  const [newspreview, setNewspreview] = useState('');
  // const [newsvalue, setNewsvalue] = useState('');
  // console.log('newsvalue: ', newsvalue);
  const [newsdate, setNewsdate] = useState('');

  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelected = useRef(null);

  // инпут ввода самой новости с помощью редактора TinyMCE
  // const initialText = '';
  const [editorText, setEditorText] = useState('');
  // console.log('editorText: ', editorText); // editorText нигде больше не используется, но нужен
  const [editorValue, setEditorValue] = useState('');
  // console.log('editorValue: ', editorValue.replaceAll('"', '\\"'));

  // вставляем полученные данные полей выбранной новости и следим за изменениями 
  useEffect(() => {
    setNewsname(targetNewsname);
    setNewspreview(targetNewspreview);
    // setNewsvalue(targetNewsvalue);
    setNewsdate(targetNewsdate);
  }, [targetNewsdate, targetNewspreview, targetNewsvalue, targetNewsname]);

  const dispatch = useDispatch();

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  // данные для второго запроса
  const newsDataBody = {
    'newsid': targetId,
    'newsname': newsname,
    'newsvalue': editorValue,
    'newsdate': antigoblin(newsdate),
    'newsactive': true,
    'newspreview': newspreview,
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);
    console.log(formData.uploadedFile);

    // первое это отправка с фото второе - отправка без фото
    if (selectedFileDouble !== '') {
      await fetchNewsWithPhotoAddEdit(formData, newsDataBody);
    } else {
      await fetchNewsNoPhotoAddEdit(newsDataBody);
    }

    dispatch(fetchNewsList());

    closeModalEdit();

    console.log('форма отправлена');
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreview(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью добавить файл"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setImagePreview(null);
    setSelectedFileDouble('');
  };

  // удаляем поле "превью редактировать файл"
  const clearPreviewFileEdit = (e) => {
    e.preventDefault();
    setImagePreviewEdit(false);
  };


  return (
    <div className={st.newsedit_wrap}>
      <button className={st.btn_close} onClick={closeModalEdit}>
        <BtnClose />
      </button>

      <div className={st.newsedit}>
        <h3 className={st.title}>Редактирование новости</h3>

        <form className={st.form}>
          {/* блок с превью выбранного для редактирования фото новости */}
          <div className={imagePreviewEdit === true ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Фото новости</p>

            <div className={st.image_preview}>
              <img src={targetImagelink ? targetImagelink : nophoto} alt={targetNewspreview} />
              <button className={st.btn_delete} onClick={clearPreviewFileEdit}></button>
            </div>
          </div>

          {/* блок с превью выбранного для загрузки нового фото новости */}
          <div className={imagePreview !== null ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Фото для новости</p>

            <div className={st.image_preview}>
              {imagePreview !== null && <img src={imagePreview} alt='' />}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора нового фото новости для загрузки из ПК */}
          <fieldset className={
            imagePreview === null && imagePreviewEdit === false
              ? st.fieldset_show : st.fieldset_hide}>
            <label className={st.label_image}>
              <input
                ref={fileSelected}
                className={st.input_image}
                type='file'
                name='image'
                accept='image/*,image/jpeg,image/png,image/svg'
                onChange={previewFile}
              />

              <p>Фото для новости...</p>

              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут ввода заголовка новости */}
          <div className={st.wrap_title}>
            <p className={newsname !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Заголовок новости</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  // ref={refTitle}
                  className={st.input_title}
                  type='text'
                  name='newsname'
                  value={newsname}
                  placeholder='Заголовок новости'
                  onChange={(e) => setNewsname(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода анонса новости */}
          <div className={st.wrap_title}>
            <p className={newspreview !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Анонс новости</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='newspreview'
                  value={newspreview}
                  placeholder='Анонс новости'
                  onChange={(e) => setNewspreview(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода новости */}
          <div className={st.wrap_title}>
            <p className={editorValue !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Текст новости</p>

            <Editor
              apiKey='4wtzqhvlph75vbd3kofhrprf5cx68r4ujrf59ujf8hujuer1'

              initialValue={targetNewsvalue}

              outputFormat='text' // проверить нужен или нет

              onEditorChange={(newValue, editor) => {
                setEditorValue(newValue);
                setEditorText(editor.getContent({ format: 'text' }));
              }}

              onInit={(evt, editor) => {
                setEditorText(editor.getContent({ format: 'text' }));
              }}

              value={editorValue}

              init={{
                // plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tableofcontents footnotes mergetags autocorrect typography inlinecss',
                menubar: false,
                plugins: 'link',
                toolbar: 'undo redo blocks | bold italic underline forecolor backcolor | link unlink | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat',
                height: '280px',
                language: 'ru',
                skin: 'oxide-dark',
                content_css: 'dark'
              }}
            />
          </div>

          {/* инпут ввода даты новости */}
          <div className={st.wrap_title}>
            <p className={newsdate !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Дата публикации</p>

            <fieldset className={st.fieldset_date}>
              <label className={st.label_title}>
                <input
                  className={st.input_date}
                  type='date'
                  name='newsdate'
                  value={newsdate}
                  placeholder='Дата публикации'
                  onChange={(e) => setNewsdate(e.target.value)}
                />

                <div className={st.input_after}></div>
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={handleFormSubmit}
          >Сохранить</button>
        </form>
      </div>
    </div>
  )
};
