import st from './DeptNews.module.css';
import { useState, useEffect } from 'react';
import { ReactComponent as IconSetting } from '../../../../img/icon_setting.svg';
// import { news_list } from './data/news_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from 'src/components/Overlay/Overlay';
import { NewsAdd } from './components/NewsAdd/NewsAdd';
import { NewsPreview } from './components/NewsPreview/NewsPreview';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsList } from 'src/store/news/getNewsList';

export const DeptNews = () => {
  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);

  const { newsList } = useSelector(state => state.newsListReducer.dataNewsList);
  const { status } = useSelector(state => state.newsListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!newsList) {
      dispatch(fetchNewsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 4;

  // индекс последней новости
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой новости
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  if (status === 'success') {
    // ===== пагинация продолжение по условию=====
    // список элементов текущей страницы
    const statementsCurrentList = newsList.slice(firstStatementIndex, lastStatementIndex);

    // общее количество заявок
    const totalStatementsQuantity = newsList.length;

    // список номеров-кнопок страниц
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
      pages.push(i);
    }

    // функция кнопки следующая страница
    const nextPage = () => {
      setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
    };

    // функция кнопки предыдущая страница
    const prevPage = () => {
      setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
    };
    // ===== пагинация =====

    return (
      <>
        <div className={st.deptnews}>
          <div className={st.title_and_btn}>
            <h3 className={st.title}>Новости</h3>

            <button className={st.add_news} onClick={() => setModalAdd(!modalAdd)}>
              <svg width="19" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 1.75v16.5M17.75 10H1.25" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
              </svg>

              Добавить новость
            </button>
          </div>

          {/* список заголовков таблицы */}
          <ul className={st.table_header}>
            <li className={st.header_item}>Фото</li>
            <li className={st.header_item}>Заголовок</li>
            <li className={st.header_item}>Дата публикации</li>
            <li className={st.header_item}><IconSetting /></li>
          </ul>

          {/* список новостей */}
          <ul className={st.news_list}>
            {statementsCurrentList.map(item =>
              <NewsPreview
                key={uuidv4()}
                newsid={item.newsid}
                imagelink={item.imagelink}
                newsname={item.newsname}
                newsdate={item.newsdate}
                newspreview={item.newspreview}
                newsvalue={item.newsvalue}
              />
            )}
          </ul>

          {/* пагинация */}
          <div className={st.pagination}>
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
              onClick={() => {
                prevPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
            {
              pages.map((item) => {
                return (
                  <button
                    key={uuidv4()}
                    onClick={() => {
                      setCurrentPageNumber(item);
                      window.scrollTo(0, 0);
                    }}
                    className={
                      pages.length === 1 ? st.hidden :
                        item === currentPageNumber
                          ? st.pagination_item_active
                          : st.pagination_item
                    }>
                    {item}
                  </button>
                );
              })
            }
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
              onClick={() => {
                nextPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
          </div>
        </div>

        <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
          <NewsAdd closeModalAdd={() => setModalAdd(false)} />
        </Overlay>
      </>
    )
  } else {
    return (
      <div className={st.deptnews}>
        <div className={st.title_and_btn}>
          <h3 className={st.title}>Новости</h3>
        </div>

        <p>загрузка DeptNews...</p>
      </div>
    );
  }
};
