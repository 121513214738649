import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { PHONE_CHECK } from '../../consts.js';
import { PHONE_RECOVERY } from '../../consts.js';

export const fetchPhoneCheck = createAsyncThunk(
  'passRecovery/fetchPhoneCheck',

  async (bodyObj) => {

    // const response = await fetch(PHONE_CHECK, {
    const response = await fetch(PHONE_RECOVERY, {
      method: 'POST',
      body: JSON.stringify(bodyObj),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });

    const data = await response.json();
    console.log('data: ', data);

    return data;
  }
);


const initialState = {
  status: 'idle',
  phoneCheckStatus: null,
  error: null,
};

// получаем ответ существует ли юзер с отправленным номером телефона
const passRecoverySlice = createSlice({
  name: 'passRecovery',
  initialState,
  reducers: {
    removephoneCheckStatus: (state) => {
      state.phoneCheckStatus = null;
    }
  },

  extraReducers: (builder) => {
    builder

      .addCase(fetchPhoneCheck.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPhoneCheck.fulfilled, (state, action) => {
        state.status = 'success';
        state.phoneCheckStatus = action.payload;
      })
      .addCase(fetchPhoneCheck.rejected, (state, action) => {
        state.status = 'failed';
        state.phoneCheckStatus = action.error.message;
      })

  }
});

export const { removephoneCheckStatus } = passRecoverySlice.actions;

export default passRecoverySlice.reducer;
