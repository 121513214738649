import { MEDIAFILE_ADD, SERVICECENTERS_ADD } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// двойной запрос: добавляем медиафайл, добавляем остальные данные с ответом из первого запроса
export const fetchServiceCentersAddEdit = async (bodyMediaFile, bodyData) => {
  // первый запрос
  const responsemediafile = await fetch(MEDIAFILE_ADD, {
    method: 'POST',
    body: bodyMediaFile,
    headers: {
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const datamediafile = await responsemediafile.json();

  // в случае протухания токена обновляем пару "token - rtoken"
  if (responsemediafile.status === 401 || datamediafile.loginstatus === 7) {
    // перезапись токенов
    await updateTokens();
    // первый запрос
    const responsemediafile = await fetch(MEDIAFILE_ADD, {
      method: 'POST',
      body: bodyMediaFile,
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datamediafile = await responsemediafile.json();
    // console.log('datamediafile: ', datamediafile);

    // добавляем полученные из первого запроса данные в массив данных для body
    bodyData.uidlogo = datamediafile.mediafileuid;

    // второй запрос
    const responseservcenteradd = await fetch(SERVICECENTERS_ADD, {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });

    if (responseservcenteradd.status === 200) {
      const dataservcenteradd = await responseservcenteradd.json();
      console.log('dataservcenteradd: ', dataservcenteradd);
    } else {
      console.error('произошла ошибка, статус ответа сервера: ', responseservcenteradd.status);
    }

    console.log('данные на добавление сервисного центра отправлены c перезаписью токенов');

  } else {

    // добавляем полученные из первого запроса данные в массив данных для body
    bodyData.uidlogo = datamediafile.mediafileuid;

    // второй запрос
    const responseservcenteradd = await fetch(SERVICECENTERS_ADD, {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });

    if (responseservcenteradd.status === 200) {
      const dataservcenteradd = await responseservcenteradd.json();
      console.log('dataservcenteradd: ', dataservcenteradd);
    } else {
      console.error('произошла ошибка, статус ответа сервера: ', responseservcenteradd.status);
    }

    console.log('данные на добавление сервисного центра отправлены без перезаписи токенов');
  }
};
