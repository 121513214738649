import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SLIDES_LIST } from 'src/consts.js';

// получаем список слайдов
export const fetchSlidesList = createAsyncThunk(
  'slidesList/fetchSlidesList',

  async () => {
    const response = await fetch(SLIDES_LIST, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    }
    );

    const data = await response.json();

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataSlidesList: {},
  error: null,
};

const slidesListSlice = createSlice({
  name: 'slidesList',
  initialState,
  reducers: {
    // removeSlidesList: (state) => {
    //   state.dataNewsList = {};
    // }
  },

  extraReducers: (builder) => {
    builder
      // при загрузке сайта
      .addCase(fetchSlidesList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSlidesList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataSlidesList = action.payload;
      })
      .addCase(fetchSlidesList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataSlidesList = action.error.message;
      })
  }
});


// export const { removeSlidesList } = slidesListSlice.actions;

export default slidesListSlice.reducer;
