import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVICECENTERS_LIST } from "src/consts.js";

// получаем список наших клиентов
export const fetchServicecentersList = createAsyncThunk(
  'servicecentersList/fetchServicecentersList',

  async () => {
    const response = await fetch(SERVICECENTERS_LIST, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    }
    );

    const data = await response.json();
    // console.log('data: ', data);

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataServicecentersList: {},
  error: null,
};

const servicecentersListSlice = createSlice({
  name: 'servicecentersList',
  initialState,
  reducers: {
    removeServicecentersList: (state) => {
      state.dataServicecentersList = {};
    }
  },

  extraReducers: (builder) => {
    builder
      // при загрузке сайта
      .addCase(fetchServicecentersList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchServicecentersList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataServicecentersList = action.payload;
      })
      .addCase(fetchServicecentersList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataServicecentersList = action.error.message;
      })
  }
});


export const { removeServicecentersList } = servicecentersListSlice.actions;

export default servicecentersListSlice.reducer;
