import st from './PDFPreview.module.css';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export const PDFPreview = ({ documentlink }) => {

  // это иногда не работает при работе локально (после включения получения данных с сервера)
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  // это не работало при сборке на хосте
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   'pdfjs-dist/build/pdf.worker.min.js',
  //   import.meta.url,
  // ).toString();

  return (
    <Document className={st.pdfpreview} file={documentlink}>
      <Page pageNumber={1} width={260}></Page>
    </Document>
  )
};
