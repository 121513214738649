import st from './LiftDownAdd.module.css';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// import { equipment_list } from '../../data/equipment_list_json.js';
import { fetchWarrantyService } from '../../fetches/fetchWarrantyService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLiftDownListOneOrg } from 'src/store/liftdown/getLiftDownList';
import { fetchEquipmentList } from 'src/store/equipment/getEquipmentList';

export const LiftDownAdd = ({
  closeModal
}) => {
  // в данном разделе используется список имеющегося у организации оборудования
  // чтобы при подаче заявки на гарантийное обслуживание можно было выбрать и 
  // автоматически заполнить некоторые поля
  const dispatch = useDispatch();

  // список оборудования в store
  const { equipmentOfOrganizatioList } = useSelector(state => state.equipmentListReducer.dataEquipmentList);

  // uuid организации для запроса получения оборудования
  const { uuid } = useSelector(state => state.authReducer.dataAuth.organization);

  // инпут ввода "заводского номера оборудования"
  const [inputFactoryNum, setInputFactoryNum] = useState('');
  // инпут ввода "названия детали"
  const [inputDetailName, setInputDetailName] = useState('');

  // поля данных выбранного оборудования
  const [manufacturer, setManufacturer] = useState('');
  const [brand, setBrand] = useState('');

  const [equid, setEquid] = useState();
  const [efouid, setEfouid] = useState();

  // поля данных выбранной детали
  const [indiNum, setIndiNum] = useState('');
  const [selfnumber, setSelfnumber] = useState('');
  const [prodDate, setProdDate] = useState('');
  const [warrantDate, setWarrantDate] = useState('');
  const [warrantDateFinish, setWarrantDateFinish] = useState('');

  // показываем прячем список у инпута "Заводской номер оборудования"
  const [inputSelect, setInputSelect] = useState(false);

  // показываем прячем список у инпута "Название детали"
  const [inputSelectDetName, setInputSelectDetName] = useState(false);

  // управление полем "Дополнительная информация"
  const [additInfo, setAdditInfo] = useState('');

  // чекбоксы управление
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);

  // ссылки на инпуты выбора "завод номера" и "имени детали"
  const inputFactNumRef = useRef(null);
  const aroowInputFactNumRef = useRef(null);
  const inputDetNameRef = useRef(null);
  const aroowInputDetNameRef = useRef(null);

  // функция получения текущей даты в нужном формате
  const getCurrentDate = () => {
    const date = new Date();
    // возвращаем в нужном формате
    return date.toISOString().slice(0, -14);
  };

  // функция получения массива заводских номеров
  const getArrFactNum = () => {
    const arr = [];
    equipmentOfOrganizatioList?.forEach(item => {
      arr.push(item.factorynumber);
    })
    return arr;
  };

  // массив заводских номеров оборудования
  const [factNumArray, setFactNumArray] = useState([]);

  // объект деталей выбранного оборудования
  const [arrDetails, setArrDetails] = useState({});

  // функция получения и вставки данных объекта выбранного оборудования
  const targetDataEquipForOrder = (factoryNum) => {
    equipmentOfOrganizatioList.forEach(item => {

      if (item.factorynumber === factoryNum) {

        let itemPartsListNew = [...item.partsList];

        setManufacturer(item.manufacturer.manufacturername);
        setBrand(item.brand.brandname);
        setArrDetails(
          item.typeOfEquipment === 'Elevator' ?
            itemPartsListNew : itemPartsListNew.slice(0, -1)
        );

        setEquid(item.equid);
        setEfouid(item.efouid);

        // поле имя детали
        setInputDetailName('');

        // поля детали
        setIndiNum('');
        setProdDate('');
        setWarrantDate('');
        setWarrantDateFinish('');
      }
    })
  };

  // функция получения и вставки данных объекта выбранной детали
  const targetDataDetForOrder = (e) => {
    arrDetails.forEach(item => {
      if (e.target.textContent === item.partname) {
        setIndiNum(item.selfnumber !== '' ? item.partid : '');
        setSelfnumber(item.selfnumber);
        setProdDate(item.equipmentDate.dateofcommissioning ?
          item.equipmentDate.dateofcommissioning.slice(0, -9).split('-').reverse().join('-') : '');
        setWarrantDate(item.equipmentDate.dateofacceptanceforwarranty ?
          item.equipmentDate.dateofacceptanceforwarranty.slice(0, -9).split('-').reverse().join('-') : '');
        setWarrantDateFinish(item.equipmentDate.warrantyexpirationdate ?
          item.equipmentDate.warrantyexpirationdate?.slice(0, -9).split('-').reverse().join('-') : '');
      }
    })
  };

  // функция поиска заводск номера по вводу
  const filterFactNumArr = (e) => {
    setInputFactoryNum(e.target.value);
    let newArr = [];

    getArrFactNum().forEach(item => {
      if (item.includes(e.target.value)) {
        newArr.push(item);
      }
    })

    setFactNumArray(newArr);
  };


  useEffect(() => {
    // если "equipmentOfOrganizatioList" нет в store, то диспатчим его в store 
    if (!equipmentOfOrganizatioList) {
      // сurrentOrganizationUuid это uuid выбранной организации для построения списка ее оборудования
      dispatch(fetchEquipmentList(uuid));
    }

    // устанавливаем список заводских номеров оборудования
    setFactNumArray(getArrFactNum);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, equipmentOfOrganizatioList]);

  // закрываем вып список "заводск номер" по клику вне инпута
  useEffect(() => {
    let handler = (e) => {
      if (!inputFactNumRef.current.contains(e.target) &&
        !aroowInputFactNumRef.current.contains(e.target)) {
        setInputSelect(false);
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    }
  });

  // закрываем вып список "имя детали" по клику вне инпута
  useEffect(() => {
    let handler = (e) => {
      if (!inputDetNameRef.current.contains(e.target) &&
        !aroowInputDetNameRef.current.contains(e.target)) {
        setInputSelectDetName(false);
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    }
  });

  // очищаем поля автомат заполнения при выборе "заводск номера"
  useEffect(() => {
    if (!factNumArray.length || inputFactoryNum === '') {
      setManufacturer('');
      setBrand('');
      setInputDetailName('');
      setArrDetails({});

      // поля детали
      setIndiNum('');
      setProdDate('');
      setWarrantDate('');
      setWarrantDateFinish('');
    }

  }, [factNumArray, inputFactoryNum]);

  // данные для тела запроса подачи заявки на гарантийное обслуживание
  const bodyWarrantyService = {
    'uid': equid, // возможно equid, пока отправим пустым
    'status': 'NEW', // первональный статус заявки
    'organization': {
      'id': uuid // uuid организации
    },
    'date': getCurrentDate(), // текущая дата
    'equipmentOfOrganization': {
      'efouid': efouid // uuid оборудования
    },
    'infowarrantycase': additInfo, // письменное сообщение
    'orderpartreplace': checked1, // Заказать услуги по замене детали
    'ordernonwarrantypart': checked2, // Заказать негарантийную деталь
    'part': {
      'partid': indiNum, // uuid детали
      'partname': inputDetailName, // Наименование детали
      'selfnumber': selfnumber, // Индивидуальный номер
      'equipmentDate': {
        'dateofcommissioning': prodDate, // Дата производства
        'dateofacceptanceforwarranty': warrantDate, // Дата приемки на гарантию
        'warrantyexpirationdate': warrantDateFinish // Дата окончания гарантии
      }
    },
  };

  // функция отправки запроса на добавление новой заявки
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await fetchWarrantyService(bodyWarrantyService);

    // обновляем список заявок в stor
    dispatch(fetchLiftDownListOneOrg(uuid));

    closeModal();
    console.log('форма отправлена');
  };


  return (
    <div className={st.clientsedit_wrap}>
      <button
        className={st.btn_close}
        onClick={closeModal}>
      </button>

      <div className={st.clientsedit}>
        <div className={st.container}>

          {/* окно с заявкой */}
          <div className={st.window_order}>
            <h3 className={st.title}>Подача заявки</h3>

            <form className={st.form}>

              {/* поле заводской номер оборудования */}
              <div className={st.wrap_input}>
                <p className={inputFactoryNum !== '' ? st.wrap_title_p : st.hidden}>
                  Заводской номер оборудования
                </p>

                <div className={st.input_wrap}>
                  <div className={st.label}>
                    <input
                      className={st.input_select}
                      ref={inputFactNumRef}
                      type='text'
                      maxLength={12}
                      placeholder='Заводской номер оборудования'
                      value={inputFactoryNum}
                      onChange={filterFactNumArr}
                      onClick={() => setInputSelect(true)}
                    />

                    <div
                      className={!inputSelect ? st.arrow : `${st.arrow} ${st.arrow_trans}`}
                      ref={aroowInputFactNumRef}
                      onClick={!inputSelect ? () => setInputSelect(true) : () => setInputSelect(false)}
                    ></div>

                    <ul
                      className={st.factorynumber_list}
                      style={!inputSelect ? { height: '0' } : { height: `calc(${factNumArray.length} * 31px)` }}
                    >
                      {
                        factNumArray.length ?
                          factNumArray.map(elem => (

                            <li
                              key={uuidv4()}
                              onClick={(e) => {
                                setInputFactoryNum(e.target.textContent);
                                targetDataEquipForOrder(e.target.textContent);
                              }}>
                              {elem}
                            </li>

                          )) : <li>номера не найдены!</li>
                      }
                    </ul>
                  </div>
                </div>
              </div>

              {/* поле "производитель" */}
              <div className={st.wrap_input}>
                <p className={manufacturer !== '' ? st.wrap_title_p : st.hidden}>
                  Производитель</p>

                <input
                  className={st.input_dis}
                  placeholder='Производитель'
                  value={manufacturer}
                  disabled={true}
                />
              </div>

              {/* поле "марка" */}
              <div className={st.wrap_input}>
                <p className={brand !== '' ? st.wrap_title_p : st.hidden}>
                  Марка</p>

                <input
                  className={st.input_dis}
                  placeholder='Марка'
                  value={brand}
                  disabled={true}
                />
              </div>

              {/* поле "название детали" */}
              <div className={st.wrap_input}>
                <p className={inputDetailName !== '' ? st.wrap_title_p : st.hidden}>
                  Название детали
                </p>

                <div className={st.input_wrap}>
                  <div className={st.label}>
                    <input
                      className={st.input_select}
                      ref={inputDetNameRef}
                      type='text'
                      maxLength={16}
                      placeholder='Название детали'
                      value={inputDetailName}
                      onChange={() => setInputDetailName('')}
                      onClick={() => setInputSelectDetName(true)}
                    />

                    <div
                      className={!inputSelectDetName ? st.arrow : `${st.arrow} ${st.arrow_trans}`}
                      ref={aroowInputDetNameRef}
                      onClick={!inputSelectDetName ? () => setInputSelectDetName(true) : () => setInputSelectDetName(false)}
                    ></div>

                    <ul
                      className={st.factorynumber_list}
                      style={!inputSelectDetName ? { height: '0' } : { height: `calc(${arrDetails.length} * 31px)` }}
                    >
                      {
                        arrDetails.length ?
                          arrDetails.map(item => (

                            <li
                              key={uuidv4()}
                              onClick={(e) => {
                                setInputDetailName(e.target.textContent);
                                targetDataDetForOrder(e);
                              }}>
                              {item.partname}
                            </li>

                          )) : <li>детали не найдены</li>
                      }
                    </ul>
                  </div>
                </div>
              </div>

              {/* поле "Индивидуальный номер" */}
              <div className={st.wrap_input}>
                <p className={indiNum !== '' ? st.wrap_title_p : st.hidden}>
                  Индивидуальный номер</p>

                <input
                  className={st.input_dis}
                  placeholder='Индивидуальный номер'
                  value={indiNum}
                  disabled={true}
                />
              </div>

              {/* поле "Дата производства" */}
              <div className={st.wrap_input}>
                <p className={prodDate !== '' ? st.wrap_title_p : st.hidden}>
                  Дата производства</p>

                <input
                  className={st.input_dis}
                  placeholder='Дата производства'
                  value={prodDate}
                  disabled={true}
                />
              </div>

              {/* поле "Дата приемки на гарантию" */}
              <div className={st.wrap_input}>
                <p className={warrantDate !== '' ? st.wrap_title_p : st.hidden}>
                  Дата приемки на гарантию</p>

                <input
                  className={st.input_dis}
                  placeholder='Дата приемки на гарантию'
                  value={warrantDate}
                  disabled={true}
                />
              </div>

              {/* поле "Дата окончания гарантии" */}
              <div className={st.wrap_input}>
                <p className={warrantDateFinish !== '' ? st.wrap_title_p : st.hidden}>
                  Дата окончания гарантии</p>

                <input
                  className={st.input_dis}
                  placeholder='Дата окончания гарантии'
                  value={warrantDateFinish}
                  disabled={true}
                />
              </div>

              {/* поле ввода доп информации */}
              <textarea
                className={st.addit_info}
                id='additInfo'
                name='additInfo'
                rows='3'
                maxLength='600'
                minLength='2'
                spellCheck='true'
                placeholder='Информация о гарантийном случае'
                value={additInfo}
                onChange={(e) => setAdditInfo(e.target.value)}
              ></textarea>

              {/* чкбокс "Заказать услуги по замене детали" */}
              <fieldset className={st.fieldset_assent}>
                <label className={st.label_check}>
                  <input
                    className={st.input_check}
                    type='checkbox'
                    name='checkbox1'
                    checked={checked1}
                    onChange={() => setChecked1(!checked1)}
                  />

                  <p>Заказать услуги по замене детали</p>
                </label>
              </fieldset>

              {/* чкбокс "Заказать негарантийную деталь" */}
              <fieldset className={st.fieldset_assent}>
                <label className={st.label_check}>
                  <input
                    className={st.input_check}
                    type='checkbox'
                    name='checkbox2'
                    checked={checked2}
                    onChange={() => setChecked2(!checked2)}
                  />

                  <p>Заказать негарантийную деталь</p>
                </label>
              </fieldset>

              <button
                className={st.btn_tosend}
                onClick={handleFormSubmit}
                disabled={indiNum === '' ? true : false}
              >Отправить</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
