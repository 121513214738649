import { FIASGETADDRESSES_POST, FIASREGIONS_GET } from "src/consts";

// запрос на получение данных от ФИАС регионы (liftupAPI схема)
export const fetchGetRegionFias = async (setData1, setData2) => {
  const response = await fetch(FIASREGIONS_GET, {
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0'
    },
  });

  let data = await response.json();
  data = await data.addresses;

  setData1(data);
  setData2(data);
  return;
};

// запрос на получение данных от ФИАС всё ниже региона (браузерная схема)
export const fetchGetDataFias = async (dataObject, setData1, setData2) => {
  const response = await fetch(FIASGETADDRESSES_POST, {
    method: 'POST',
    body: JSON.stringify(dataObject),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0'
    },
  });

  let data = await response.json();
  data = await data.addresses;

  setData1(data);
  setData2(data);
  return;
};
