import st from './DeptOurClients.module.css';
import { useEffect, useState } from 'react';
import { ReactComponent as IconSetting } from '../../../../img/icon_setting.svg';
// import { clients_list } from './data/clients_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from 'src/components/Overlay/Overlay';
import { OurClientsAdd } from './components/OurClientsAdd/OurClientsAdd.jsx';
import { OurClientsPreview } from './components/OurClientsPreview/OurClientsPreview.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurclientsList } from 'src/store/ourclients/getOurclientsList';

export const DeptOurClients = () => {
  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);
  // сортированный массив документов
  const [ourClientsListSort, setOurClientsListSort] = useState([]);

  // список наших клиентов в store
  const { ourorgs } = useSelector(state => state.ourclientsListReducer.dataOurclientsList);
  // статус  выполнения запроса на получение списка наших клиентов
  const { status } = useSelector(state => state.ourclientsListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!ourorgs) {
      dispatch(fetchOurclientsList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setOurClientsListSort(Array.from(ourorgs).sort((a, b) => {
        return (a.orgcount > b.orgcount) - 0.5;
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);


  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество наших клиентов на странице
  const statementsQuantity = 4;

  // индекс последнего нашего клиента
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первого нашего клиента
  const firstStatementIndex = lastStatementIndex - statementsQuantity;


  if (status === 'success') {
    // ===== пагинация продолжение по условию=====
    // список элементов текущей страницы
    const statementsCurrentList = ourClientsListSort.slice(firstStatementIndex, lastStatementIndex);

    // общее количество наших клиентов
    const totalStatementsQuantity = ourClientsListSort.length;

    // список номеров-кнопок страниц
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
      pages.push(i);
    }

    // функция кнопки следующая страница
    const nextPage = () => {
      setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
    };

    // функция кнопки предыдущая страница
    const prevPage = () => {
      setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
    };
    // ===== пагинация =====

    return (
      <>
        <div className={st.deptclients}>
          <div className={st.title_and_btn}>
            <h3 className={st.title}>Наши клиенты</h3>

            <button className={st.add_news} onClick={() => setModalAdd(!modalAdd)}>
              <svg width="19" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 1.75v16.5M17.75 10H1.25" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
              </svg>
              Добавить клиента
            </button>
          </div>

          {/* список заголовков таблицы */}
          <ul className={st.table_header}>
            <li className={st.header_item}>№</li>
            <li className={st.header_item}>Логотип</li>
            <li className={st.header_item}>Наименование</li>
            <li className={st.header_item}><IconSetting /></li>
          </ul>

          {/* список  клиентов */}
          <ul className={st.clients_list}>
            {statementsCurrentList.map(item =>
              <OurClientsPreview
                key={uuidv4()}
                id={item.id}
                logo={item.logo}
                link={item.link}
                name={item.name}
                orgcount={item.orgcount}
              />
            )}
          </ul>

          {/* пагинация */}
          <div className={st.pagination}>
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
              onClick={() => {
                prevPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
            {
              pages.map((item) => {
                return (
                  <button
                    key={uuidv4()}
                    onClick={() => {
                      setCurrentPageNumber(item);
                      window.scrollTo(0, 0);
                    }}
                    className={
                      pages.length === 1 ? st.hidden :
                        item === currentPageNumber
                          ? st.pagination_item_active
                          : st.pagination_item
                    }>
                    {item}
                  </button>
                );
              })
            }
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
              onClick={() => {
                nextPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
          </div>
        </div>

        <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
          <OurClientsAdd
            closeModal={() => setModalAdd(false)}
            ourorgs={ourorgs}
            status={status}
          />
        </Overlay>
      </>
    )
  } else {
    return (
      <div className={st.deptclients}>
        <div className={st.title_and_btn}>
          <h3 className={st.title}>Наши клиенты</h3>
        </div>

        <p>загрузка DeptOurClients...</p>
      </div>
    );
  }

};
