import st from './SectionClients.module.css';
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurclientsList } from 'src/store/ourclients/getOurclientsList';

export const SectionClients = () => {
  // сортированный массив документов
  const [ourClientsListSort, setOurClientsListSort] = useState([]);

  const { ourorgs } = useSelector(state => state.ourclientsListReducer.dataOurclientsList);
  const { status } = useSelector(state => state.ourclientsListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!ourorgs && status !== 'failed') {
      dispatch(fetchOurclientsList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setOurClientsListSort(Array.from(ourorgs).sort((a, b) => {
        return (a.orgcount > b.orgcount) - 0.5;
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  const nologo = '../assets/img/clients_logo/nologo.jpg';

  return (
    <section className={`${st.sectionclients} container`}>
      <h3>наши клиенты</h3>

      <div className={st.wrapper_slider}>
        {
          status === 'success' ?
            <Swiper
              className='clients_swiper'
              modules={[Pagination, A11y, Scrollbar]}
              slidesPerView={8}
              spaceBetween={36}
              onSlideChange={() => { }}
              onSwiper={(swiper) => { }}
              // grabCursor={true}
              direction={'horizontal'}
              // loop='true'
              // navigation
              breakpoints={
                {
                  1500: {
                    slidesPerView: 8,
                    spaceBetween: 36
                  },
                  1300: {
                    slidesPerView: 7,
                    spaceBetween: 36
                  },
                  1140: {
                    slidesPerView: 6,
                    spaceBetween: 18
                  },
                  1000: {
                    slidesPerView: 5,
                    spaceBetween: 18
                  },
                  900: {
                    slidesPerView: 4,
                    spaceBetween: 18
                  },
                  520: {
                    slidesPerView: 3,
                    spaceBetween: 18
                  },
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 18
                  },


                }
              }
              // pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >

              {ourClientsListSort.map(item => (
                <SwiperSlide className={st.clients_item} key={uuidv4()}>
                  <div className={st.swiper_slide}>
                    <div className={st.imgwrap}>
                      <img className={st.swiper_slide_img} src={item.logo ? item.logo : nologo} alt={item.name} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper> : <div className={st.preloader}><p>загрузка клиентов...</p></div>
        }
      </div>
    </section>
  )
};
