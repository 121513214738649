import st from './OurClientsAdd.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useEffect, useRef, useState } from 'react';
import { fetchOurclientsList } from 'src/store/ourclients/getOurclientsList';
import { useDispatch } from 'react-redux';
import { fetchOurClientAddEdit } from '../../fetches/fetchAddEdit';

export const OurClientsAdd = ({ closeModal, ourorgs, status }) => {
  // для определения максимального slideOrder
  const [nextOrder, setNextOrder] = useState(0);

  useEffect(() => {
    // при перезагрузке или открытии прямой ссылки чтобы исключить ошибку undefined
    if (status === 'success' && ourorgs && ourorgs.length) {
      const maxOrder = Array.from(ourorgs).reduce((previous, current) => {
        if (+current.orgcount > +previous.orgcount) {
          return current;
        } else {
          return previous;
        }
      });
      setNextOrder(maxOrder.orgcount + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ourorgs]);

  // превью выбранного файла
  const [imagePreview, setImagePreview] = useState(null);
  // инпут ввода наименования клиента
  const [title, setTitle] = useState('');
  // инпут ввода ссылки на сайт клиента
  const [ourClientLink, setOurClientLink] = useState('');
  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelection = useRef(null);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  const dispatch = useDispatch();

  // данные для второго запроса
  const ourClientsDataBody = {
    'id': '',
    'orgcount': nextOrder,
    'name': title,
    'logo': '',
    'link': ourClientLink,
    'orgid': ''
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);

    await fetchOurClientAddEdit(formData, ourClientsDataBody);

    dispatch(fetchOurclientsList());

    closeModal();

    console.log('форма отправлена');
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreview(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью файла"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setImagePreview(null);
  };

  return (
    <div className={st.clientsadd_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.clientsadd}>
        <h3 className={st.title}>Добавление клиента</h3>

        <form
          onSubmit={handleFormSubmit}
          className={st.form}>

          {/* блок с превью выбранного для загрузки нового слайда */}
          <div className={imagePreview !== null ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Лого клиента</p>

            <div className={st.image_preview}>
              {imagePreview !== null && <img src={imagePreview} alt='' />}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора файла для загрузки c ПК */}
          <fieldset className={imagePreview === null ? st.fieldset_show : st.fieldset_hide}>
            <label className={st.label_image}>
              <input
                ref={fileSelection}
                className={st.input_image}
                type='file'
                name='image'
                accept='image/*,image/jpeg,image/png,image/svg'
                onChange={previewFile}
              />
              <p>Лого клиента...</p>
              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут ввода наименования клиента */}
          <div className={st.wrap_title}>
            <p className={title !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Наименование клиента</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='title'
                  value={title}
                  placeholder='Наименование клиента'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут ввода ссылки на сайт клиента */}
          <div className={st.wrap_title}>
            <p className={ourClientLink !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Ссылка на сайт</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='ourClientLink'
                  value={ourClientLink}
                  placeholder='Ссылка на сайт'
                  onChange={(e) => setOurClientLink(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={handleFormSubmit}
            disabled={imagePreview === null || title === '' ? true : false}
          >Добавить</button>
        </form>
      </div>
    </div>
  )
};
