import st from './RusProduct.module.css';

export const RusProduct = () => {

  return (
    <section className={`${st.section_rusprod} outer_container`}>
      <div className={st.container}>
        <div className={st.image}></div>

        <div className={st.text_block}>
          <h3 className={st.title}>
            Мы гордимся тем, что наш продукт является полностью российским и импортозамещающим.
          </h3>

          <p className={st.description}>
            Наша система универсальна и позволяет интегрировать в нашу бизнес-модель практически любые технические системы критически важной инфраструктуры, таких как транспорт, промышленность, строительство, энергетика, ЖКХ, бытовой сектор.
          </p>
        </div>
      </div>
    </section>
  )
};
