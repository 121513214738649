import st from './LkPayments.module.css';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

export const LkPayments = ({
  closeModal,
  schedule,
}) => {
  // это список всех платежных документов и актов включая главный счет
  const [listPaymentSchedule, setListPaymentSchedule] = useState([]);

  // формируем список "listPaymentSchedule" в зависимости от того пустой или нет "paydoc" в "schedule"
  useEffect(() => {
    const arr = [];

    schedule.forEach(item => {
      if (Object.keys(item.paydoc).length !== 0) {
        arr.push(
          {
            dateshedpay: item?.dateshedpay?.slice(0, -9).split('-').reverse().join('.'),
            sumshedpay: item?.sumshedpay,
            status: item?.status,
            sheddocimagelink: item?.sheddocimagelink
          },
          {
            dateshedpay: item?.paydoc.datedoc?.slice(0, -12).split('-').reverse().join('.'),
            sumshedpay: item?.paydoc.sumdoc,
            status: item?.paydoc.status,
            sheddocimagelink: item?.paydoc.imagelink
          },
          ...item.alldocs?.map(elem => (
            {
              dateshedpay: elem?.datedoc?.slice(0, -12).split('-').reverse().join('.'),
              sumshedpay: elem?.sumdoc,
              status: elem?.status,
              sheddocimagelink: elem?.imagelink
            }
          ))
        )
      } else {
        arr.push(
          {
            dateshedpay: item?.dateshedpay?.slice(0, -9).split('-').reverse().join('.'),
            sumshedpay: item?.sumshedpay,
            status: item?.status,
            sheddocimagelink: item?.sheddocimagelink
          },
          ...item.alldocs?.map(elem => (
            {
              dateshedpay: elem?.datedoc?.slice(0, -12).split('-').reverse().join('.'),
              sumshedpay: elem?.sumdoc,
              status: elem?.status,
              sheddocimagelink: elem?.imagelink
            }
          ))
        )
      }
    })

    setListPaymentSchedule(arr);
  }, []);


  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 3;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const equipmentCurrentList = listPaymentSchedule.slice(firstStatementIndex, lastStatementIndex);

  // общее количество заявок
  const totalStatementsQuantity = listPaymentSchedule.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = () => {
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = () => {
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация =====


  return (
    <div className={st.clientsedit_wrap}>
      <button
        className={st.btn_close}
        onClick={closeModal}>
      </button>

      <div className={st.clientsedit}>
        <div className={st.form}>
          <div className={st.ft_edit_requisites}>
            <h3 className={st.edit_title}>График оплат</h3>

            <div className={st.column_names}>
              <p>Дата</p>
              <p>Сумма &#8381;</p>
              <p>Статус</p>
              <p>Счет</p>
            </div>

            <ul className={st.payments}>
              {equipmentCurrentList.map(item =>
                <li className={st.payments_item} key={uuidv4()}>
                  <p>{item.dateshedpay}</p>
                  <p>{`${item.sumshedpay}`} &#8381;</p>
                  <p>{
                    item.status === 'PAYED' ? 'Оплачен' :
                      item.status === 'SHEDULED' ? 'Запланирован' :
                        item.status === 'OVERDUE' ? 'Просрочен' : 'Нет данных'
                  }</p>
                  {
                    item.sheddocimagelink ?
                      <Link to={item.sheddocimagelink} target='_blank'>
                        <div className={st.payments_item_pdf}></div>
                      </Link> :
                      <div className={st.payments_item_no_pdf}></div>
                  }
                </li>
              )}
            </ul>
          </div>
        </div>

        {/* пагинация */}
        <div className={st.pagination}>
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
            onClick={prevPage}>
          </button>
          {
            pages.map((item) => {
              return (
                <button
                  key={uuidv4()}
                  onClick={() => setCurrentPageNumber(item)}
                  className={
                    pages.length === 1 ? st.hidden :
                      item === currentPageNumber
                        ? st.pagination_item_active
                        : st.pagination_item
                  }>
                  {item}
                </button>
              );
            })
          }
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
            onClick={nextPage}>
          </button>
        </div>

      </div>
    </div>
  );
};
