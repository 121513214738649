import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  сurrentOrganization: [],
};

const currentOrganizationSlice = createSlice({
  name: 'currentOrganization',
  initialState,
  reducers: {
    setCurrentOrganization: (state, action) => {
      state.сurrentOrganization = action.payload;
    },
  },
});

export const { setCurrentOrganization } = currentOrganizationSlice.actions;

export default currentOrganizationSlice.reducer;
