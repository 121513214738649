import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NEWS_LIST } from "src/consts.js";

// получаем список новостей
export const fetchNewsList = createAsyncThunk(
  'newsList/fetchNewsList',

  async () => {
    const response = await fetch(NEWS_LIST, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
      }
    });

    const data = await response.json();
    // console.log('data: ', data);

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataNewsList: {},
  error: null,
};

const newsListSlice = createSlice({
  name: 'newsList',
  initialState,
  reducers: {
    removeNewsList: (state) => {
      state.dataNewsList = {};
    }
  },

  extraReducers: (builder) => {
    builder
      // при загрузке сайта
      .addCase(fetchNewsList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchNewsList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataNewsList = action.payload;
      })
      .addCase(fetchNewsList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataNewsList = action.error.message;
      })
  }
});


export const { removeNewsList } = newsListSlice.actions;

export default newsListSlice.reducer;
