import { Link } from 'react-router-dom';
import st from './NewsCardSecNews.module.css';

export const NewsCardSecNews = ({ newsid, newsname, newspreview }) => {

  return (
    <div className={st.news_item}>
      {/* <div className={st.news_test}> */}

      <div className={st.news_item_border}></div>

      <div className={st.news_item_wrap}>
        <Link className={st.news_item_link} to={`./news/newsread/${newsid}`}></Link>


        <div className={st.wrap_news_title}>
          <h5 className={st.news_title}>{newsname}</h5>
        </div>


        <div className={st.wrap_text_btn}>
          <div className={st.wrap_news_text}>
            <p className={st.news_text}>{newspreview}</p>
          </div>

          <button className={st.news_item_btn}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
              <path d="M0 9.01238H18.6081M18.6081 9.01238L12.8633 1M18.6081 9.01238L12.8633 17" stroke="currentColor" strokeWidth="2" />
            </svg>
          </button>
        </div>
      </div>
    </div >
  )
};
