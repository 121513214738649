import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FIN_DOC_ALL_AND_PAY } from 'src/consts.js';
import { updateTokens } from 'src/utils/updateTokens';

// получаем список платежных документов (акты, накладные, счета)
export const fetchDocsAllPayList = createAsyncThunk(
  'docAllList/fetchDocsAllPayList',

  async (uuid) => {
    try {
      const response = await fetch(`${FIN_DOC_ALL_AND_PAY}${uuid}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF8',
          'User-Agent': 'LiftupSite/1.0.0',
          // заголовок с токеном для проверки
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        }
      });

      const data = await response.json();

      if (response.ok) {
        console.log('ответ без перезаписи токена: ', data);
        return data
      } else if (response.status === 401 || data.loginstatus === 7) {

        // в случае просроченного токена обновляем пару "token - rtoken"
        await updateTokens();

        // и снова отправляем запрос на получение платежных документов
        const response = await fetch(FIN_DOC_ALL_AND_PAY, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF8',
            'User-Agent': 'LiftupSite/1.0.0',
            // заголовок с токеном для проверки
            'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
          }
        });

        const data = await response.json();

        console.log('ответ с перезаписью токена: ', data);

        return data;
      } else {
        console.log('произошла непредвиденная ошибка');
        return ['unexpected', 'error'];
      }
    } catch (error) {
      console.log('сработала конструкция try-cetch в запросе "fetchDocsAllPayList": ', error);
    }
  }
);

const initialState = {
  status: 'idle',
  dataDocsPayAllList: {},
  error: null,
};

const docsFinListSlice = createSlice({
  name: 'docsPayList',
  initialState,
  // reducers: {
  //   removeDocsPayList: (state) => {
  //     state.dataDocsPayAllList = {};
  //   }
  // },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDocsAllPayList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDocsAllPayList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataDocsPayAllList = action.payload;
      })
      .addCase(fetchDocsAllPayList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataDocsPayAllList = action.error.message;
      })
  }
});


// export const { removeDocsPayList } = docsFinListSlice.actions;

export default docsFinListSlice.reducer;
