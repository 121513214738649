import { Link } from 'react-router-dom';
import st from './Clients.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchOurclientsList } from 'src/store/ourclients/getOurclientsList';
import { Helmet } from 'react-helmet-async';

export const Clients = () => {
  // сортированный массив документов
  const [ourClientsListSort, setOurClientsListSort] = useState([]);

  const { ourorgs } = useSelector(state => state.ourclientsListReducer.dataOurclientsList);
  const { status } = useSelector(state => state.ourclientsListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!ourorgs && status !== 'failed') {
      dispatch(fetchOurclientsList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "slideOrder" (по порядковому номеру слайда)
      setOurClientsListSort(Array.from(ourorgs).sort((a, b) => {
        return (a.orgcount > b.orgcount) - 0.5;
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  const nologo = '../assets/img/clients_logo/nologo.jpg';

  return (
    // { status === 'success' ?
    <>
      <Helmet>
        <title>Наши клиенты | LIFTUP</title>
        <meta
          name="description"
          content="Мы гордимся тем, что сотрудничаем с крупными клиентами, которые уже давно стали нашими постоянными партнерами. Благодарим их за доверие, которое они нам оказывают, и стремимся к тому, чтобы наше сотрудничество было долгосрочным и взаимовыгодным."
        />
      </Helmet>

      <main className={`${st.main} container`}>
        <h1 className={st.title}>Наши клиенты</h1>

        <div className={st.flashlight2}></div>

        {status === 'success' ?
          <ul className={st.clients_list}>
            {ourClientsListSort.map(item => (
              <li className={st.clients_item} key={uuidv4()}>
                <img className={st.clients_img} src={item.logo ? item.logo : nologo} alt={item.name} />
                {
                  item.link ?
                    <Link
                      className={st.clients_link}
                      to={item.link}
                      target='_blank'
                    >
                      {item.link ? item.link : 'ссылка на сайт'}
                    </Link> :
                    <p className={st.clients_link}>ссылка на сайт</p>
                }
                <h4 className={st.clients_item_h4}>{item.name ? item.name : 'Название организации'}</h4>
              </li>
            ))}
          </ul> : <div className={st.preloader}><p>загрузка клиентов...</p></div>
        }
      </main>
    </>
  )
};
