import st from './PassRecPhone.module.css';
import store from 'src/store/store.js';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import phoneNumberMask from 'src/utils/phoneNumberMask.js';
import { useDispatch } from 'react-redux';
import { fetchPhoneCheck } from 'src/store/authorization/passRecovery.js';

export const PassRecPhone = () => {
  // не удалять!
  // const [countryСode, setСountryСode] = useState('');
  const [phoneNumRec, setPhoneNumRec] = useState('');
  const [showNotePhone, setShowNotePhone] = useState(false);
  const [loginstatus, setLoginstatus] = useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // меняет значение в инпуте на отформатированное
  const phoneNumberFormatter = (e) => {
    const formattedTargetValue = phoneNumberMask(e.target.value);

    e.target.value = formattedTargetValue;

    setPhoneNumRec(e.target.value);
  }

  // формируем объект тела запроса из инпута
  const numPhoneForRes = {
    'phone': 7 + phoneNumRec.replace(/\D/g, '')
  };


  // авторизация по кнопке "войти"
  const checkPhone = (e) => {
    e.preventDefault();

    dispatch(fetchPhoneCheck(numPhoneForRes));

    // блокируем кнопку "Войти"
    // setShowNoteMail('');
    // setShowNotePass('');

    // очищаем поля ввода
    // setAuthorMail('');
    // setAutorPassword('');
  };

  // отключает включает кнопку
  useEffect(() => {
    if (phoneNumRec.length === 13) {
      setShowNotePhone(true);
    } else {
      setShowNotePhone(false);
    }
  }, [phoneNumRec.length])

  useEffect(() => {
    // подписка на изменение значения "loginstatus"
    store.subscribe(() => setLoginstatus(store.getState().authReducer?.dataAuth?.loginstatus));
    if (loginstatus === undefined) setLoginstatus(5);
  }, [loginstatus]);

  return (
    <>
      <button className={st.btn_back} onClick={() => navigate(-1)}></button>

      <h2 className={st.title}>Восстановление пароля</h2>

      <p className={st.description}>На ваш номер телефона будет отправлено СМС с кодом подтверждения</p>

      <Link className={st.close_btn} to='/'></Link>

      <form className={st.pass_rec_form}>
        <div className={st.note_wrap}>

          <p className={loginstatus !== 5 ? st.note_phone : st.note_hide}>Пользователь с указанным номером не найден</p>

          <label className={st.label}>
            <p className={st.select}>+7</p>
            {/* <select
              className={st.select}
              name='countryСode'
              value={countryСode}
              onChange={(e) => setСountryСode(e.target.value)}>
              <option value=''>+7</option>
              <option value='+86'>+86</option>
            </select> */}

            <input
              className={st.input}
              type='text'
              name='phoneNumRec'
              value={phoneNumRec}
              onChange={(e) => phoneNumberFormatter(e)}
              // onMouseDown={() => {
              //   setLoginstatus(5);
              // }}
              placeholder='Номер мобильного телефона'
              maxLength='13'
            />
          </label>
        </div>

        <button
          className={st.button_submit}
          onClick={checkPhone}
          // onClick={() => navigate('/signin/passreccode')}
          disabled={showNotePhone ? false : true}
        >Получить код в смс</button>

        <button
          className={st.button_submit}
          onClick={() => navigate('/signin/passreccode')}
        >Шаг вперед</button>

      </form>

    </>
  )
};
