import st from './AccountPersonal.module.css';
import { useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as RedCircle } from '../../img/red_circle.svg';
import { ReactComponent as ArrowDown2 } from '../../img/arrow_down2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { removeAuth } from 'src/store/authorization/auth';
import { removeLiftDownList } from 'src/store/liftdown/getLiftDownList';
import { removeEquipmentList } from 'src/store/equipment/getEquipmentList';

export const AccountPersonal = () => {
  const [showMobMenu, setShowMobMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('О компании');

  const { id } = useSelector(state => state.authReducer?.dataAuth?.organization);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // меняем значение активного пункта меню и закрываем меню
  const closeMenu = (e) => {
    setActiveMenu(e.target.textContent);
    setShowMobMenu(false);

    console.log(window.location);
  };

  // присваиваем класс пункту меню NavLink
  const setMenuActive = ({ isActive, }) => isActive
    ? `${st.dept_link_active}`
    : `${st.dept_link}`;

  // выход из авторизации
  const outAuth = (e) => {
    e.preventDefault();

    localStorage.removeItem('tokenlu');
    localStorage.removeItem('otk');
    document.cookie = 'rtokenlu=;  path=/; max-age=0';

    dispatch(removeAuth());
    dispatch(removeLiftDownList());
    dispatch(removeEquipmentList());

    navigate('/');
  };

  return (
    <main className={`${st.adminpanel} container`}>
      <h2>Личный кабинет</h2>

      <div className={st.wrapper}>
        <div className={st.wrapper_mob}>

          <button className={st.menu_btn_mob} onClick={() => setShowMobMenu(!showMobMenu)}>{activeMenu}
            <ArrowDown2 className={!showMobMenu ? st.arrow_down2 : st.arrow_down2_180} />
          </button>

          <ul className={showMobMenu ? st.depts_list : st.depts_list_hide}>
            <li className={st.dept_item}>
              <NavLink
                className={setMenuActive} to={`/lk/${id}/aboutcompany`}
                onClick={closeMenu}
              >
                <RedCircle className={st.red_circle}></RedCircle>
                <p>О компании</p>
              </NavLink>
            </li>

            <li className={st.dept_item}>
              <NavLink
                className={setMenuActive} to={`/lk/${id}/equip`}
                onClick={closeMenu}
              >
                <RedCircle className={st.red_circle}></RedCircle>
                <p>Оборудование</p>
              </NavLink>
            </li>

            <li className={st.dept_item}>
              <NavLink
                className={setMenuActive} to={`/lk/${id}/docs`}
                onClick={closeMenu}
              >
                <RedCircle className={st.red_circle}></RedCircle>
                <p>Документы</p>
              </NavLink>
            </li>

            <li className={st.dept_item}>
              <NavLink
                className={setMenuActive} to={`/lk/${id}/liftdown`}
                onClick={closeMenu}
              >
                <RedCircle className={st.red_circle}></RedCircle>
                <p>LiftDown</p>
              </NavLink>
            </li>

            {/* <li className={st.dept_item}>
              <NavLink
                className={setMenuActive} to={`http://localhost:3009/`} target='_blank'
                onClick={closeMenu}
              >
                <RedCircle className={st.red_circle}></RedCircle>
                <p>SHOP</p>
              </NavLink>
            </li> */}

            <li className={st.dept_item}>
              <button className={st.dept_link} onClick={outAuth}>
                <RedCircle className={st.red_circle_exit}></RedCircle>
                <p>Выход</p>

                <svg width="22" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14 4.25V2.375A1.875 1.875 0 0012.125.5h-9A1.875 1.875 0 001.25 2.375v11.25A1.875 1.875 0 003.125 15.5h9A1.875 1.875 0 0014 13.625V11.75m3-7.5L20.75 8m0 0L17 11.75M20.75 8H7.953"
                    stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </li>
          </ul>
        </div>

        <Outlet />
      </div>
    </main>
  )
};
