import st from './RegPhone.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhoneConfirm } from 'src/store/registration/reg';

export const RegPhone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // номер телефона для отправки проверочного кода
  const phoneNum = useSelector(state => state.regReducer.dataReg.userPhone);

  // правильный формат для отправки в теле запроса
  const phoneNumObj = {
    'phone': phoneNum
  };

  // отправляем номер телефона для получения кода"
  const handlerConfirmPhone = (e) => {
    e.preventDefault();

    dispatch(fetchPhoneConfirm(phoneNumObj));

    navigate('/signin/regcode');
  };

  return (
    <>
      <button className={st.btn_back} onClick={() => navigate(-1)}></button>

      <h2 className={st.title}>Подтвердите номер телефона</h2>

      <p className={st.description}>На ваш номер телефона будет отправлено СМС с кодом подтверждения</p>

      <Link className={st.close_btn} to='/'></Link>

      <form className={st.pass_rec_form}>
        <div className={st.note_wrap}>
          {phoneNum !== undefined && phoneNum?.length === 11
            ? <p className={st.phone_num}>
              {`+${phoneNum?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}`}</p>
            : <p className={st.phone_note}>Номер телефона не определен</p>}
        </div>

        <button
          className={st.button_submit}
          onClick={handlerConfirmPhone}
          disabled={phoneNum !== undefined && phoneNum?.length === 11 ? false : true}
        >Получить код в смс</button>

        <button
          className={st.button_submit}
          onClick={() => navigate('/signin/regcode')}
        >Шаг вперед</button>
      </form>
    </>
  )
};
