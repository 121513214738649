import { Link, useNavigate } from 'react-router-dom';
import st from './PassChangeSuccess.module.css';
import { ReactComponent as ImgChangeSuccess } from '../../../../img/pass_change_success.svg';

export const PassChangeSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <h2 className={st.title}>Пароль успешно изменен</h2>

      <Link className={st.close_btn} to='/'></Link>

      <div className={st.autor_form}>

        <ImgChangeSuccess className={st.img_change_success} />

        <button
          className={st.button_submit}
          onClick={() => navigate('/signin/author')}
        >Перейти к авторизации</button>
      </div>
    </>
  )
};
