import { Link } from "react-router-dom";
import st from './DocCard.module.css';
import { useEffect, useRef, useState } from 'react';

export const DocCard = ({
  invoice,
  invoicesum,
  status,
  paymentsum,
  actssum,
  docLink,
  actLink
}) => {
  // ссылка на элемент с документом со статусом "оплачен"
  const refInvoiceOrAct = useRef(null);

  // меню "скачать док" "скачать акт"
  const [menuDocsOrAct, setMenuDocsOrAct] = useState(false);

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      try {
        if (!refInvoiceOrAct.current.contains(e.target)) {
          setMenuDocsOrAct(false);
        }
      } catch (error) {
        console.log('error: ', error.message);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });


  return (
    <>
      <li className={st.docs_item}>

        <div className={st.item_wrap}>
          <p className={st.item_title}>Счет</p>
          <p className={st.item_date}>{invoice}</p>
        </div>

        <div className={st.item_wrap}>
          <p className={st.item_title}>Сумма</p>
          <p className={st.item_date}>{invoicesum}</p>
        </div>

        <div className={st.item_wrap}>
          <p className={st.item_title}>Статус</p>
          <p className={st.item_date}>{
            status && status === 'PAYED' ?
              'Оплачен' : status && status === 'NOTPAYED' ?
                'Неоплачен' : 'нет данных'
          }</p>
        </div>

        <div className={st.item_wrap}>
          <p className={st.item_title}>Сумма&nbsp;оплаты</p>
          <p className={st.item_date}>{paymentsum}</p>
        </div>

        {
          status?.includes('Неоплачен') ?

            <button className={st.item_button}>Скачать Счет</button> :

            <div className={st.item_wrap_desc}>
              <div className={st.item_wrap}>
                <p className={st.item_title}>Сумма&nbsp;актов</p>
                <p className={st.item_date}>{actssum}</p>
              </div>

              <div className={st.item_btn_container} ref={refInvoiceOrAct}>
                <div className={st.item_btn} onClick={() => setMenuDocsOrAct(true)}></div>

                <ul className={!menuDocsOrAct ? st.btn_menu : st.btn_menu_show}>
                  <li
                    className={st.btn_menu_item}
                    onClick={() => setMenuDocsOrAct(false)}
                  >
                    {
                      docLink !== '' ?
                        <Link to={docLink} target='_blank' download>Скачать счет</Link> : null
                    }
                  </li>

                  <li
                    className={st.btn_menu_item}
                    onClick={() => setMenuDocsOrAct(false)}
                  >
                    {
                      actLink !== '' ?
                        <Link to={actLink} target='_blank' download>Скачать акт</Link> : null
                    }
                  </li>
                </ul>

                <ul className={st.btn_menu_mob}>
                  <li className={st.btn_menu_item}>Скачать счет</li>
                  <li className={st.btn_menu_item}>Скачать акт</li>
                </ul>
              </div>
            </div>

        }
      </li>
    </>
  )
};
