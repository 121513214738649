import st from './LkEquipment.module.css';
import { useEffect, useState } from 'react';
import { ReactComponent as Plus } from '../../../../img/plus.svg';
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from 'src/components/Overlay/Overlay';
// import { equipment_list } from './data/equipment_list_json.js'
import { EquipmentCard } from './components/EquipmentCard/EquipmentCard.jsx';
import { EquipmentAdd } from './components/EquipmentAdd/EquipmentAdd.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEquipmentList } from 'src/store/equipment/getEquipmentList';

export const LkEquipment = () => {
  // фейковые данные из статичного файла
  // const equipmentList = equipment_list[0].clbaseEquipmentList;

  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);

  const dispatch = useDispatch();

  // показывает, скрывает окно "фильтры" в мобильной версии
  const [winFilterMob, setWinFilterMob] = useState(false);
  // управление инпутами фильтрации
  const [filID, setfilID] = useState('');
  const [filStatus, setFilStatus] = useState('');
  const [filNumber, setFilNumber] = useState('');
  const [filEquip, setFilEquip] = useState('');
  const [filManufacture, setFilManufacture] = useState('');
  const [filBrand, setFilBrand] = useState('');
  const [filProductDate, setFilProductDate] = useState('');
  const [filLaunchDate, setFilLaunchDate] = useState('');

  // uuid организации для передачи в запросе получения списка оборудования
  const { uuid } = useSelector(state => state.authReducer.dataAuth.organization);

  // статус  выполнения запроса на получение списка оборудования
  const { status } = useSelector(state => state.equipmentListReducer);

  // список оборудования в store
  const { equipmentOfOrganizatioList } = useSelector(state => state.equipmentListReducer.dataEquipmentList);

  // состояние списка оборудования после фильтрации по номеру efointid
  const [equipmentOfOrganizatioListToFilter, setEquipmentOfOrganizatioListToFilter] = useState([]);
  // console.log('отфильтрованный: ', equipmentOfOrganizatioListToFilter);

  // фиксированный массив для перебора при поиске
  const [constEquipmentList, setConstEquipmentList] = useState([]);
  // console.log('фиксированный: ', constEquipmentList);

  // изменяемый массив для вывода результатов поиска
  const [letEquipmentList, setLetEquipmentList] = useState(constEquipmentList);
  // console.log('изменяемый: ', letEquipmentList);

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 6;

  // индекс последней заявки
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первой заявки
  const firstStatementIndex = lastStatementIndex - statementsQuantity;

  // список элементов текущей страницы
  const equipmentCurrentList = letEquipmentList.slice(firstStatementIndex, lastStatementIndex);
  // console.log('текущий на странице: ', equipmentCurrentList);

  // общее количество заявок
  const totalStatementsQuantity = letEquipmentList.length;

  // список номеров-кнопок страниц
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
    pages.push(i);
  }

  // функция кнопки следующая страница
  const nextPage = () => {
    setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
  };

  // функция кнопки предыдущая страница
  const prevPage = () => {
    setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
  };
  // ===== пагинация =====


  // если в сторе нет массива, то получаем его и диспатчим в стор
  useEffect(() => {
    // если "equipmentOfOrganizatioList" нет в store, то диспатчим его в store 
    if (!equipmentOfOrganizatioList) {
      // сurrentOrganizationUuid это uuid выбранной организации для построения списка ее оборудования
      dispatch(fetchEquipmentList(uuid));
    }

    // сортировка массива оборудования по "efointid" (возможно не понадобится)
    if (equipmentOfOrganizatioList && status === 'success') {
      // сортируем  массив по ключу "id" (по порядковому номеру поля)
      setEquipmentOfOrganizatioListToFilter(Array.from(equipmentOfOrganizatioList).sort((a, b) => {
        return (+a.efointid > +b.efointid) - 0.5;
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, equipmentOfOrganizatioList]);

  // получаем фиксированный и изменяемый массивы для фильтрации и очистки
  useEffect(() => {
    setConstEquipmentList(equipmentOfOrganizatioListToFilter);
    setLetEquipmentList(equipmentOfOrganizatioListToFilter);
  }, [equipmentOfOrganizatioListToFilter]);


  // функция очистки фильтров и возврат к исходному списку
  const clearFilter = () => {
    setfilID('');
    setFilStatus('');
    setFilNumber('');
    setFilEquip('');
    setFilManufacture('');
    setFilBrand('');
    setFilProductDate('');
    setFilLaunchDate('');

    setLetEquipmentList(constEquipmentList);
  };

  // функция поиска по id
  const searchId = (e) => {
    setfilID(e.target.value);
    let newListEquipment = [];

    setFilStatus('');
    setFilNumber('');
    setFilEquip('');
    setFilManufacture('');
    setFilBrand('');
    setFilProductDate('');
    setFilLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.efouid.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по статусу
  const searchStatus = (e) => {
    setFilStatus(e.target.value);
    let newListEquipment = [];

    setfilID('');
    setFilNumber('');
    setFilEquip('');
    setFilManufacture('');
    setFilBrand('');
    setFilProductDate('');
    setFilLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.status.includes(e.target.value)) {
        newListEquipment.push(item);
      }

      if (e.target.value === 'Статус') {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по номеру
  const searchNumber = (e) => {
    setFilNumber(e.target.value);
    let newListEquipment = [];

    setfilID('');
    setFilStatus('');
    setFilEquip('');
    setFilManufacture('');
    setFilBrand('');
    setFilProductDate('');
    setFilLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.factorynumber.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по оборудованию
  const searchEquip = (e) => {
    setFilEquip(e.target.value);
    let newListEquipment = [];

    setfilID('');
    setFilStatus('');
    setFilNumber('');
    setFilManufacture('');
    setFilBrand('');
    setFilProductDate('');
    setFilLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.typeOfEquipment.includes(e.target.value)) {
        newListEquipment.push(item);
      }

      if (e.target.value === 'Все') {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по производителю
  const searchManufacture = (e) => {
    setFilManufacture(e.target.value);
    let newListEquipment = [];

    setfilID('');
    setFilStatus('');
    setFilNumber('');
    setFilEquip('');
    setFilBrand('');
    setFilProductDate('');
    setFilLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.manufacturer.manufacturername.includes(e.target.value)) {
        newListEquipment.push(item);
      }

      if (e.target.value === 'Все') {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по марке
  const searchBrand = (e) => {
    setFilBrand(e.target.value);
    let newListEquipment = [];

    setfilID('');
    setFilStatus('');
    setFilNumber('');
    setFilEquip('');
    setFilManufacture('');
    setFilProductDate('');
    setFilLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.brand.brandname.includes(e.target.value)) {
        newListEquipment.push(item);
      }

      if (e.target.value === 'Все') {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по дате производства
  const searchProductDate = (e) => {
    setFilProductDate(e.target.value);
    let newListEquipment = [];

    setfilID('');
    setFilStatus('');
    setFilNumber('');
    setFilEquip('');
    setFilManufacture('');
    setFilBrand('');
    setFilLaunchDate('');

    constEquipmentList.forEach(item => {
      if (item.dateofacceptanceforwarranty.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  // функция поиска по дате запуска
  const searchLaunchDate = (e) => {
    setFilLaunchDate(e.target.value);
    let newListEquipment = [];

    setfilID('');
    setFilStatus('');
    setFilNumber('');
    setFilEquip('');
    setFilManufacture('');
    setFilProductDate('');
    setFilBrand('');

    constEquipmentList.forEach(item => {
      if (item.dateofcommissioning.includes(e.target.value)) {
        newListEquipment.push(item);
      }
    });

    setLetEquipmentList(newListEquipment);

    setCurrentPageNumber(1);
  };

  return (
    <>
      <div className={st.lk_equipment}>
        <div className={st.wrap_1}>
          <h3 className={st.title}>Оборудование</h3>

          <button
            className={st.btn_add}
            onClick={() => setModalAdd(!modalAdd)}
          ><Plus />Добавить&nbsp;оборудование</button>

          <button
            className={!winFilterMob ? st.btn_filters : st.btn_filters_active}
            onClick={() => setWinFilterMob(!winFilterMob)}
          >Фильтры
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 6.75h21M5.25 12h13.5m-9 5.25h4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>

        {/* список фильтров */}
        <ul className={!winFilterMob ? st.filter_list : st.filter_list_show}>
          <li className={st.filter_item}>
            <p>Фильтры</p>
          </li>

          {/* по id */}
          <li className={`${st.filter_item} ${st.inp}`}>
            <input
              className={st.filter_input}
              type='text'
              name='filID'
              value={filID}
              maxLength='60'
              placeholder='ID'
              onChange={searchId} />

            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span>
          </li>

          {/* по статусу */}
          <li className={`${st.filter_item} ${st.sel}`}>
            <select
              className={st.filter_select}
              name='filStatus'
              value={filStatus}
              onChange={searchStatus}
            >
              <option value=''>Статус</option>
              <option value='Entered'>Заведен</option>
              <option value='UnderWarranty'>На гарантии</option>
            </select>
            <div className={st.select_focus}></div>
          </li>

          {/* по номеру */}
          <li className={`${st.filter_item} ${st.inp}`}>
            <input
              className={st.filter_input}
              type='text'
              name='filNumber'
              value={filNumber}
              maxLength='60'
              placeholder='Номер'
              onChange={searchNumber} />

            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span>
          </li>

          {/* по оборудованию */}
          <li className={`${st.filter_item} ${st.sel}`}>
            <select
              className={st.filter_select}
              name='filEquip'
              value={filEquip}
              onChange={searchEquip}
            >
              <option value=''>Оборудование</option>
              <option value='Elevator'>Лифт</option>
              <option value='Travolator'>Травалатор</option>
              <option value='Escalator'>Эскалатор</option>
            </select>
            <div className={st.select_focus}></div>
          </li>

          {/* по производителю */}
          <li className={`${st.filter_item} ${st.sel}`}>
            <select
              className={st.filter_select}
              name='filManufacture'
              value={filManufacture}
              onChange={searchManufacture}
            >
              <option value=''>Производитель</option>
              <option value='BLT'>BLT</option>
              <option value='KONE'>KONE</option>
              <option value='OTIS'>OTIS</option>
            </select>
            <div className={st.select_focus}></div>
          </li>

          {/* по марке */}
          <li className={`${st.filter_item} ${st.inp}`}>
            <input
              className={st.filter_input}
              type='text'
              name='filBrand'
              value={filBrand}
              maxLength='60'
              placeholder='Марка'
              onChange={searchBrand} />

            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span>
          </li>

          {/* по дате производства */}
          <li className={`${st.filter_item} ${st.inp}`}>
            <input
              className={st.filter_input}
              type='date'
              name='filProductDate'
              value={filProductDate}
              maxLength='60'
              placeholder='Дата производства'
              onChange={searchProductDate} />

            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span>
          </li>

          {/* по дате запуска */}
          <li className={`${st.filter_item} ${st.inp}`}>
            <input
              className={st.filter_input}
              type='date'
              name='filLaunchDate'
              value={filLaunchDate}
              maxLength='60'
              placeholder='Дата запуска'
              onChange={searchLaunchDate} />

            <span
              className={st.clear_input}
              onMouseDown={clearFilter}
            >
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7M7 1L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </span>
          </li>

          {/* просто пустой */}
          <li className={st.filter_item_empty}></li>
        </ul>

        {/* список карточек оборудования */}
        <ul className={st.lk_equip_body}>
          {
            equipmentCurrentList.length !== 0 ?
              equipmentCurrentList.map(item =>
                <EquipmentCard
                  key={uuidv4()}

                  efouid={item.efouid}
                  uuid={item.uuid}
                  equid={item.equid}
                  efointid={item.efointid}
                  status={item.status}

                  factorynumber={item.factorynumber ? item.factorynumber : ''}
                  typeOfEquipment={item.typeOfEquipment}
                  manufacturername={item.manufacturer ? item.manufacturer.manufacturername : ''}

                  brandname={item.brand ? item.brand.brandname : ''}

                  purpose={item.purpose}
                  speed={item.speed}
                  loadcapacity={item.loadcapacity}

                  dateofacceptanceforwarranty={
                    item.dateofacceptanceforwarranty ? item.dateofacceptanceforwarranty.slice(0, -9) : ''
                  }
                  dateofcommissioning={item.dateofcommissioning ? item.dateofcommissioning.slice(0, -9) : ''}
                  passportlink={item.passportlink || ''}

                  installationaddress={item.installationaddress ? item.installationaddress : {}}

                  partsList={item.partsList || {}}

                  schedule={item.schedule || []}
                />
              ) : <p className={st.notification}>Оборудование не найдено!</p>
          }
        </ul>

        {/* пагинация */}
        <div className={st.pagination}>
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
            onClick={() => {
              prevPage();
              window.scrollTo(0, 0);
            }}
          >
          </button>
          {
            pages.map((item) => {
              return (
                <button
                  key={uuidv4()}
                  onClick={() => {
                    setCurrentPageNumber(item);
                    window.scrollTo(0, 0);
                  }}
                  className={
                    pages.length === 1 ? st.hidden :
                      item === currentPageNumber
                        ? st.pagination_item_active
                        : st.pagination_item
                  }>
                  {item}
                </button>
              );
            })
          }
          <button
            className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
            onClick={() => {
              nextPage();
              window.scrollTo(0, 0);
            }}
          >
          </button>
        </div>
      </div>

      {/* оверлей с окном добавления оборудования */}
      <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
        <EquipmentAdd closeModal={() => setModalAdd(false)} />
      </Overlay>
    </>
  );
};
