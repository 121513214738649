import { GET_COORD } from "src/consts";

// получаем координаты по введенному адресу (яндекс)
export const fetchServCentCoordinates = async (argCity, argAddress) => {
  const res = await fetch(`${GET_COORD}&geocode=${argCity} ${argAddress}`, {
    method: 'GET',
    headers: {
      'User-Agent': 'LiftupSite/1.0.0'
    },
  });

  const data = await res.json();

  // массив с координатами строчного типа
  const pointsStrings = data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ');
  console.log('pointsStrings: ', pointsStrings);

  return [
    {
      'latitude': pointsStrings[0],
      'longitude': pointsStrings[1]
    }
  ];

  // массив с координатами номерного типа
  // return [+pointsStrings[1], +pointsStrings[0]];
};
