import { LOGIN_GET } from 'src/consts.js';
import getCookie from './getCookie.js';

// обновляем и записываем пару "token - rtoken"
export const updateTokens = async () => {
  // получаем rtokenlu из кук
  const cookie = getCookie();
  const rtoken = cookie.rtokenlu;

  // отправляем GET запрос с rtokenlu на получение юсера
  const response = await fetch(LOGIN_GET, {
    headers: {
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${rtoken}`,
    },
  });

  const data = await response.json();

  // если rtokenlu просрочен или не валиден, очищаем localStorage и cookie, чтобы избежать повторных запросов при перезагрузках
  if (response.status === 401 || data.loginstatus === 7) {
    localStorage.removeItem('tokenlu');

    localStorage.removeItem('otk');

    document.cookie = `rtokenlu=; expires=${new Date(0)}; path=/;`;

    window.location.href = '/signin/author';
  }

  // помещаем токен в локалсторадж
  if (data.token.token !== null) {
    localStorage.setItem('tokenlu', data.token.token);
  }
  // помещаем рефрештокен в куки на срок один месяц
  if (data.token.rtoken !== null) {
    document.cookie = `rtokenlu=${data.token.rtoken}; path=/; max-age=2592000`;
  }

  console.log('отработал rtokenlu, перезаписаны значения токенов в хранилищах');

  return data;
};
