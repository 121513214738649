import st from './ServiceCenterCard.module.css';
import { ReactComponent as IconAddress } from '../../../../img/icons_contacts/address.svg';
import { ReactComponent as IconClock } from '../../../../img/icons_contacts/clock.svg';
import { ReactComponent as IconPhone } from '../../../../img/icons_contacts/phone.svg';
import { ReactComponent as IconMail } from '../../../../img/icons_contacts/mail.svg';
// import ServiceCenterMap from '../../../../img/servicecenter_map1.png';
import { YandexMaps } from '../YandexMaps/YandexMaps';

export const ServiceCenterCard = ({

  name,
  logo,
  city,
  address,
  jobschedule,
  phone,
  email,
  coordinates

}) => {

  return (

    <li className={st.servicecenter_card}>
      <div className={st.servicecenter_content}>
        {
          logo && !logo.includes('unknown') ?
            <img className={st.servicecenter_logo} src={logo} alt={name} /> :
            <div className={st.servicecenter_no_logo}></div>
        }

        <div className={st.wrap_title}>
          <h3 className={st.title}>{name}</h3>
        </div>

        <ul className={st.servicecenter_list}>
          <li className={st.servicecenter_item}>
            <IconAddress className={st.icon} />
            <p>{address}</p>
          </li>

          <li className={st.servicecenter_item}>
            <IconClock className={st.icon} />
            <p>{jobschedule}</p>
          </li>

          <li className={st.servicecenter_item}>
            <IconPhone className={st.icon} />
            <p>{phone}</p>
          </li>

          <li className={st.servicecenter_item}>
            <IconMail className={st.icon} />
            <p>{email}</p>
          </li>
        </ul>
      </div>

      <div className={st.servicecenter_map}>
        {/* <img src={ServiceCenterMap} alt='карта' /> */}
        <YandexMaps
          name={name}
          address={address}
          city={city}
          coordinates={coordinates}
        />
      </div>
    </li>

  )
};
