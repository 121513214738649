import st from './OurClientsPreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { Overlay } from 'src/components/Overlay/Overlay';
import { OurClientsEdit } from '../OurClientsEdit/OurClientsEdit.jsx';
import { useDispatch } from 'react-redux';
import { fetchOurClientDel } from '../../fetches/fetchDel';
import { fetchOurclientsList } from 'src/store/ourclients/getOurclientsList';

export const OurClientsPreview = ({
  id,
  logo,
  link,
  name,
  orgcount,
}) => {
  // модальное окно закр откр
  const [modalEdit, setModalEdit] = useState(false);
  // поля для передачи пропсов в окно редактирования нашего клиента
  const [targetID, setTargetID] = useState('');
  const [targetLogo, setTargetlogo] = useState('');
  const [targetName, setTargetName] = useState('');
  const [targetLink, setTargetLink] = useState('');
  const [targetOrgcount, setTargetOrgcount] = useState('');

  // ссылка на компонент с кнопками "редактировать - удалить"
  const refEditOrDel = useRef(null);

  // откр закр меню "редактировать - удалить"
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);

  const dispatch = useDispatch();

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  // удаляем нашего клиента, закрываем меню, обновляем список документов
  const ourClientDel = async () => {

    setModalEdit(false);

    await fetchOurClientDel(id);

    dispatch(fetchOurclientsList());
  };

  // показывает меню "редактировать удалить"
  const editOrDel = (e) => {
    e.preventDefault();

    setMenuEditOrDel(true);
  };

  // открывает окно "редактировать клиента" выбранного элемента
  const editTargetElem = () => {
    setTargetID(id)

    setTargetlogo(logo);

    setTargetName(name);

    setTargetLink(link);

    setTargetOrgcount(orgcount);

    setModalEdit(!modalEdit);
  };

  const nologo = '../assets/img/clients_logo/nologo.jpg';

  return (
    <>
      <li className={st.news_item} id={id}>
        <p className={st.item_seq}>{orgcount}</p>

        <img src={logo ? logo : nologo} alt={name} className={st.item_img} />

        <h4 className={st.news_title}>{name}</h4>

        <div className={st.item_edit_del} ref={refEditOrDel}>
          <div className={st.item_btn} onClick={editOrDel}></div>

          <ul
            className={!menuEditOrDel ? st.btn_menu : st.btn_menu_show}
            onClick={(e) => { e.preventDefault() }}>
            <li className={st.btn_menu_item} onClick={editTargetElem}>Редактировать</li>
            <li className={st.btn_menu_item} onClick={ourClientDel}>Удалить</li>
          </ul>
        </div>
      </li>

      <Overlay className={!modalEdit ? st.overlay_hide : st.overlay_show}>
        <OurClientsEdit
          closeModal={() => setModalEdit(false)}
          targetID={targetID}
          targetLogo={targetLogo}
          targetName={targetName}
          targetLink={targetLink}
          targetOrgcount={targetOrgcount}
        />
      </Overlay>
    </>
  )
};
