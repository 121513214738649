import st from './LiftDownCard.module.css';

export const LiftDownCard = ({

  uid,
  factorynumber,
  typeOfEquipment,
  manufacturer,
  brand,
  partname,
  partid,
  installationaddress,
  statusstate,
  dateinit,

}) => {

  // функция заменяет латинские названия на русские
  const handlerTypeEquip = () => {
    let typeOfEquipmentRus = '';

    if (typeOfEquipment === 'Elevator') typeOfEquipmentRus = 'Лифт';
    if (typeOfEquipment === 'Escalator') typeOfEquipmentRus = 'Эскалатор';
    if (typeOfEquipment === 'Travolator') typeOfEquipmentRus = 'Травалатор';

    return typeOfEquipmentRus;
  };

  // функция заменяет латинские названия на русские
  const handlerStatusState = () => {
    let statusstateRus = '';

    if (statusstate === 'NEW') statusstateRus = 'Новая';
    if (statusstate === 'INWORK') statusstateRus = 'В работе';
    if (statusstate === 'CLOSED') statusstateRus = 'Закрыта';
    if (statusstate === 'REJECTED') statusstateRus = 'Отменена';

    return statusstateRus;
  };

  return (
    <li className={st.liftdown_item}>
      <div className={st.wrap_item}>
        <p className={st.item_title}>ID</p>
        <p className={st.item_text}>{uid}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Заводской номер</p>
        <p className={st.item_text}>{factorynumber}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Оборудование</p>
        <p className={st.item_text}>{handlerTypeEquip()}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Производитель</p>
        <p className={st.item_text}>{manufacturer}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Марка</p>
        <p className={st.item_text}>{brand}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Деталь</p>
        <p className={st.item_text}>{partname}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Номер детали</p>
        <p className={st.item_text}>{partid}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Адрес</p>
        <p className={st.item_text}>{`
        г. ${installationaddress?.city}, 
        ул. ${installationaddress?.street}, 
        д. ${installationaddress?.house}, 
        п. ${installationaddress?.entrance}
        `}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={st.item_title}>Статус</p>
        <p className={st.item_text}>{handlerStatusState()}</p>
      </div>

      <div className={st.wrap_item}>
        <p className={`${st.item_title} `}>Дата заявки</p>
        <p className={`${st.item_text} ${st.nowrap}`}>
          {dateinit.slice(0, -9).split('-').reverse().join('-')}
        </p>
      </div>
    </li>
  )
};
