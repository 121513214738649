// функция скрытия показа пароля
const passwordShowHide = (e, value, changeValue) => {
  e.preventDefault();

  value === 'password'
    ? changeValue('text')
    : changeValue('password');
};

export default passwordShowHide;
