import { PHONE_SETNEWPHONE, USER_VERIFY_SMS } from 'src/consts.js';
import { updateTokens } from 'src/utils/updateTokens';

// здесь отправляется два запроса: первый с кодом подтверждения, присланным на телефон, 
// второй, если код подтвержден, на смену номера телефона

// запрос отправляет проверочный код для подтверждения его на сервере
export const fetchConfirmCode = async (codeFromSMS, newPhone) => {
  // запрос на подтверждение кода из смс
  const response = await fetch(USER_VERIFY_SMS, {
    method: 'POST',
    body: JSON.stringify(codeFromSMS),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0'
    }
  });

  const data1 = await response.json();
  console.log('data: ', data1);

  if (data1.status === true) {
    const response = await fetch(PHONE_SETNEWPHONE, {
      method: 'POST',
      body: JSON.stringify(newPhone),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      }
    });

    const data2 = await response.json();
    console.log('data2: ', data2);

    console.log('сработал запрос на смену телефона без перезаписи токенов')

    if (response.status === 401 || data2.loginstatus === 7) {

      // перезапись токенов
      await updateTokens();

      // запрос после перезаписи токенов
      const response = await fetch(PHONE_SETNEWPHONE, {
        method: 'POST',
        body: JSON.stringify(newPhone),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'User-Agent': 'LiftupSite/1.0.0',
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        }
      });

      const data3 = await response.json();
      console.log('data3: ', data3);

      console.log('сработал запрос на смену телефона с перезапизью токенов')
    }
  } else {
    console.log('запрос на смену телефона не сработал');
  }

  return data1;
};
