import st from './EquipmentPreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { Overlay } from 'src/components/Overlay/Overlay';
import { EquipmentEdit } from '../EquipmentEdit/EquipmentEdit.jsx';
import { Link } from 'react-router-dom';
import { ReactComponent as IconPDF } from '../../../../../../img/icon_pdf.svg';
import { ReactComponent as IconNoPDF } from '../../../../../../img/icon_no_pdf.svg';
import { ReactComponent as IconDetails } from '../../../../../../img/icon_details.svg';
import { ReactComponent as IconRequisites } from '../../../../../../img/icon_requisites.svg';
import { ReactComponent as IconAddress } from '../../../../../../img/icon_address.svg';
import { ReactComponent as IconCalendar } from '../../../../../../img/icon_calendar.svg';
import { fetchEquipmentDel } from '../../fetches/fetchDel';
import { useDispatch } from 'react-redux';
import { fetchEquipmentList } from 'src/store/equipment/getEquipmentList';

export const EquipmentPreview = ({
  сurrentOrganization,

  efouid,
  uuid,
  equid,

  status,
  factorynumber,
  typeOfEquipment,
  manufacturername,
  cabintype,
  numberofstops,
  brandname,

  purpose,
  speed,
  loadcapacity,

  dateofacceptanceforwarranty,
  dateofcommissioning,
  passport,

  installationaddress,

  partsList,

  schedule,

  setCurrentPageNumber
}) => {
  const dispatch = useDispatch();
  const refEditOrDel = useRef(null);
  const refTargetEqip = useRef(null);

  // меню редактировать, удалить
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);
  // модалка окно редактирования
  const [modalEdit, setModalEdit] = useState(false);

  // модальные окна из "EquipmentPreview" открыть, закрыть
  const [openEditEquip, setOpenEditEquip] = useState(false);
  const [openTechSpec, setOpenTechSpec] = useState(false);
  const [openAddressInstall, setOpenAddressInstall] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  // функция удаляет оборудование
  const delEquipment = async (equipmentid) => {
    await fetchEquipmentDel(equipmentid);

    dispatch(fetchEquipmentList(сurrentOrganization[0]));

    setCurrentPageNumber();
  };

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  // показывает меню "редактировать удалить"
  const editOrDel = () => {
    setMenuEditOrDel(!menuEditOrDel);
  };

  // открывает окно "редактировать оборудование" выбранного элемента
  const editTargetEquip = (e) => {
    setModalEdit(!modalEdit);

    setOpenEditEquip(true);
    setOpenTechSpec(false);
    setOpenAddressInstall(false);

    setOpenDetails(false);
    setOpenPayment(false);
  };

  // открывает окно "редактировать технические характеристики" выбранного элемента
  const editTargetTechSpec = (e) => {
    setModalEdit(!modalEdit);

    setOpenEditEquip(false);
    setOpenTechSpec(true);
    setOpenAddressInstall(false);

    setOpenDetails(false);
    setOpenPayment(false);
  };

  // открывает окно "редактировать адрес установки" выбранного элемента
  const editTargetAddressInstall = (e) => {
    setModalEdit(!modalEdit);

    setOpenEditEquip(false);
    setOpenTechSpec(false);
    setOpenAddressInstall(true);

    setOpenDetails(false);
    setOpenPayment(false);
  };

  // открывает окно "редактировать детали" выбранного элемента
  const editTargetOpenDelails = (e) => {
    setModalEdit(!modalEdit);

    setOpenEditEquip(false);
    setOpenTechSpec(false);
    setOpenAddressInstall(false);

    setOpenDetails(true);
    setOpenPayment(false);
  };

  // открывает окно "редактировать график оплат" выбранного элемента
  const editTargetOpenPayment = (e) => {
    setModalEdit(!modalEdit);

    setOpenEditEquip(false);
    setOpenTechSpec(false);
    setOpenAddressInstall(false);

    setOpenDetails(false);
    setOpenPayment(true);
  };

  return (
    <>
      <li
        className={st.clbase_item}
        id={efouid}
        ref={refTargetEqip}
      >
        {/* ID */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>ID</p>
          <p className={st.item_id}>{efouid}</p>
        </div>

        {/* статус */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Статус</p>
          <p className={st.item_text}>{status === 'Entered' ? 'Заведен' : status === 'UnderWarranty' ? 'Стандартна гарантия' : status === 'Extended' ? 'Заведен' : 'Не указано'}</p>
        </div>

        {/* номер */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Номер</p>
          <p className={st.item_text}>{factorynumber}</p>
        </div>

        {/* оборудование */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Оборудование</p>
          {/* <p className={st.item_text}>{equipmentType}</p> */}
          <p className={st.item_text}>
            {
              typeOfEquipment === 'Elevator' ? 'Лифт' :
                typeOfEquipment === 'Escalator' ? 'Эскалатор' :
                  typeOfEquipment === 'Travolator' ? 'Травалатор' : 'не определен'
            }
          </p>
        </div>

        {/* производитель */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Производитель</p>
          <p className={st.item_text}>{manufacturername}</p>
        </div>

        {/* марка */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Марка</p>
          <p className={st.item_text}>{brandname}</p>
        </div>

        {/* произведено */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Произведено</p>
          <p className={st.item_text_c}>{dateofacceptanceforwarranty}</p>
        </div>

        {/* запущено */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Запущено</p>
          <p className={st.item_text_c}>{dateofcommissioning}</p>
        </div>

        {/* характеристики */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Хар-ки</p>
          <button
            className={st.item_button}
            onClick={editTargetTechSpec}>
            <IconRequisites />
          </button>
        </div>

        {/* паспорт */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Паспорт</p>
          {
            passport !== '' ?
              <Link className={st.item_icon} to={passport} target='_blank'><IconPDF /></Link> :
              <div className={st.item_icon}><IconNoPDF /></div>
          }
        </div>

        {/* детали */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Детали</p>
          <button
            className={st.item_button}
            onClick={editTargetOpenDelails}>
            <IconDetails />
          </button>
        </div>

        {/* адрес */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Адрес</p>
          <button
            className={st.item_button}
            onClick={editTargetAddressInstall}>
            <IconAddress />
          </button>
        </div>

        {/* платежи */}
        <div className={st.item_name}>
          <p className={st.item_name_p}>Платежи</p>
          <button
            className={st.item_button}
            onClick={editTargetOpenPayment}>
            <IconCalendar />
          </button>
        </div>

        {/* редактировать - удалить */}
        <div className={st.item_edit_del} ref={refEditOrDel}>
          <div className={st.item_btn} onClick={editOrDel}></div>

          <ul className={!menuEditOrDel ? st.btn_menu : st.btn_menu_show}>
            <li className={st.btn_menu_item} onClick={() => {
              editTargetEquip();
            }}>Редактировать</li>

            <li
              className={st.btn_menu_item}
              // onClick={() => fetchEquipmentDel(efouid)}>Удалить</li>
              onClick={() => delEquipment(efouid)}>Удалить</li>
            {/* onClick={delEquipment(efouid)}>Удалить</li> */}
          </ul>
        </div>
      </li>

      <Overlay className={!modalEdit ? st.overlay_hide : st.overlay_show}>
        <EquipmentEdit
          closeModal={() => setModalEdit(false)}
          status={status}

          efouid={efouid}
          uuid={uuid}
          equid={equid}

          factorynumber={factorynumber}
          typeOfEquipment={typeOfEquipment}
          manufacturername={manufacturername}
          cabintype={cabintype}
          numberofstops={numberofstops}
          brandname={brandname}

          purpose={purpose}
          speed={speed}
          loadcapacity={loadcapacity}

          dateofacceptanceforwarranty={dateofacceptanceforwarranty || ''}
          dateofcommissioning={dateofcommissioning || ''}
          passport={passport}

          installationaddress={installationaddress}

          partsList={partsList}

          schedule={schedule}

          openEditEquip={openEditEquip}
          openTechSpec={openTechSpec}
          openAddressInstall={openAddressInstall}
          openDetails={openDetails}
          openPayment={openPayment}
        />
      </Overlay>
    </>
  )
};
