import st from './Footer.module.css';
import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

export const Footer = () => {
  // получаем userroles
  const userroles = useSelector(store => store.authReducer.dataAuth.user?.userroles) || null;

  // для вставки в адресную ссылку при необходимости
  const id = useSelector(store => store.authReducer.dataAuth.organization?.id) || null;

  // следим за появлением в поле видимости "mark"
  const { ref, inView } = useInView({
    threshold: 0.6
  });

  // переключатель ссылки кнопки меню "личный кабинет", "администратор"
  const [switchAuthorBtn, setSwitchAuthorBtn] = useState('/signin/author');

  // текущий год
  let currentYear = new Date().getFullYear();

  useEffect(() => {
    // переключает ссылку в зависимости от авторизованности
    const redirectLink = () => {
      if (userroles === 999) {
        setSwitchAuthorBtn('/superlift/main');
      }

      if (userroles === 1) {
        setSwitchAuthorBtn(`/lk/${id}/aboutcompany`);
      }

      if (userroles === null) {
        setSwitchAuthorBtn('/signin/author');
      }
    };

    redirectLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userroles]);


  return (
    <footer className={st.footer}>
      <div className={st.mark} ref={ref}></div>
      <div className={st.footerReflection}></div>

      <div
        id='footer'
        className={st.footer_content}
        style={!inView ? { transform: 'translateY(70px)', opacity: '0' } : { transform: 'translateY(0)', opacity: '1' }}
      >
        <div className={`${st.footer_container} container`}>
          <ul className={st.menu_list}>
            <li className={st.menu_item}>
              <NavLink className={st.menu_link} to='/about'>О&nbsp;компании</NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={st.menu_link} to='/news/newslists'>Новости</NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={st.menu_link} to='/documents'>Документы</NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={st.menu_link} to='/analytics'>Аналитика</NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={st.menu_link} to='/clients'>Клиенты</NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={st.menu_link} to='/servicecenters'>Сервисные&nbsp;центры</NavLink>
              <div className={st.item_hover}></div>
            </li>
            {/* <li className={st.menu_item}>
              <NavLink className={st.menu_link} to='/error404'>Фонд&nbsp;запчастей</NavLink>
              <div className={st.item_hover}></div>
            </li> */}
            <li className={st.menu_item}>
              <NavLink className={st.menu_link} to={switchAuthorBtn}>
                {userroles === 999 ? 'Администратор' : 'Личный кабинет'}

              </NavLink>
              <div className={st.item_hover}></div>
            </li>
          </ul>

          <div className={st.copyright}>
            <Link to={'https://www.artmax-studio.ru/'} target='_blank'>
              <div className={st.logo_artmax}></div>
            </Link>

            <div className={st.copyright_text}>
              <p>© LIFTUP {currentYear} - Все&nbsp;права&nbsp;защищены&nbsp;· <Link to='/privacypolicy'>Политика&nbsp;конфиденциальности</Link></p>
            </div>

            <div className={st.copyright_text_mob}>
              <p>© LIFTUP {currentYear} - Все права защищены</p>
              <Link to='/privacypolicy'><p>Политика&nbsp;конфиденциальности</p></Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
};
