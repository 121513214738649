import st from './AdminPanel.module.css';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Exit } from '../../img/exit.svg';
import { ReactComponent as RedCircle } from '../../img/red_circle.svg';
import { useDispatch } from 'react-redux';
import { removeAuth } from 'src/store/authorization/auth';
import { removeLiftDownList } from 'src/store/liftdown/getLiftDownList';
import { removeEquipmentList } from 'src/store/equipment/getEquipmentList';
import { removeClientbaseList } from 'src/store/clientbase/getClientbaseList';

export const AdminPanel = () => {
  const navigate = useNavigate();
  const dopswitch = useLocation().pathname.includes('/superlift/equipment/');
  const dispatch = useDispatch();

  // присваиваем класс пункту меню NavLink
  const setMenuActive = ({ isActive, }) => isActive
    ? `${st.dept_link_active}`
    : `${st.dept_link}`;

  // присваиваем класс пункту меню "База клиентов"
  const setMenuActiveBaseCl = ({ isActive }) => isActive || dopswitch
    ? `${st.dept_link_active}`
    : `${st.dept_link}`;

  // выход из авторизации
  const outAuth = (e) => {
    e.preventDefault();

    localStorage.removeItem('tokenlu');
    localStorage.removeItem('otk');
    document.cookie = 'rtokenlu=;  path=/; max-age=0';

    dispatch(removeAuth());
    dispatch(removeLiftDownList());
    dispatch(removeEquipmentList());
    dispatch(removeClientbaseList());

    navigate('/');
  };

  return (
    <main className={`${st.adminpanel} container`}>
      <h2>Панель управления</h2>

      <div className={st.wrapper}>
        <ul className={st.depts_list}>
          <li className={st.dept_item}>
            <NavLink className={setMenuActive} to='/superlift/main'>
              <RedCircle className={st.red_circle}></RedCircle>
              <p>Главная</p>
            </NavLink>
          </li>
          <li className={st.dept_item}>
            <NavLink className={setMenuActive} to='/superlift/news'>
              <RedCircle className={st.red_circle}></RedCircle>
              <p>Новости</p>
            </NavLink>
          </li>
          <li className={st.dept_item}>
            <NavLink className={setMenuActive} to='/superlift/docs'>
              <RedCircle className={st.red_circle}></RedCircle>
              <p>Документы</p>
            </NavLink>
          </li>
          <li className={st.dept_item}>
            <NavLink className={setMenuActive} to='/superlift/ourclients'>
              <RedCircle className={st.red_circle}></RedCircle>
              <p>Наши клиенты</p>
            </NavLink>
          </li>
          <li className={st.dept_item}>
            <NavLink className={setMenuActive} to='/superlift/service'>
              <RedCircle className={st.red_circle}></RedCircle>
              <p>Сервисные центры</p>
            </NavLink>
          </li>
          <li className={st.dept_item}>
            <NavLink className={setMenuActiveBaseCl} to='/superlift/clientbase'>
              <RedCircle className={st.red_circle}></RedCircle>
              <p>База клиентов</p>
            </NavLink>
          </li>
          <li className={st.dept_item}>
            <NavLink className={setMenuActive} to='/superlift/liftdown'>
              <RedCircle className={st.red_circle}></RedCircle>
              <p>LiftDown</p>
            </NavLink>
          </li>
          <li className={st.dept_item}>
            <button className={st.dept_link} onClick={outAuth}>
              <RedCircle className={st.red_circle_exit}></RedCircle>

              <p>Выход</p>

              <Exit />
            </button>
          </li>
        </ul>

        <Outlet />
      </div>
    </main>
  )
};
