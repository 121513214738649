import { DOCS_ADD } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// одинарный запрос: на добавление всего кроме медиафайл
export const fetchEditWithoutImg = async (bodyDocsData) => {
  bodyDocsData.documentlinkuid = '';

  // запрос на добавление всего кроме самого медиафайл
  const responsedocadd = await fetch(DOCS_ADD, {
    method: 'POST',
    body: JSON.stringify(bodyDocsData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const datadocadd = await responsedocadd.json();

  console.log('datadocadd: ', datadocadd);
  console.log('данные на добавление всего, кроме документа, отправлены без перезаписи токенов');

  // в случае протухания токена обновляем пару "token - rtoken"
  if (responsedocadd.status === 401 || datadocadd.loginstatus === 7) {
    // перезапись токенов
    await updateTokens();

    bodyDocsData.documentlinkuid = '';

    // запрос на добавление всего кроме самого медиафайл
    const responsedocadd = await fetch(DOCS_ADD, {
      method: 'POST',
      body: JSON.stringify(bodyDocsData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datadocadd = await responsedocadd.json();

    console.log('datadocadd: ', datadocadd);
    console.log('данные на добавление всего, кроме документа, отправлены c перезаписью токенов');
  }
};
