import { useDispatch } from 'react-redux';
import { fetchChangeData } from '../../fetches/fetchChangeData';
import st from './EditBankRequisites.module.css';
import { useState } from 'react';
import { fetchUser } from 'src/store/authorization/auth';

export const EditBankRequisites = (
  {
    closeModal,
    dataAuth
  }
) => {
  const dispatch = useDispatch();

  const [orgBankAccount, setOrgBankAccount] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.bank?.accountnumber :
      'нет данных'
  );
  const [orgCorrAccount, setOrgCorrAccount] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.bank?.bankaccountnumber :
      'нет данных'
  );
  const [orgBank, setOrgBank] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.bank?.bankname :
      'нет данных'
  );
  const [orgBIK, setOrgBIK] = useState(
    Object.keys(dataAuth).length ?
      dataAuth.organization?.bank?.bankid :
      'нет данных'
  );

  // данные для тела запроса по отправке изменений данных банковских реквизитов
  const bodyChangeBankReqData = {
    'organization': {
      'uuid': dataAuth.organization.uuid,
      'inn': dataAuth.organization.inn,
      'id': dataAuth.organization.id,
      'shortname': dataAuth.organization.shortname,
      "fullname": dataAuth.organization.fullname,
      "respusername": dataAuth.organization?.respusername,
      'name': dataAuth.organization.name,
      'email': dataAuth.user.email,
      'phone': dataAuth.user.phonenumber,
      'serial': dataAuth.organization.serial,
      'jaddress': dataAuth.organization?.address?.legaladdress,
      'faddress': dataAuth.organization?.address?.physicaladdress,
      'directorname': dataAuth.organization?.persons?.directorname,
      'bookername': dataAuth.organization?.persons?.bookername,
      'sclientfnsinn': dataAuth.organization?.fnsinn,
    },
    'bank': {
      'id': '',
      'bankid': orgBIK,
      'bankname': orgBank,
      'bankaccountnumber': orgCorrAccount,
      'accountnumber': orgBankAccount
    }
  };

  // функция стандартной отправки запроса на изменение данных
  const postChangeDataBankReq = async (e) => {
    e.preventDefault();

    await fetchChangeData(bodyChangeBankReqData);

    dispatch(fetchUser());

    closeModal();

    console.log('форма запроса на изменение данных банковских реквизитов отправлена');
  };


  return (
    <div className={st.docsedit_wrap}>
      <button
        className={st.btn_close}
        onClick={closeModal}
      ></button>

      <div className={st.docsedit}>
        <form className={st.form}>

          {/* окно изменения банковских реквизитов */}
          <fieldset className={st.fieldset}>
            <h3 className={st.title}>Изменение банковских реквизитов</h3>

            {/* инпут редактирования расчетного счета организации */}
            <div className={st.wrap_title}>
              <p className={orgBankAccount !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Расчетный счет</p>

              <input
                className={st.input}
                name='orgBankAccount'
                value={orgBankAccount}
                maxLength='60'
                placeholder='Расчетный счет'
                onChange={(e) => setOrgBankAccount(e.target.value)}
              />
            </div>

            {/* инпут редактирования корреспонденского счета организации */}
            <div className={st.wrap_title}>
              <p className={orgCorrAccount !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Корреспондентский счет</p>

              <input
                className={st.input}
                name='orgCorrAccount'
                value={orgCorrAccount}
                maxLength='60'
                placeholder='Корреспондентский счет'
                onChange={(e) => setOrgCorrAccount(e.target.value)}
              />
            </div>

            {/* инпут редактирования наименования банка организации */}
            <div className={st.wrap_title}>
              <p className={orgBank !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                Банк</p>

              <input
                className={st.input}
                name='orgBank'
                value={orgBank}
                maxLength='60'
                placeholder='Банк'
                onChange={(e) => setOrgBank(e.target.value)}
              />
            </div>

            {/* инпут редактирования БИК организации */}
            <div className={st.wrap_title}>
              <p className={orgBIK !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
                БИК</p>

              <input
                className={st.input}
                name='orgBIK'
                value={orgBIK}
                maxLength='60'
                placeholder='БИК'
                onChange={(e) => setOrgBIK(e.target.value)}
              />
            </div>

            <button
              className={st.btn_submit}
              onClick={postChangeDataBankReq}
            >Сохранить</button>

          </fieldset>
        </form>
      </div>
    </div>
  );
};
