import st from './DeptDocs.module.css';
import { useEffect, useState } from 'react';
import { ReactComponent as IconSetting } from '../../../../img/icon_setting.svg';
// import { docs_list } from './data/docs_list_json.js';
import { v4 as uuidv4 } from 'uuid';
import { Overlay } from 'src/components/Overlay/Overlay';
import { DocsAdd } from './components/DocsAdd/DocsAdd.jsx';
import { DocsPreview } from './components/DocsPreview/DocsPreview.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocsList } from 'src/store/docs/getDocs';

export const DeptDocs = () => {
  // откр закр модалка
  const [modalAdd, setModalAdd] = useState(false);
  // сортированный массив документов
  const [docsListSort, setDocsListSort] = useState([]);

  const { documentList } = useSelector(state => state.docsListReducer.dataDocsList);
  const { status } = useSelector(state => state.docsListReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!documentList) {
      dispatch(fetchDocsList());
    }

    if (status === 'success') {
      // сортируем  массив по ключу "documentordernum" (по порядковому номеру поля)
      setDocsListSort(Array.from(documentList).sort((a, b) => {
        return (a.documentordernum > b.documentordernum) - 0.5;
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  // ===== пагинация =====
  // номер текущей страницы
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // количество заявок на странице
  const statementsQuantity = 4;

  // индекс последнего документа
  const lastStatementIndex = currentPageNumber * statementsQuantity;

  // индекс первого документа
  const firstStatementIndex = lastStatementIndex - statementsQuantity;


  if (status === 'success') {
    // ===== пагинация продолжение по условию=====
    // список элементов текущей страницы
    const statementsCurrentList = docsListSort.slice(firstStatementIndex, lastStatementIndex);
    // const statementsCurrentList = docs_list.slice(firstStatementIndex, lastStatementIndex);

    // общее количество заявок
    const totalStatementsQuantity = docsListSort.length;
    // const totalStatementsQuantity = docs_list.length;

    // список номеров-кнопок страниц
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalStatementsQuantity / statementsQuantity); i++) {
      pages.push(i);
    }

    // функция кнопки следующая страница
    const nextPage = () => {
      setCurrentPageNumber(currentPageNumber !== pages.length ? prev => prev + 1 : prev => prev);
    };

    // функция кнопки предыдущая страница
    const prevPage = () => {
      setCurrentPageNumber(currentPageNumber > 1 ? prev => prev - 1 : prev => prev);
    };
    // ===== пагинация =====

    return (
      <>
        <div className={st.deptnews}>
          <div className={st.title_and_btn}>
            <h3 className={st.title}>Документы</h3>

            <button className={st.add_news} onClick={() => setModalAdd(!modalAdd)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 1.75v16.5M17.75 10H1.25" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Добавить документ
            </button>
          </div>

          {/* список заголовков таблицы */}
          <ul className={st.table_header}>
            <li className={st.header_item}>№</li>
            <li className={st.header_item}>Наименование</li>
            <li className={st.header_item}><IconSetting /></li>
          </ul>

          {/* список документов */}
          <ul className={st.docs_list}>
            {statementsCurrentList.map(item =>
              <DocsPreview
                key={uuidv4()}
                documentuid={item.documentuid}
                documentname={item.documentname}
                documentlink={item.documentlink}
                documentordernum={item.documentordernum}
              />
            )}
          </ul>

          {/* пагинация */}
          <div className={st.pagination}>
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_prev : st.hidden}
              onClick={() => {
                prevPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
            {
              pages.map((item) => {
                return (
                  <button
                    key={uuidv4()}
                    onClick={() => {
                      setCurrentPageNumber(item);
                      window.scrollTo(0, 0);
                    }}
                    className={
                      pages.length === 1 ? st.hidden :
                        item === currentPageNumber
                          ? st.pagination_item_active
                          : st.pagination_item
                    }>
                    {item}
                  </button>
                );
              })
            }
            <button
              className={statementsQuantity <= totalStatementsQuantity ? st.pagination_btn_next : st.hidden}
              onClick={() => {
                nextPage();
                window.scrollTo(0, 0);
              }}
            >
            </button>
          </div>
        </div>

        <Overlay className={!modalAdd ? st.overlay_hide : st.overlay_show}>
          <DocsAdd
            closeModal={() => setModalAdd(false)}
            documentList={documentList}
            status={status}
          />
        </Overlay>
      </>
    )
  } else {
    return (
      <div className={st.deptnews}>
        <div className={st.title_and_btn}>
          <h3 className={st.title}>Документы</h3>
        </div>
        <p>загрузка DeptDocs...</p>
      </div>
    );
  }
};
