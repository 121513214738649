import st from './OurClientsEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurclientsList } from 'src/store/ourclients/getOurclientsList';
import { fetchEditWithoutImgOurClient } from '../../fetches/fetchEditWithoutImg';
import { fetchOurClientAddEdit } from '../../fetches/fetchAddEdit';
import { fetchReorderOurClients } from '../../fetches/fetchReorder';

export const OurClientsEdit = ({
  closeModal,
  targetID,
  targetLogo,
  targetName,
  targetLink,
  targetOrgcount,
}) => {
  // список наших клиентов в store
  const { ourorgs } = useSelector(state => state.ourclientsListReducer.dataOurclientsList);
  // инпуты "превью лого"
  const [imagePreviewEdit, setImagePreviewEdit] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  // инпут "имя нашего клиента"
  const [title, setTitle] = useState('');
  // инпут "ссылка на сайт нашего клиента"
  const [ourClientLink, setOurClientLink] = useState('');
  // инпут "порядковый номер нашего клиента"
  const [orgcountNew, setOrgcount] = useState(targetOrgcount);

  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelection = useRef(null);
  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');


  const dispatch = useDispatch();

  // данные для второго запроса
  const ourClientsDataBody = {
    'id': targetID,
    'orgcount': orgcountNew,
    'name': title,
    'logo': '',
    'link': ourClientLink,
    'orgid': ''
  };

  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);

    if (formData.get('uploadedFile') === '') {
      console.log('пустая строка');

      // перебор массива наших клиентов для нахождения совпадения вновь вводимого номера 
      // документа и если есть, то заменять имеющийся номер на тот, 
      // который был раньше у редактируемого элемента
      ourorgs.forEach(async item => {
        console.log('item: ', item);
        if (
          item.orgcount === +ourClientsDataBody.orgcount
          && +ourClientsDataBody.orgcount !== targetOrgcount
        ) {
          // данные для запроса на изменение порядкового номера элемента 
          // с уже имеющимся таким же значением "orgcount"
          const docsDataReorderBody = {
            'ourorgs': [
              {
                'id': item.id,
                'orgcount': targetOrgcount
              }
            ]
          };
          await fetchReorderOurClients(docsDataReorderBody);
        }
      });

      await fetchEditWithoutImgOurClient(ourClientsDataBody);
    }

    if (formData.get('uploadedFile') !== '') {
      console.log('НЕЕЕЕЕ пустая строка: ', formData.get('uploadedFile'));

      // перебор массива наших клиентов для нахождения совпадения вновь вводимого номера 
      // документа и если есть, то заменять имеющийся номер на тот, 
      // который был раньше у редактируемого элемента
      ourorgs.forEach(async item => {
        console.log('item: ', item);
        if (
          item.orgcount === +ourClientsDataBody.orgcount
          && +ourClientsDataBody.orgcount !== targetOrgcount
        ) {
          // данные для запроса на изменение порядкового номера элемента 
          // с уже имеющимся таким же значением "orgcount"
          const docsDataReorderBody = {
            'ourorgs': [
              {
                'id': item.id,
                'orgcount': targetOrgcount
              }
            ]
          };
          await fetchReorderOurClients(docsDataReorderBody);
        }
      });

      await fetchOurClientAddEdit(formData, ourClientsDataBody);
    }

    dispatch(fetchOurclientsList());

    closeModal();

    console.log('форма отправлена');
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreview(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью добавить файл"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setImagePreview(null);

    // старый вариант при котором появляется окно со старым файлом для ред-ния
    // setImagePreviewEdit(true);
  };

  // удаляем поле "превью редактировать файл"
  const clearPreviewFileEdit = (e) => {
    e.preventDefault();
    setImagePreviewEdit(false);
  };

  useEffect(() => {
    setTitle(targetName);
    setOurClientLink(targetLink);
    setOrgcount(targetOrgcount);
  }, [targetName, targetLink, targetOrgcount]);

  return (
    <div className={st.clientsedit_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.clientsedit}>
        <h3 className={st.title}>Редактирование клиента</h3>

        <form
          onSubmit={handleFormSubmit}
          className={st.form}>

          {/* блок с превью выбранного для редактирования лого клиента */}
          <div className={imagePreviewEdit === true ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Лого клиента</p>

            <div className={st.image_preview}>
              <img src={targetLogo} alt='' />
              <button className={st.btn_delete} onClick={clearPreviewFileEdit}></button>
            </div>
          </div>

          {/* блок с превью выбранного для загрузки нового лого клиента */}
          <div className={imagePreview !== null ? st.image_preview_show : st.image_preview_hide}>
            <p className={st.image_preview_show_p}>Лого клиента</p>

            <div className={st.image_preview}>
              {imagePreview !== null && <img src={imagePreview} alt='' />}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора нового лого клиента для загрузки с ПК */}
          <fieldset className={
            imagePreview === null && imagePreviewEdit === false
              ? st.fieldset_show : st.fieldset_hide}>
            <label className={st.label_image}>
              <input
                ref={fileSelection}
                className={st.input_image}
                type='file'
                name='image'
                accept='image/*,image/jpeg,image/png,image/svg'
                onChange={previewFile}
              />

              <p>Изменить лого</p>

              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут редактирования наименования клиента */}
          <div className={st.wrap_title}>
            <p className={title !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Наименование клиента</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  // ref={refTitle}
                  className={st.input_title}
                  type='text'
                  name='title'
                  value={title}
                  placeholder='Наименование клиента'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут редактирования ссылки на сайт клиента */}
          <div className={st.wrap_title}>
            <p className={ourClientLink !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Ссылка на сайт</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='ourClientLink'
                  value={ourClientLink}
                  placeholder='Ссылка на сайт'
                  onChange={(e) => setOurClientLink(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут редактирования порядкового номера клиента */}
          <div className={st.wrap_title}>
            <p className={orgcountNew !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Порядковый номер</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='orgcountNew'
                  value={orgcountNew}
                  placeholder='Порядковый номер'
                  onChange={(e) => setOrgcount(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={closeModal}
          >Сохранить</button>
        </form>
      </div>
    </div>
  )
};
