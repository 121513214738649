import st from './DocsEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocsList } from 'src/store/docs/getDocs';
import { fetchDocsAddEdit } from '../../fetches/fetchAddEdit';
import { fetchEditWithoutImg } from '../../fetches/fetchEditWithoutImg';
import { fetchDocReorder } from '../../fetches/fetchReorder';

export const DocsEdit = ({
  closeModal,
  failName,
  documentuid,
  documentname,
  documentordernum
}) => {
  const { documentList } = useSelector(state => state.docsListReducer.dataDocsList);
  // инпуты "превью файлов", "имя документа", "порядковый номер документа"
  const [docPreviewEdit, setDocPreviewEdit] = useState(true);
  const [docPreview, setDocPreview] = useState(null);
  const [docNameEdit, setDocNameEdit] = useState(documentname);
  const [docOrder, setDocOrder] = useState(documentordernum);

  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelection = useRef(null);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  const dispatch = useDispatch();

  // данные для второго запроса
  const docsDataBody = {
    'documentuid': documentuid,
    'documentname': docNameEdit,
    'documentlinkuid': '',
    'documentordernum': docOrder,
  };



  // отправляет форму
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);

    if (formData.get('uploadedFile') === '') {
      console.log('пустая строка');

      // перебор массива документов для нахождения совпадения вновь вводимого номера 
      // документа и если есть, то заменять имеющийся номер на тот, 
      // который был раньше у редактируемого элемента
      documentList.forEach(async item => {
        if (
          item.documentordernum === +docsDataBody.documentordernum
          && +docsDataBody.documentordernum !== documentordernum
        ) {
          // данные для запроса на изменение порядкового номера элемента 
          // с уже имеющимся таким же значением "documentordernum"
          const docsDataReorderBody = {
            'document': [
              {
                'documentuid': item.documentuid,
                'documentordernum': documentordernum
              }
            ]
          };
          await fetchDocReorder(docsDataReorderBody);
        }
      });

      await fetchEditWithoutImg(docsDataBody);
    }

    if (formData.get('uploadedFile') !== '') {
      console.log('НЕЕЕЕЕ пустая строка: ', formData.get('uploadedFile'));

      // перебор массива документов для нахождения совпадения вновь вводимого номера 
      // документа и если есть, то заменять имеющийся номер на тот, 
      // который был раньше у редактируемого элемента
      documentList.forEach(async item => {
        if (
          item.documentordernum === +docsDataBody.documentordernum
          && +docsDataBody.documentordernum !== documentordernum
        ) {
          // данные для запроса на изменение порядкового номера элемента 
          // с уже имеющимся таким же значением "documentordernum"
          const docsDataReorderBody = {
            'document': [
              {
                'documentuid': item.documentuid,
                'documentordernum': documentordernum
              }
            ]
          };
          await fetchDocReorder(docsDataReorderBody);
        }
      });

      await fetchDocsAddEdit(formData, docsDataBody);
    }

    dispatch(fetchDocsList());

    closeModal();

    console.log('форма отправлена');
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('application')) {
        setDocPreview(selectedFile.name);
      }
    };
  };

  // удаляем поле "превью добавить файл"
  const clearPreviewFile = (e) => {
    e.preventDefault();
    setDocPreview(null);
    setSelectedFileDouble('');
  };

  // удаляем поле "превью редактировать файл"
  const clearPreviewFileEdit = (e) => {
    e.preventDefault();
    setDocPreviewEdit(false);
  };

  return (
    <div className={st.docsedit_wrap}>
      <button className={st.btn_close} onClick={closeModal}>
        <BtnClose />
      </button>

      <div className={st.docsedit}>
        <h3 className={st.title}>Редактирование документа</h3>

        <form className={st.form}>
          {/* блок с превью выбранного для редактирования документа */}
          <div className={docPreviewEdit === true ? st.doc_preview_show : st.doc_preview_hide}>
            <p className={st.doc_preview_show_p}>Удаление файла</p>

            <div className={st.doc_preview}>
              {docPreview === null && <p className={st.pdf_preview}><span>{failName}</span></p>}
              <button className={st.btn_delete} onClick={clearPreviewFileEdit}></button>
            </div>
          </div>

          {/* блок с превью выбранного для загрузки документа с ПК */}
          <div className={docPreview !== null ? st.doc_preview_show : st.doc_preview_hide}>
            <p className={st.doc_preview_show_p}>Загрузка файла</p>

            <div className={st.doc_preview}>
              {docPreview !== null && <p className={st.pdf_preview}><span>{docPreview}</span></p>}
              <button className={st.btn_delete} onClick={clearPreviewFile}></button>
            </div>
          </div>

          {/* инпут с кнопкой для выбора нового документа для загрузки с ПК */}
          <fieldset className={
            docPreview === null && docPreviewEdit === false
              ? st.fieldset_show : st.fieldset_hide}>
            <label className={st.label_image}>
              <input
                ref={fileSelection}
                className={st.input_image}
                type='file'
                name='doc'
                accept='application/pdf'
                onChange={previewFile}
              />
              <p>Загрузка файла...</p>
              <BtnLoad />
            </label>
          </fieldset>

          {/* инпут редактирования наименования документа */}
          <div className={st.wrap_title}>
            <p className={docNameEdit !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>
              Наименование документа</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  // ref={refTitle}
                  className={st.input_title}
                  type='text'
                  name='docNameEdit'
                  value={docNameEdit}
                  placeholder='Наименование документа'
                  onChange={(e) => setDocNameEdit(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          {/* инпут редактирования порядкового номера документа */}
          <div className={st.wrap_title}>
            <p className={docOrder !== '' ? st.wrap_title_p : st.wrap_title_p_hide}>Порядковый номер</p>

            <fieldset className={st.fieldset_title}>
              <label className={st.label_title}>
                <input
                  className={st.input_title}
                  type='text'
                  name='documentordernum'
                  value={docOrder}
                  placeholder='Порядковый номер'
                  onChange={(e) => setDocOrder(e.target.value)}
                />
              </label>
            </fieldset>
          </div>

          <button
            className={st.btn_submit}
            onClick={handleFormSubmit}
          >Сохранить</button>
        </form>
      </div>
    </div>
  )
};
