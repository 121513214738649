import st from './NewsRead.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsList } from 'src/store/news/getNewsList';
import { Helmet } from 'react-helmet-async';

export const NewsRead = () => {
  const { newsList } = useSelector(state => state.newsListReducer.dataNewsList);
  const { status } = useSelector(state => state.newsListReducer);

  const targetIdNews = useParams().id;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!newsList) {
      dispatch(fetchNewsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    status === 'success' ?
      <main className={`${st.newsread} container`}>
        <div className={st.flashlight1}></div>
        <div className={st.flashlight2}></div>

        <div className={st.btn_wrap} onClick={() => navigate('/news/newslists')}>
          <div className={st.btn_back}></div>
          <p>Все новости</p>
        </div>

        {newsList.map(item => (
          item.newsid === +targetIdNews ?
            <div key={uuidv4()}>
              <Helmet>
                <title>{item.newsname}</title>
                <meta
                  name="description"
                  content={item.newspreview}
                />
              </Helmet>

              <div className={st.wrap_text}>
                <h1 className={st.title}>{item.newsname}</h1>
                <p className={st.date}>{item.newsdate.slice(0, -9)}</p>
              </div>

              <img className={st.image} src={item.imagelink} alt="" />

              <div className={st.text_read} dangerouslySetInnerHTML={{ __html: item.newsvalue }}></div>
            </div> : null
        ))}
      </main> :
      <main className={`${st.newsread} container`}>
        <div className={st.btn_wrap} onClick={() => navigate(-1)}>
          <div className={st.btn_back}></div>
          <p>Все новости</p>
        </div>

        <p>загрузка NewsRead...</p>
      </main>
  )
};
