// функция форматирует ввод адреса организации
const addressMask = (value) => {
  // if (!value) return value;

  let address = value.replace(/[^а-я А-Я\d.]/ig, '');

  if (address.length > 2 && !address.includes('ул.')) {
    address = `ул. ${address}`;
  }

  if (address.length === 5 || address.length === 7) {
    address = `ул. ${address[4].toUpperCase()}${address.substring(5)}`;
  }

  return address;
};

export default addressMask;
