import st from './SlideEdit.module.css';
import { ReactComponent as BtnClose } from '../../../../../../img/btn_close.svg';
import { ReactComponent as BtnLoad } from '../../../../../../img/btn_folder.svg';
import { useRef, useState } from 'react';
import { fetchSlidesList } from 'src/store/slides/getSlides.js';
import { useDispatch } from 'react-redux';
import { fetchSlideAddEdit } from '../../fetches/fetchAddEdit';
import { fetchEditWithoutImg } from '../../fetches/fetchEditWithoutImg';

export const SlideEdit = ({ modalClose, slideID, slideimagelink, slideLink, slideOrder }) => {
  // инпуты существующей картинки, выбора новой картинки и ввода ссылки для слайда
  const [imagePreviewAdd, setImagePreviewAdd] = useState(null);
  const [imagePreviewEdit, setImagePreviewEdit] = useState(true);
  const [slideLinkEdit, setSlideLinkEdit] = useState(slideLink || '');

  // ссылка на инпут с кнопкой для выбора файла для загрузки на ПК
  const fileSelection = useRef(null);

  // данные выбранного для отправки файла
  const [selectedFileDouble, setSelectedFileDouble] = useState('');

  const dispatch = useDispatch();

  // данные для второго запроса
  const slidesDataBody = {
    'slideID': slideID,
    'slideLink': slideLinkEdit,
    'slideimagelink': '',
    'slideOrder': slideOrder
  };

  // отправляет форму редактирования слайда
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('uploadedFile', selectedFileDouble);

    if (formData.get('uploadedFile') === '') {
      console.log('пустая строка');
      await fetchEditWithoutImg(slidesDataBody);
    }

    if (formData.get('uploadedFile') !== '') {
      console.log('НЕЕЕЕЕ пустая строка: ', formData.get('uploadedFile'));
      await fetchSlideAddEdit(formData, slidesDataBody);
    }

    // await fetchSlideAddEdit(formData, slidesDataBody);

    dispatch(fetchSlidesList());

    modalClose();

    console.log('форма редактирования слайда отправлена');
  };

  // выбирает новый файл на ПК и вставляет в превью
  const previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    setSelectedFileDouble(selectedFile);

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        setImagePreviewAdd(readerEvent.target.result);
      }
    };
  };

  // удаляем поле "превью добавить файл"
  const clearPreviewFileAdd = (e) => {
    e.preventDefault();
    setImagePreviewAdd(null);
    setSelectedFileDouble('');
  };

  // удаляем поле "превью редактировать файл"
  const clearPreviewFileEdit = (e) => {
    e.preventDefault();
    setImagePreviewEdit(false);

  };

  return (
    <div className={st.addslidesave}>
      <button className={st.btn_close} onClick={modalClose}>
        <BtnClose />
      </button>

      <h3 className={st.title}>Редактирование слайда</h3>

      <form className={st.form}>

        {/* блок с превью выбранного для редактирования слайда */}
        <div className={imagePreviewEdit === true ? st.image_preview_show : st.image_preview_hide}>
          <p className={st.image_preview_show_p}>Картинка слайда</p>

          <div className={st.image_preview}>
            <img src={slideimagelink} alt='' />
            <button className={st.btn_delete} onClick={clearPreviewFileEdit}></button>
          </div>
        </div>

        {/* блок с превью выбранного для загрузки нового слайда */}
        <div className={imagePreviewAdd !== null ? st.image_preview_show : st.image_preview_hide}>
          <p className={st.image_preview_show_p}>Картинка для слайда</p>

          <div className={st.image_preview}>
            {imagePreviewAdd !== null && <img src={imagePreviewAdd} alt='' />}
            <button className={st.btn_delete} onClick={clearPreviewFileAdd}></button>
            {/* <button className={st.btn_delete} onClick={clearPreviewFileEdit}></button> */}
          </div>
        </div>

        {/* инпут с кнопкой для выбора файла для загрузки из ПК */}
        <fieldset className={
          imagePreviewAdd === null && imagePreviewEdit === false
            ? st.fieldset_show : st.fieldset_hide}>

          <label className={st.label_image}>
            <input
              ref={fileSelection}
              className={st.input_image}
              type='file'
              name='image'
              accept='image/*,image/jpeg,image/png,image/svg'
              onChange={previewFile}
            />
            <p>Картинка для слайда...</p>
            <BtnLoad />
          </label>
        </fieldset>

        {/* инпут ввода ссылки для слайда */}
        <div className={st.wrap_link}>
          <p className={slideLinkEdit && slideLinkEdit !== '' ? st.wrap_link_p : st.wrap_link_p_hide}>Ссылка</p>

          <fieldset className={st.fieldset_link}>
            <label className={st.label_link}>
              <input
                className={st.input_link}
                type='text'
                name='slideLinkEdit'
                value={slideLinkEdit}
                placeholder='Ссылка'
                onChange={(e) => setSlideLinkEdit(e.target.value)}
              />
            </label>
          </fieldset>
        </div>

        <button
          className={st.btn_submit}
          onClick={handleFormSubmit}
        // disabled={selectedFileDouble !== '' ? false : true}
        >Сохранить</button>
      </form>
    </div>
  )
};
