import { MEDIAFILE_ADD, EQUIP_ADD } from 'src/consts';
import { updateTokens } from 'src/utils/updateTokens';

// запрос отправляет данные с документом на добавление или модификацию 
// оборудования организации из панели администратора
// это двойной запрос: сначала отправляем медиафайл и получаем ссылку на него, 
// потом отправляем второй запрос и вставляем в него, помимо основных данных,
// полученную из первого запроса ссылку на медиафайл
export const fetchEquipmentWithDocAddEdit = async (bodyMediaFile, basicData) => {
  // первый запрос
  const responsemediafile = await fetch(MEDIAFILE_ADD, {
    method: 'POST',
    body: bodyMediaFile,
    headers: {
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const datamediafile = await responsemediafile.json();

  // в случае просроченного токена обновляем пару "token - rtoken"
  if (responsemediafile.status === 401 || datamediafile.loginstatus === 7) {

    // перезапись токенов
    await updateTokens();

    // первый запрос
    const responsemediafile = await fetch(MEDIAFILE_ADD, {
      method: 'POST',
      body: bodyMediaFile,
      headers: {
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const datamediafile = await responsemediafile.json();

    // basicData.passportlink = datamediafile.mediafileuid;
    basicData.passportlinkuid = datamediafile.mediafileuid;

    // второй запрос
    const resBasicData = await fetch(EQUIP_ADD, {
      method: 'POST',
      body: JSON.stringify(basicData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const dataResBasic = await resBasicData.json();
    // console.log('dataResBasic: ', dataResBasic);
    console.log('сработал вариант отправки оборудования с документом c перезаписью токенов');

  } else {

    // basicData.passportlink = datamediafile.mediafileuid;
    basicData.passportlinkuid = datamediafile.mediafileuid;

    // второй запрос
    const resBasicData = await fetch(EQUIP_ADD, {
      method: 'POST',
      body: JSON.stringify(basicData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const dataResBasic = await resBasicData.json();
    // console.log('dataResBasic: ', dataResBasic);
    console.log('сработал вариант отправки оборудования с документом без перезаписи токенов');
  }
};


// запрос отправляет данные без документа на добавление или модификацию 
// оборудования организации из панели администратора
export const fetchEquipmentNoDocAddEdit = async (basicData) => {

  const resBasicData = await fetch(EQUIP_ADD, {
    method: 'POST',
    body: JSON.stringify(basicData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });
  const dataResBasic = await resBasicData.json();

  if (dataResBasic.loginstatus !== 7) {
    console.log('сработал вариант отправки без документа без перезаписи токенов');
  };

  // в случае просроченного токена обновляем пару "token - rtoken"
  if (resBasicData.status === 401 || dataResBasic.loginstatus === 7) {

    // перезапись токенов
    await updateTokens();

    const resBasicData = await fetch(EQUIP_ADD, {
      method: 'POST',
      body: JSON.stringify(basicData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });
    const dataResBasic = await resBasicData.json();

    // console.log('dataResBasic: ', dataResBasic);
    console.log('сработал вариант отправки без документа c перезаписью токенов');
  }
};
