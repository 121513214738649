import st from './SectionConsultation.module.css';
import arrow_right from '../../img/arrow_right.svg';
import up from '../../img/lift_btns/up.svg';
import btn_liftopen from '../../img/lift_btns/btn_liftopen.svg';
import btn_liftclose from '../../img/lift_btns/btn_liftclose.svg';
import btn_1 from '../../img/lift_btns/btn_1.svg';
import btn_2 from '../../img/lift_btns/btn_2.svg';
import btn_3 from '../../img/lift_btns/btn_3.svg';
import btn_4 from '../../img/lift_btns/btn_4.svg';
import btn_5 from '../../img/lift_btns/btn_5.svg';
import btn_6 from '../../img/lift_btns/btn_6.svg';
import { useState } from 'react';
import scrollingHendler from 'src/utils/scrollingHendler';
import handleInputChange from 'src/utils/handleInputChange';
import phoneNumberMask from 'src/utils/phoneNumberMask';
import { fetchFeedbackContacts } from 'src/fetch/fetchFeedbackContacts';

export const SectionConsultation = () => {
  const patternMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  // поля ввода емайла и телефона для обратной связи с их уведомлениями
  const [feedbackMail, setFeedbackMail] = useState('');
  const [showNoteMail, setShowNoteMail] = useState('');
  const [feedbackPhone, setFeedbackPhone] = useState('');
  const [showNotePhone, setShowNotePhone] = useState('');

  // чекбокс управление
  const [checked, setChecked] = useState(true);

  // уведомление над кнопкой об успешной отправке крнтактных данных
  const [noteOfSending, setNoteOfSending] = useState(false);

  // уведомление над кнопкой о неверно заполненных полях
  const [noteOfIncorrectFields, setNoteOfIncorrectFields] = useState(false);

  // уведомление над кнопкой о не принятом соглашении
  const [noteOfNotAgreement, setNoteOfNotAgreement] = useState(false);

  // скроллинг вверх вниз табло
  const [scroll, setScroll] = useState(0);
  // скроллинг вверх вниз направление
  const [btnsUpDown, setBtnsUpDown] = useState(false);

  // показывает этажи в табло в зависимости от положения прокрутки (вверх и вниз)
  scrollingHendler(setScroll, setBtnsUpDown);

  // меняет значение в инпуте на отформатированное
  const phoneNumberFormatter = (e) => {
    const formattedTargetValue = phoneNumberMask(e.target.value);

    e.target.value = formattedTargetValue;

    setFeedbackPhone(e.target.value);

    if (e.target.value.length === 13) {
      setShowNotePhone(true);
    } else {
      setShowNotePhone(false);
    }
  }

  // формируем объект для передачи в теле запроса
  const feedbackContactsData = {
    "email": feedbackMail,
    "phone": 7 + feedbackPhone.replace(/\D/g, '')
  };

  // отправляем контактные данные для обратной связи
  const handlerSendingFeedbackContacts = () => {
    if (showNoteMail && showNotePhone && checked) {
      // Все правильно - отправляем

      fetchFeedbackContacts(feedbackContactsData, setNoteOfSending);

      // скрываем уведомления
      setNoteOfIncorrectFields(false);
      setNoteOfNotAgreement(false);

      // очищаем поля ввода
      setFeedbackMail('');
      setFeedbackPhone('');
    } else if (showNoteMail && showNotePhone && !checked) {
      // Примите условия Пользовательского соглашения

      // скрываем уведомление
      setNoteOfIncorrectFields(false);
      // показываем уведомление
      setNoteOfNotAgreement(true);
    } else {
      // Не правильно заполнены данные

      // показываем уведомление
      setNoteOfIncorrectFields(true);
      // скрываем уведомление
      setNoteOfNotAgreement(false);
    }
  };

  return (
    <section className={st.sectionconsultation}>
      <div className={st.mark} id='сonsultation'></div>
      <div className={`${st.block_background} outer_container`}>
        <div className='container'>
          <div className={st.flex_content}>
            <div className={st.wrap_scroll_count}>
              <div className={st.count_screen}>
                <a href='#footer'>
                  <img className={!btnsUpDown ? st.count_down : st.count_down_active} src={up} alt='' />
                </a>

                <h4><span>00</span>{scroll}</h4>

                <a href='/#'>
                  <img className={btnsUpDown ? st.count_up : st.count_up_active} src={up} alt='' />
                </a>
              </div>

              <div className={st.wrap_count_control}>
                <div className={st.count_control}>
                  <img src={btn_liftopen} alt='' />
                  <img src={btn_liftclose} alt='' />
                  <img src={btn_1} alt='' />
                  <img src={btn_2} alt='' />
                  <img src={btn_3} alt='' />
                  <img src={btn_4} alt='' />
                  <img src={btn_5} alt='' />
                  <img src={btn_6} alt='' />
                </div>
              </div>
            </div>

            <div className={st.wrap_form}>
              <h2>Получите консультацию</h2>

              <p className={st.wrap_content_p}>
                Наша гарантия дает вам уверенность и&nbsp;защиту от&nbsp;неожиданных расходов на&nbsp;ремонт, а&nbsp;также гарантирует быстрое и&nbsp;качественное обслуживание в&nbsp;случае поломки.
              </p>

              <form className={st.form} id='formconsult'>
                <fieldset className={st.fieldset}>
                  <div className={st.input_wrap}>
                    <p className={
                      showNoteMail === '' ? st.note_empty : showNoteMail ? st.note_hide : st.note_visible
                    }>Введите корректный адрес</p>

                    <input
                      className={st.input_mail}
                      type='email'
                      name='feedbackMail'
                      value={feedbackMail}
                      placeholder='ваш e-mail'
                      onChange={(e) => handleInputChange(e, patternMail, setShowNoteMail, setFeedbackMail)}
                    />
                  </div>

                  <div className={st.input_wrap}>
                    <p className={
                      showNotePhone === '' ? st.note_empty : showNotePhone ? st.note_hide : st.note_visible
                    }>Введите корректный номер телефона</p>

                    <p className={st.select}>+7</p>

                    <input
                      className={st.input_phone}
                      type='tel'
                      name='feedbackPhone'
                      value={feedbackPhone}
                      placeholder='ваш телефон'
                      onChange={(e) => phoneNumberFormatter(e)}
                    />
                  </div>
                </fieldset>

                <fieldset className={st.fieldset_assent}>
                  <div className={st.input_wrap}>
                    <p className={
                      checked ? st.note_empty : st.note_visible
                    }>Примите условия Пользовательского соглашения</p>

                    <label className={st.label_check}>
                      <input
                        className={st.input_check}
                        type='checkbox'
                        name='assent'
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />

                      <p>Я&nbsp;принимаю условия Пользовательского соглашения и&nbsp;даю своё согласие на&nbsp;обработку моей персональной информации на&nbsp;условиях, определенных Политикой конфиденциальности.</p>
                    </label>
                  </div>
                </fieldset>
              </form>

              <div className={st.input_wrap}>
                <p className={
                  noteOfSending ? st.note_visible : st.note_hide
                }>Сообщение отправлено!</p>

                <p className={
                  noteOfIncorrectFields ? st.note_visible : st.note_hide
                }>Не правильно заполнены данные!</p>

                <p className={
                  noteOfNotAgreement ? st.note_visible : st.note_hide
                }>Примите условия Пользовательского соглашения!</p>

                <button className={st.btn_order} onClick={handlerSendingFeedbackContacts}>
                  <p>Оставить заявку</p>
                  <img className={st.btn_img} src={arrow_right} alt='' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};
