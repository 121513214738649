import st from './Part.module.css';
import { useEffect, useState } from 'react';

// это карточка одной детали (4 поля) которая рендерится при нажатии на кнопку "Детали" или символ Гайка
// в зависимости от имени детали сюда передаются соответствующие данные
// сложность в том, что тело запроса на редактирование данных формируется в "EquipmentEdit"
// а инпуты редактирования данных в этой карточке
export const Part = ({
  partid,
  partname,

  editDetId,
  setEditDetId,
  editDetProductdate,
  setEditDetProductdate,
  editDetAcceptwarrantydate,
  setEditDetAcceptwarrantydate,
  editDetExpiratwarrantydate,
  setEditDetExpiratwarrantydate,
}) => {

  // Первая деталь = Электродвигатель главного привода
  // Вторая деталь = Частотный преобразователь
  // Третья деталь = Главная плата управления лифтом
  // Четвертая деталь = Плата управления приводом дверей кабины

  // детали показ инпутов
  const [inputEditDetIdShow, setInputEditDetIdShow] = useState(false);
  const [inputDetProductdateShow, setInputDetProductdateShow] = useState(false);
  const [inputDetAcceptwarrantydateShow, setInputDetAcceptwarrantydateShow] = useState(false);
  const [inputDetExpiratwarrantydateShow, setInputDetExpiratwarrantydateShow] = useState(false);

  // состояние для того чтобы поле editDetId в инпуте редактирования индивидуального номера детали
  // обновлялось не при каждой введенной букве, а только при нажатии на кнопку "сохранить"
  const [bufferEditDetId, setBufferEditDetId] = useState(editDetId);

  // при пусом номере детали даты обнуляются
  useEffect(() => {
    if (editDetId === '') {
      setEditDetProductdate('');
      setEditDetAcceptwarrantydate('');
      setEditDetExpiratwarrantydate('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDetId]);

  return (
    <div className={st.edit_det_wrap}>
      {/* <h4 className={st.edit_det_title}>Первая деталь</h4> */}
      <h4 className={st.edit_det_title}>{partname}</h4>

      {/* инпут редактирования индивидуального номера детали */}
      <div className={st.edit_input}>
        <div className={st.edit_field_wrap}>
          <p className={st.edit_field_title}>Индивидуальный номер</p>

          <p className={!inputEditDetIdShow ? st.edit_field_text : st.edit_field_text_hidden}>
            {editDetId !== '' ? editDetId : 'нет детали'}
          </p>

          <div className={st.wrap_p}>
            <p
              className={!inputEditDetIdShow ? st.edit_field_btn : st.edit_field_btn_hide}
              onClick={() => setInputEditDetIdShow(true)}>Изменить</p>

            <p
              className={inputEditDetIdShow ? st.save_field_btn : st.save_field_btn_hide}
              onClick={() => {
                setInputEditDetIdShow(false);
                setEditDetId(bufferEditDetId)
              }}>Сохранить</p>
          </div>
        </div>

        <input
          className={inputEditDetIdShow ? st.edit_entry : st.edit_entry_hidden}
          type='text'
          name='editDet_det_id'
          value={bufferEditDetId}
          placeholder='Индивидуальный номер'
          onChange={(e) => setBufferEditDetId(e.target.value)}
        />
      </div>

      {/* инпут редактирования даты производства детали */}
      <div className={st.edit_input}>
        <div className={st.edit_field_wrap}>
          <p className={st.edit_field_title}>Дата производства</p>

          <p className={!inputDetProductdateShow ? st.edit_field_text : st.edit_field_text_hidden}>{
            editDetProductdate !== '' ? editDetProductdate?.split('-').reverse().join('.') : 'Не назначено'
          }</p>

          <div className={st.wrap_p}>
            {
              editDetId === '' ?
                <p className={`${st.edit_field_btn} ${st.red_text}`}>Изменить</p> :
                <p
                  className={!inputDetProductdateShow ? st.edit_field_btn : st.edit_field_btn_hide}
                  onClick={() => setInputDetProductdateShow(true)}
                  disabled
                >Изменить</p>
            }

            <p
              className={inputDetProductdateShow ? st.save_field_btn : st.save_field_btn_hide}
              onClick={() => setInputDetProductdateShow(false)}>Сохранить</p>
          </div>
        </div>

        <div className={inputDetProductdateShow ? st.edit_entry : st.edit_entry_hidden}>
          <label className={st.label_title}>
            <input
              className={st.input_calendar}
              type='date'
              name='editDetProductdate'
              value={editDetProductdate}
              onChange={(e) => setEditDetProductdate(e.target.value)} />

            <p className={editDetProductdate === '' ? st.placeholderedit : st.wrap_title_p_hide}>
              Дата производства
            </p>
          </label>
        </div>
      </div>

      {/* инпут редактирования даты приемки на гарантию детали */}
      <div className={st.edit_input}>
        <div className={st.edit_field_wrap}>
          <p className={st.edit_field_title}>Дата приемки на гарантию</p>

          <p className={!inputDetAcceptwarrantydateShow ? st.edit_field_text : st.edit_field_text_hidden}>{
            editDetAcceptwarrantydate !== '' ? editDetAcceptwarrantydate?.split('-').reverse().join('.') : 'Не назначено'
          }</p>

          <div className={st.wrap_p}>
            {
              editDetId === '' ?
                <p className={`${st.edit_field_btn} ${st.red_text}`}>Изменить</p> :
                <p
                  className={!inputDetAcceptwarrantydateShow ? st.edit_field_btn : st.edit_field_btn_hide}
                  onClick={() => setInputDetAcceptwarrantydateShow(true)}
                  disabled
                >Изменить</p>
            }

            <p
              className={inputDetAcceptwarrantydateShow ? st.save_field_btn : st.save_field_btn_hide}
              onClick={() => setInputDetAcceptwarrantydateShow(false)}>Сохранить</p>
          </div>
        </div>

        <div className={inputDetAcceptwarrantydateShow ? st.edit_entry : st.edit_entry_hidden}>
          <label className={st.label_title}>
            <input
              className={st.input_calendar}
              type='date'
              name='editDetAcceptwarrantydate'
              value={editDetAcceptwarrantydate}
              onChange={(e) => setEditDetAcceptwarrantydate(e.target.value)} />

            <p className={editDetAcceptwarrantydate === '' ? st.placeholderedit : st.wrap_title_p_hide}>
              Дата приемки на гарантию
            </p>
          </label>
        </div>
      </div>

      {/* инпут редактирования даты окончания гарантии детали */}
      <div className={st.edit_input}>
        <div className={st.edit_field_wrap}>
          <p className={st.edit_field_title}>Дата окончания гарантии</p>

          <p className={!inputDetExpiratwarrantydateShow ? st.edit_field_text : st.edit_field_text_hidden}>{
            editDetExpiratwarrantydate !== '' ? editDetExpiratwarrantydate?.split('-').reverse().join('.') : 'Не назначено'
          }</p>

          <div className={st.wrap_p}>
            {
              editDetId === '' ?
                <p className={`${st.edit_field_btn} ${st.red_text}`}>Изменить</p> :
                <p
                  className={!inputDetExpiratwarrantydateShow ? st.edit_field_btn : st.edit_field_btn_hide}
                  onClick={() => setInputDetExpiratwarrantydateShow(true)}
                  disabled
                >Изменить</p>
            }

            <p
              className={inputDetExpiratwarrantydateShow ? st.save_field_btn : st.save_field_btn_hide}
              onClick={() => setInputDetExpiratwarrantydateShow(false)}>Сохранить</p>
          </div>
        </div>

        <div className={inputDetExpiratwarrantydateShow ? st.edit_entry : st.edit_entry_hidden}>
          <label className={st.label_title}>
            <input
              className={st.input_calendar}
              type='date'
              name='editDetExpiratwarrantydate'
              value={editDetExpiratwarrantydate}
              onChange={(e) => setEditDetExpiratwarrantydate(e.target.value)} />

            <p className={editDetExpiratwarrantydate === '' ? st.placeholderedit : st.wrap_title_p_hide}>
              Дата окончания гарантии
            </p>
          </label>
        </div>
      </div>
    </div>
  )
};
