import st from './SlidePreviews.module.css';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as BtnRight } from '../../../../../../img/btn_right.svg';
import { ReactComponent as BtnLeft } from '../../../../../../img/btn_left.svg';
import { Overlay } from 'src/components/Overlay/Overlay';
import { SlideEdit } from '../SlideEdit/SlideEdit';
import store from 'src/store/store.js';
import { fetchSlideDel } from '../../fetches/fetchDel.js';
import { fetchSlidesList } from 'src/store/slides/getSlides';
import { useDispatch } from 'react-redux';
import { fetchSlideReorder } from '../../fetches/fetchReorder';

export const SlidePreviews = ({
  slideID,
  slideLink,
  slideimagelink,
  slideOrder
}) => {
  // для смены порядка слайдов (перебор)
  const slideList = store.getState().slidesListReducer.dataSlidesList.slideList;
  // console.log('slideList.length: ', slideList.length);
  // console.log('slideOrder: ', slideOrder);

  // меню "редактировать-удалить"
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);
  // модальное окно "редактировать"
  const [modalSave, setModalSave] = useState(false);

  // ссылка на меню "редактировать - удалить"
  const refEditOrDel = useRef(null);

  const dispatch = useDispatch();

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  // удаляем слайд, закрываем меню, обновляем список слайдов
  const slideDel = async () => {

    setModalSave(false);

    await fetchSlideDel(slideID);

    dispatch(fetchSlidesList());
  };

  // открывает меню "редактировать удалить"
  const editOrDel = () => {
    setMenuEditOrDel(true);
  };

  // меняем порядок слайдов
  const orderElementChange = async (num) => {
    // данные для запроса смены порядка
    let slideReordersDataBody = {
      slideReorders: [
        {
          "slideID": null,
          "slideOrder": null
        },
        {
          "slideID": null,
          "slideOrder": null
        }
      ]
    };

    await slideList.forEach((item, index) => {
      if (item.slideID === slideID) {
        slideReordersDataBody.slideReorders[0].slideID = slideList[index].slideID;
        slideReordersDataBody.slideReorders[1].slideID = slideList[index + num].slideID;
        slideReordersDataBody.slideReorders[0].slideOrder = slideList[index + num].slideOrder;
        slideReordersDataBody.slideReorders[1].slideOrder = slideList[index].slideOrder;
      }
    });

    await fetchSlideReorder(slideReordersDataBody);
    dispatch(fetchSlidesList());
  };

  return (
    <>
      <li className={st.slide_previews_item}>
        <ul
          className={!menuEditOrDel ? st.menu_list : st.menu_list_show}
          ref={refEditOrDel}
        >
          <li className={st.menu_item} onClick={() => setModalSave(true)}>Редактировать</li>
          <li className={st.menu_item} onClick={slideDel}>Удалить</li>
        </ul>

        <div className={st.num_and_btn}>
          <h4>{slideOrder < 10 ? `0${slideOrder}` : slideOrder}</h4>
          <button className={st.btn_menu} onClick={editOrDel}></button>
        </div>

        <img id={slideID} className={st.img} src={slideimagelink} alt='' />

        <div className={st.btns_change_wrap}>
          <div className={st.btns_change}>
            <button
              className={st.change_prev}
              onClick={() => orderElementChange(-1)}
              // onClick={orderElementsToLeft}
              disabled={slideOrder === 1}
            ><BtnLeft /></button>
            <button
              className={st.change_next}
              onClick={() => orderElementChange(1)}
              // onClick={orderElementsToRight}
              disabled={slideOrder === slideList.length ? true : false}
            ><BtnRight /></button>
          </div>
        </div>
      </li>

      <Overlay className={!modalSave ? st.overlay_hide : st.overlay_show}>
        <SlideEdit
          modalClose={() => setModalSave(false)}
          slideID={slideID}
          slideLink={slideLink}
          slideimagelink={slideimagelink}
          slideOrder={slideOrder}
        />
      </Overlay>
    </>
  )
};
