import { SERVICECENTERS_ADD } from "src/consts";
import { updateTokens } from "src/utils/updateTokens";

// одинарный запрос: на добавление всего кроме медиафайл
export const fetchEditWithoutImgServiceCenter = async (bodyData) => {
  bodyData.uidlogo = '';

  // запрос на добавление всего кроме самого медиафайл
  const responseservcenteradd = await fetch(SERVICECENTERS_ADD, {
    method: 'POST',
    body: JSON.stringify(bodyData),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'User-Agent': 'LiftupSite/1.0.0',
      'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
    },
  });

  if (responseservcenteradd.status === 200) {
    const dataservcenteradd = await responseservcenteradd.json();

    console.log('dataservcenteradd: ', dataservcenteradd);

    console.log('данные на добавление всего, кроме лого, отправлены без перезаписи токенов');
  }

  // в случае протухания токена обновляем пару "token - rtoken"
  // else if (responseservcenteradd.status === 401 || await responseservcenteradd.json().loginstatus === 7) {
  else if (responseservcenteradd.status === 401) {
    // перезапись токенов
    await updateTokens();

    bodyData.uidlogo = '';

    // запрос на добавление всего кроме самого медиафайл
    const responseservcenteradd = await fetch(SERVICECENTERS_ADD, {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'User-Agent': 'LiftupSite/1.0.0',
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      },
    });

    if (responseservcenteradd.status === 200) {
      const dataservcenteradd = await responseservcenteradd.json();
      console.log('dataservcenteradd: ', dataservcenteradd);
    } else {
      console.error('произошла ошибка, статус ответа сервера: ', responseservcenteradd.status);
    }

    console.log('данные на добавление всего, кроме лого, отправлены c перезаписью токенов');

  } else {

    console.error('произошла ошибка, статус ответа сервера: ', responseservcenteradd.status);
    console.log('данные на добавление всего, кроме лого, отправлены без перезаписи токенов');

  }
};
