import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ORG_LIST_FULL } from "src/consts.js";
import { updateTokens } from "src/utils/updateTokens";

// получаем список наших клиентов
export const fetchClientbaseList = createAsyncThunk(
  'clientbaseList/fetchClientbaseList',

  async () => {
    const res = await fetch(ORG_LIST_FULL, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF8',
        'User-Agent': 'LiftupSite/1.0.0',
        // заголовок с токеном для проверки
        'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
      }
    }
    );

    const data = await res.json();

    // в случае протухания токена обновляем пару "token - rtoken"
    if (res.status === 401 || data.loginstatus === 7) {
      // перезапись токенов
      await updateTokens();

      const res = await fetch(ORG_LIST_FULL, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF8',
          'User-Agent': 'LiftupSite/1.0.0',
          // заголовок с токеном для проверки
          'Authorization': `Bearer ${localStorage.getItem('tokenlu')}`,
        }
      }
      );
      const data = await res.json();
      return data;
    }

    return data;
  }
);

const initialState = {
  status: 'idle',
  dataClientbaseList: {},
  error: null,
};

const clientbaseListSlice = createSlice({
  name: 'clientbaseList',
  initialState,
  reducers: {
    removeClientbaseList: (state) => {
      state.dataClientbaseList = {};
    }
  },

  extraReducers: (builder) => {
    builder
      // при загрузке сайта
      .addCase(fetchClientbaseList.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchClientbaseList.fulfilled, (state, action) => {
        state.status = 'success';
        state.dataClientbaseList = action.payload;
      })
      .addCase(fetchClientbaseList.rejected, (state, action) => {
        state.status = 'failed';
        state.dataClientbaseList = action.error.message;
      })
  }
});


export const { removeClientbaseList } = clientbaseListSlice.actions;

export default clientbaseListSlice.reducer;
