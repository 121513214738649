import st from './Header.module.css';
import logo from '../../img/logo.svg'
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const Header = () => {
  // модальное окно с главным меню в моб версии
  // const [menu, setMenu] = useState(false);

  // переключатель ссылки кнопки меню "личный кабинет", "администратор"
  const [switchAuthorBtn, setSwitchAuthorBtn] = useState('/signin/author');

  // закр мод меню
  // const closeMobMenu = () => setMenu(false);

  // присваиваем класс пункту меню NavLink
  const setElemActive = ({ isActive }) => isActive
    ? `${st.navlink_active}`
    : `${st.navlink}`;

  // в зависимости от значения role присваиваются различные права
  const userroles = useSelector(store => store.authReducer.dataAuth.user?.userroles) || null;
  // console.log('role: ', userroles);

  // для вставки в адресную ссылку
  const id = useSelector(store => store.authReducer.dataAuth.organization?.id) || null;
  // console.log('id: ', id);


  useEffect(() => {
    // переключает ссылку в зависимости от авторизованности
    const redirectLink = () => {
      if (userroles === 999) {
        setSwitchAuthorBtn('/superlift/main');
      }

      if (userroles === 1) {
        setSwitchAuthorBtn(`/lk/${id}/aboutcompany`);
      }

      if (userroles === null) {
        setSwitchAuthorBtn('/signin/author');
      }
    };

    redirectLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userroles]);






  const targetUrl = 'http://localhost:3006';
  const transitionUrl = window.location.host;
  // const rootUrl = 'http://shop.liftup.pro';
  const oneTimeSK = localStorage.getItem('otk');
  // console.log('oneTimeSK: ', oneTimeSK);
  const urlWithParam = `${targetUrl}?fromurl=${transitionUrl}&param=${oneTimeSK}`;







  return (
    <header className={st.header}>
      <div className={`${st.header_container} container`}>
        <Link to={'/'}>
          <img src={logo} alt='Логотип компании' className={st.logo} />
        </Link>

        <div className={st.wrap_mob}>
          <ul className={st.menu_list}>
            <li className={`${st.menu_item} ${st.menu_item_mob}`}>
              <NavLink className={st.menu_link} to='/'>На&nbsp;главную</NavLink>
              <div className={st.item_hover}></div>
            </li>

            <li className={st.menu_item}>
              <NavLink className={setElemActive} to='/about'>
                О&nbsp;компании
              </NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink
                className={setElemActive} to='/news/newslists'>
                Новости
              </NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={setElemActive} to='/documents'>
                Документы
              </NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={setElemActive} to='/analytics'>
                Аналитика
              </NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={setElemActive} to='/clients'>
                Клиенты
              </NavLink>
              <div className={st.item_hover}></div>
            </li>
            <li className={st.menu_item}>
              <NavLink className={setElemActive} to='/servicecenters'>
                Сервисные&nbsp;центры
              </NavLink>
              <div className={st.item_hover}></div>
            </li>

            {/* <li className={st.menu_item}>
              <NavLink
                className={setElemActive}
                to={urlWithParam}
                target='_blank'
                onClick={() => { }}
              >
                Shop
              </NavLink>
              <div className={st.item_hover}></div>
            </li> */}

            {/* это маленькие кнопки в личный кабинет и панель админа для удобства тестирования - ракоментировать в случае надобности */}
            {/* <li className={st.menu_item_temp}>
              <NavLink className={st.temp_link} to={`/lk/${id}/aboutcompany`} >
                LK
              </NavLink>
              <NavLink className={st.temp_link} to='/superlift/main' >
                AP
              </NavLink>
            </li> */}
          </ul>
        </div>

        <div className={st.wrap_btn}>
          <NavLink
            className={st.btn_pers_account} to={switchAuthorBtn}>
            {userroles === 999 ? 'Администратор' : 'Личный кабинет'}
          </NavLink>
        </div>
      </div>
    </header >
  )
};
