import st from './DocsPreview.module.css';
import { useEffect, useRef, useState } from 'react';
import { Overlay } from 'src/components/Overlay/Overlay';
import { DocsEdit } from '../DocsEdit/DocsEdit.jsx';
import { fetchDocDel } from '../../fetches/fetchDel.js';
import { useDispatch } from 'react-redux';
import { fetchDocsList } from 'src/store/docs/getDocs';

export const DocsPreview = ({
  documentuid,
  documentname,
  documentlink,
  documentordernum
}) => {
  // модальное окно "редактировать"
  const [modalEdit, setModalEdit] = useState(false);

  // ссылка на меню "редактировать - удалить"
  const refEditOrDel = useRef(null);

  // показывает меню "редактировать - удалить"
  const [menuEditOrDel, setMenuEditOrDel] = useState(false);

  const dispatch = useDispatch();

  // закрываем меню по клику вне элемента
  useEffect(() => {
    let handler = (e) => {
      if (!refEditOrDel.current.contains(e.target)) {
        setMenuEditOrDel(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  // удаляем документ, закрываем меню, обновляем список документов
  const docDel = async () => {

    setModalEdit(false);

    await fetchDocDel(documentuid);

    dispatch(fetchDocsList());
  };

  // показывает меню "редактировать удалить"
  const editOrDel = () => {
    setMenuEditOrDel(true);
  };

  return (
    <>
      <li className={st.docs_item} id={documentuid}>
        <p className={st.item_date}>{documentordernum}</p>

        <h4 className={st.news_title}>{documentname}</h4>

        <div className={st.item_edit_del} ref={refEditOrDel}>
          <div className={st.item_btn} onClick={editOrDel}></div>

          <ul
            className={!menuEditOrDel ? st.btn_menu : st.btn_menu_show}>
            <li
              className={st.btn_menu_item}
              onClick={() => setModalEdit(true)}
            >Редактировать</li>
            <li
              className={st.btn_menu_item}
              onClick={docDel}
            >Удалить</li>
          </ul>
        </div>
      </li>

      <Overlay className={!modalEdit ? st.overlay_hide : st.overlay_show}>
        <DocsEdit
          closeModal={() => setModalEdit(false)}
          failName={documentlink?.substring(documentlink.lastIndexOf('/') + 1)}
          documentuid={documentuid}
          documentname={documentname}
          documentordernum={documentordernum}
        />
      </Overlay>
    </>
  )
};
