export const slide_list = {
  "status": true,
  "message": "processed successfully",
  "hashvalue": "string",
  "debugMessage": "string",
  "slideList":
    [
      {
        "slideID": 'lu32',
        // "slideLink": "assets/img/slides/slide_hero1.svg",
        "slideLink": "https://raw.githubusercontent.com/DmitrY66/midas_token/ce7e1ee900224ccf60eb52a0f5c50ed664bc3e96/img/hero_slide1.svg",
        "slideType": 1,
        "slideOrder": 3
      },
      {
        "slideID": 'lu67',
        // "slideLink": "assets/img/slides/slide_hero2.svg",
        "slideLink": "https://raw.githubusercontent.com/DmitrY66/midas_token/ce7e1ee900224ccf60eb52a0f5c50ed664bc3e96/img/hero_slide2.svg",
        "slideType": 1,
        "slideOrder": 2
      }
    ]
}
