import st from './Home.module.css';
import { SectionHero } from 'src/modules/SectionHero/SectionHero.jsx';
import { SectionWarranty } from 'src/modules/SectionWarranty/SectionWarranty.jsx';
import { SectionBrands } from 'src/modules/SectionBrands/SectionBrands.jsx';
import { SectionExtendLife } from 'src/modules/SectionExtendLife/SectionExtendLife.jsx';
import { SectionNews } from 'src/modules/SectionNews/SectionNews.jsx';
import { SectionClients } from 'src/modules/SectionClients/SectionClients.jsx';
import { SectionConsultation } from 'src/modules/SectionConsultation/SectionConsultation.jsx';
import { Helmet } from 'react-helmet-async';

export const Home = () => {

  return (
    <>
      <Helmet>
        <title>Техническая гарантия на лифты 25 лет | LIFTUP</title>
        <meta
          name="description"
          content="Предоставляем гарантию на ключевые компоненты лифтов ведущих брендов. Защитим вас от неожиданных расходов на ремонт и гарантируем быстрое обслуживание в случае поломки."
        />
      </Helmet>

      <main className={st.main}>
        <SectionHero />
        <SectionWarranty />
        <SectionBrands />
        <SectionExtendLife />
        <SectionNews />
        <SectionClients />
        <SectionConsultation />
      </main>
    </>
  )
};
