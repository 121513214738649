import st from './MobMenu.module.css';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const MobMenu = () => {
  // модальное окно с главным меню в моб версии
  const [menu, setMenu] = useState(false);

  // переключатель ссылки кнопки меню "личный кабинет", "администратор"
  const [switchAuthorBtn, setSwitchAuthorBtn] = useState('/signin/author');

  // закр мод меню
  const closeMobMenu = () => setMenu(false);

  // присваиваем класс пункту меню NavLink
  const setElemActive = ({ isActive }) => isActive
    ? `${st.navlink_active}`
    : `${st.navlink}`;

  // в зависимости от значения role присваиваются различные права
  const userroles = useSelector(store => store.authReducer.dataAuth.user?.userroles) || null;

  // для вставки в адресную ссылку
  const id = useSelector(store => store.authReducer.dataAuth.organization?.id) || null;

  useEffect(() => {
    // переключает ссылку в зависимости от авторизованности
    const redirectLink = () => {
      if (userroles === 999) {
        setSwitchAuthorBtn('/superlift/main');
      }

      if (userroles === 1) {
        setSwitchAuthorBtn(`/lk/${id}/aboutcompany`);
      }

      if (userroles === null) {
        setSwitchAuthorBtn('/signin/author');
      }
    };

    redirectLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userroles]);


  return (
    <div className={st.mobmenu}>
      <div
        className={st.btn_open_mobmenu}
        onClick={() => setMenu(true)}
      ></div>

      <div className={menu ? st.wrap_mob_open : st.wrap_mob}>
        <div
          className={st.close}
          onClick={closeMobMenu}
        ></div>

        <ul className={st.menu_list}>
          <li className={`${st.menu_item} ${st.menu_item_mob}`}>
            <NavLink className={st.menu_link} to='/' onClick={closeMobMenu}>На&nbsp;главную</NavLink>
            <div className={st.item_hover}></div>
          </li>


          <li className={st.menu_item_inner}>
            <ul className={st.menu_list_inner}>
              <li className={st.menu_item}>
                <NavLink className={setElemActive} to='/about' onClick={closeMobMenu}>
                  О&nbsp;компании
                </NavLink>
                <div className={st.item_hover}></div>
              </li>
              <li className={st.menu_item}>
                <NavLink
                  className={setElemActive} to='/news/newslists' onClick={closeMobMenu}>
                  Новости
                </NavLink>
                <div className={st.item_hover}></div>
              </li>
              <li className={st.menu_item}>
                <NavLink className={setElemActive} to='/documents' onClick={closeMobMenu}>
                  Документы
                </NavLink>
                <div className={st.item_hover}></div>
              </li>
              <li className={st.menu_item}>
                <NavLink className={setElemActive} to='/analytics' onClick={closeMobMenu}>
                  Аналитика
                </NavLink>
                <div className={st.item_hover}></div>
              </li>
              <li className={st.menu_item}>
                <NavLink className={setElemActive} to='/clients' onClick={closeMobMenu}>
                  Клиенты
                </NavLink>
                <div className={st.item_hover}></div>
              </li>
              <li className={st.menu_item}>
                <NavLink className={setElemActive} to='/servicecenters' onClick={closeMobMenu}>
                  Сервисные&nbsp;центры
                </NavLink>
                <div className={st.item_hover}></div>
              </li>

              {/* это маленькие кнопки в личный кабинет и панель админа для удобства тестирования раcкоментировать в случае надобности*/}
              {/* <li className={st.menu_item_temp}>
              <NavLink className={st.temp_link} to={`/lk/${id}/aboutcompany`} >
                LK
              </NavLink>
              <NavLink className={st.temp_link} to='/superlift/main' >
                AP
              </NavLink>
            </li> */}
            </ul>
          </li>

          <li className={st.menu_item}>
            <NavLink className={st.btn_pers_account_mob}
              to={switchAuthorBtn}
              onClick={closeMobMenu}>
              {userroles === 999 ? 'Администратор' : 'Личный кабинет'}
            </NavLink>
          </li>
        </ul>

        <div className={st.copyright}>
          <p>© LIFTUP 2023 - Все права защищены<br />
            <Link to='/' onClick={closeMobMenu}> Политика&nbsp;конфиденциальности</Link>
          </p>
        </div>
      </div>
    </div>
  )
};
