// получаем конкретную куку
const getCookie = () => {
  return document.cookie.split('; ').reduce((acc, item) => {
    const [name, value] = item.split('=');
    acc[name] = value;
    return acc;
  }, {})
};

export default getCookie;
