import st from './Fias2.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { handleInputChangeStop } from 'src/utils/handleInputChange';
import { fetchGetDataFias, fetchGetRegionFias } from 'src/fetch/fetchFIAS';
import { indexСhange, removeFocuse, searchInArrayElement, setElem } from '../utils';

export const Fias2 = ({ setFiasDataObj2, dataForFields2 }) => {
  // пременные для упрощения написания вызова функции "indexСhange"
  const typeOfDistrictStatic = dataForFields2?.hierarchy[1]?.full_name_short;
  // эта при клике по элементу выбора муниципального района
  const [typeOfDistrictDinamic, setTypeOfDistrictDinamic] = useState('');

  // паттерны валидации
  const patternPostalIndex = /^(\d){0,8}$/g;
  const patternNameCity = /^[а-яА-Я- .]*$/;

  // данные для запроса в ФИАС на получение РБ > Муниципальный район/округ 
  // const [requestObjectMunicipalAreas2, setRequestObjectMunicipalAreas2] = useState({ "address_type": 2, "path": "5705" });
  const [requestObjectMunicipalAreas2, setRequestObjectMunicipalAreas2] = useState({});
  const [requestObjectRuralUrbanSettlement2, setRequestObjectRuralUrbanSettlement2] = useState({});
  const [requestObjectPopulatedPoint2, setRequestObjectPopulatedPoint2] = useState({});
  const [requestObjectStrit2, setRequestObjectStrit2] = useState({});
  const [requestObjectHouseNumber2, setRequestObjectHouseNumber2] = useState({});
  const [requestObjectApartmentNumber2, setRequestObjectApartmentNumber2] = useState({});

  // это поле для того чтобы отображать либо населенный пункт, при выборе "г.о." 
  // либо сельское/городское поселение (следующее за этим, поле), при выборе "м. р-н"
  // а также для корректного отображения данных всех нижних элементов 
  const [operationIdMunicipalAreas2, setOperationIdMunicipalAreas2] = useState(null);

  // ======== выбор субъекта РФ района
  // ввод в инпут поиска субъекта РФ
  const [subjectRF2, setSubjectRF2] = useState(
    dataForFields2?.hierarchy[0]?.full_name_short ?
      dataForFields2.hierarchy[0]?.full_name_short : ''
  );

  // выбранные из списка данные для отправки на сервер
  const [subjectRFToSend2, setSubjectRFToSend2] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [subjectRFArr2, setSubjectRFArr2] = useState([]);
  const [dataSubjectRFArr2, setDataSubjectRFArr2] = useState([]);

  // ======== выбор муниципального района
  // ввод в инпут поиска муниципального района
  const [munAreas2, setMunAreas2] = useState(
    dataForFields2?.hierarchy[1]?.full_name_short ?
      dataForFields2.hierarchy[1]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [munAreasToSend2, setMunAreasToSend2] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [municipalAreasArr2, setMunicipalAreasArr2] = useState([]);
  const [dataMunicipalAreasArr2, setDataMunicipalAreasArr2] = useState([]);

  // ======== выбор сельское/городское поселение при выбранном "м. р-н"
  // ввод в инпут поиска
  const [ruralUrbanSettlement2, setRuralUrbanSettlement2] = useState(
    dataForFields2?.hierarchy[2]?.full_name_short ?
      dataForFields2.hierarchy[2]?.full_name_short : 'нет данных'
  );
  // выбранные из списка данные для отправки на сервер
  const [ruralUrbanSettlementToSend2, setRuralUrbanSettlementToSend2] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [ruralUrbanSettlementArr2, setRuralUrbanSettlementArr2] = useState([]);
  const [dataRuralUrbanSettlementArr2, setDataRuralUrbanSettlementArr2] = useState([]);

  // ======== выбор населенного пункта г.о.
  // ввод в инпут поиска населенного пункта
  const [popPoint2, setPopPoint2] = useState(
    dataForFields2?.hierarchy[indexСhange(typeOfDistrictStatic, 2, 3)]?.full_name_short ?
      dataForFields2.hierarchy[indexСhange(typeOfDistrictStatic, 2, 3)]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [popPointToSend2, setPopPointToSend2] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [populatedPointArr2, setPopulatedPointArr2] = useState([]);
  const [dataPopulatedPointArr2, setDataPopulatedPointArr2] = useState([]);

  // ======== выбор улицы
  // ввод в инпут поиска улицы
  const [strit2, setStrit2] = useState(
    dataForFields2?.hierarchy[indexСhange(typeOfDistrictStatic, 3, 4)]?.full_name_short ?
      dataForFields2.hierarchy[indexСhange(typeOfDistrictStatic, 3, 4)]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [stritToSend2, setStritToSend2] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [stritArr2, setStritArr2] = useState([]);
  const [dataStritArr2, setDataStritArr2] = useState([]);

  // ======== выбор номера дома
  // ввод в инпут поиска номера дома
  const [houseNumber2, setHouseNumber2] = useState(
    dataForFields2?.hierarchy[indexСhange(typeOfDistrictStatic, 4, 5)]?.full_name_short ?
      dataForFields2.hierarchy[indexСhange(typeOfDistrictStatic, 4, 5)]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [houseNumberToSend2, setHouseNumberToSend2] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [houseNumberArr2, setHouseNumberArr2] = useState([]);
  const [dataHouseNumberArr2, setDataHouseNumberArr2] = useState([]);

  // ======== выбор помещения
  // ввод в инпут поиска помещения
  const [apartmentNumber2, setApartmentNumber2] = useState(
    dataForFields2?.hierarchy[indexСhange(typeOfDistrictStatic, 5, 6)]?.full_name_short ?
      dataForFields2.hierarchy[indexСhange(typeOfDistrictStatic, 5, 6)]?.full_name_short : ''
  );
  // выбранные из списка данные для отправки на сервер
  const [apartmentNumberToSend2, setApartmentNumberToSend2] = useState('');

  // две нижние строки идут в паре с одинаковыми данными - первые - изменяемые, вторые - нет
  const [apartmentNumberArr2, setApartmentNumberArr2] = useState([]);
  const [dataApartmentNumberArr2, setDataApartmentNumberArr2] = useState([]);

  // тип адреса
  const addresstype2 = 'FIZ';

  // это guids всех уровней иерархии, для добавления в отправляемые данные
  const [guids2, setGuids2] = useState(
    {
      guid0subjectrf: '',
      guid1municipalareas: '',
      guid2ruralurbansettlement: '',
      guid23populatedpoint: '',
      guid34street: '',
      guid45housenumber: '',
      guid56apartmentnumber: ''
    }
  );

  // при первой загрузке страницы в зависимости от значения в условии присваиваем значение  переменной в зависимости от которой показывается или скрывается инпут "сельское/городское поселение"
  useEffect(() => {
    if (dataForFields2?.hierarchy[1]?.full_name_short.startsWith('г')) setOperationIdMunicipalAreas2(0);
    if (dataForFields2?.hierarchy[1]?.full_name_short.startsWith('м')) setOperationIdMunicipalAreas2(20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // помещаем и обновляем новый массив guids2 в объект данных для отправки
  useEffect(() => {
    setFiasDataObj2(prevState => ({ ...prevState, guids: guids2 }));
    setFiasDataObj2(prevState => ({ ...prevState, addresstype: addresstype2 }));
    setFiasDataObj2(prevState => ({ ...prevState, operation_id_municipal_areas: operationIdMunicipalAreas2 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guids2]);


  return (
    <div className={st.fias_address_group}>
      {/* инпут субъект РФ */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={subjectRF2 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор субъекта РФ</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='subjectRF2'
            value={subjectRF2}
            onClick={() => {
              // отправляем запрос на получение регионов
              fetchGetRegionFias(setDataSubjectRFArr2, setSubjectRFArr2)
            }}
            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternNameCity, setSubjectRF2);
              // поиск по списку данных
              searchInArrayElement(e, dataSubjectRFArr2, 0, setSubjectRFArr2);
              // очистка поля для отправки
              setSubjectRFToSend2('');
            }}
            placeholder='Выберите субъекта РФ'
          // readOnly
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            // здесь и ниже onMouseDown используется вместо onClick потому что onClick в хроме не срабатывает
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setSubjectRF2('');
              setSubjectRFToSend2('');

              // восстанавливаем список данных после поиска
              setSubjectRFArr2(dataSubjectRFArr2);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectMunicipalAreas2({});
              setRequestObjectPopulatedPoint2({});
              setRequestObjectRuralUrbanSettlement2({});
              setRequestObjectStrit2({});
              setRequestObjectHouseNumber2({});
              setRequestObjectApartmentNumber2({});

              // очищаем поля вниз по иерархии
              // ======================
              setMunicipalAreasArr2([]);
              setMunAreas2('');
              // ======================
              setPopulatedPointArr2([]);
              setPopPoint2('');
              // ======================
              setRuralUrbanSettlementArr2([]);
              setRuralUrbanSettlement2('');
              // ======================
              setStritArr2([]);
              setStrit2('');
              // ======================
              setHouseNumberArr2([]);
              setHouseNumber2('');
              // ======================
              setApartmentNumberArr2([]);
              setApartmentNumber2('');
              // ======================
              setFiasDataObj2({});
              // ======================

              // очистка guids
              setGuids2(
                {
                  guid0subjectrf: '',
                  guid1municipalareas: '',
                  guid2ruralurbansettlement: '',
                  guid23populatedpoint: '',
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              );
            }}
          >
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              subjectRFArr2 ? subjectRFArr2.map(item => (
                // временное условие пока работает только Башкортостан
                item.hierarchy[0].full_name_short === "Республика Башкортостан" ?
                  <li
                    className={st.item_search}
                    key={uuidv4()}
                    // здесь и ниже onMouseDown используется вместо onClick потому что onClick в хроме не срабатывает
                    onMouseDown={(e) => {
                      // получаем выбранное значение в инпуте и в поле для отправки
                      setElem(e, setSubjectRF2);
                      setElem(e, setSubjectRFToSend2);

                      // помещаем сюда данные для отправки
                      setFiasDataObj2(item);

                      // GUIDS
                      // предварительная очистка нижних по иерархии
                      setGuids2(
                        {
                          guid1municipalareas: '',
                          guid2ruralurbansettlement: '',
                          guid23populatedpoint: '',
                          guid34street: '',
                          guid45housenumber: '',
                          guid56apartmentnumber: ''
                        }
                      );

                      // GUIDS
                      // помещаем item.object_guid в новый массив guids
                      setGuids2(prevState => ({ ...prevState, guid0subjectrf: item.object_guid }));

                      // восстанавливаем список данных после поиска
                      setSubjectRFArr2(dataSubjectRFArr2);

                      // формируем объект для тела запроса данных
                      setRequestObjectMunicipalAreas2({ "address_type": 2, "path": `${item.path}` });

                      // очищаем поля вниз по иерархии
                      // ======================
                      setMunicipalAreasArr2([]);
                      setMunAreas2('');
                      // ======================
                      setPopulatedPointArr2([]);
                      setPopPoint2('');
                      // ======================
                      setRuralUrbanSettlementArr2([]);
                      setRuralUrbanSettlement2('');
                      // ======================
                      setStritArr2([]);
                      setStrit2('');
                      // ======================
                      setHouseNumberArr2([]);
                      setHouseNumber2('');
                      // ======================
                      setApartmentNumberArr2([]);
                      setApartmentNumber2('');
                      // ======================

                      removeFocuse();
                    }}
                  >
                    {item.hierarchy ? item.hierarchy[0].full_name_short : 'нет данных'}
                  </li> : null
              )) : <li className={st.item_search} key={uuidv4()}>ошибка получения данных</li>
            }
          </ul>
        </div>
      </div>

      {/* инпут муниципального района/округа (м.р-н или г.о.) */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={munAreas2 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор муниципального района</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='munAreas2'
            value={munAreas2}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectMunicipalAreas2).length)
                fetchGetDataFias(requestObjectMunicipalAreas2, setDataMunicipalAreasArr2, setMunicipalAreasArr2)
            }}
            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternNameCity, setMunAreas2);
              // поиск по списку данных
              searchInArrayElement(e, dataMunicipalAreasArr2, 1, setMunicipalAreasArr2);
              // очистка поля для отправки
              setMunAreasToSend2('');
            }}
            placeholder='Введите название района'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setMunAreas2('');
              setMunAreasToSend2('');

              // восстанавливаем список данных после поиска
              setMunicipalAreasArr2(dataMunicipalAreasArr2);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectRuralUrbanSettlement2({});
              setRequestObjectPopulatedPoint2({});
              setRequestObjectStrit2({});
              setRequestObjectHouseNumber2({});
              setRequestObjectApartmentNumber2({});

              // очищаем поля вниз по иерархии
              // ======================
              setPopulatedPointArr2([]);
              setPopPoint2('');
              // ======================
              setRuralUrbanSettlementArr2([]);
              setRuralUrbanSettlement2('');
              // ======================
              setStritArr2([]);
              setStrit2('');
              // ======================
              setHouseNumberArr2([]);
              setHouseNumber2('');
              // ======================
              setApartmentNumberArr2([]);
              setApartmentNumber2('');
              // ======================
              setFiasDataObj2({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids2(prevState => (
                {
                  ...prevState,
                  guid1municipalareas: '',
                  guid2ruralurbansettlement: '',
                  guid23populatedpoint: '',
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              municipalAreasArr2 ? municipalAreasArr2.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // меняем значение динамической строки при выборе элемента (нужно для использования в поиске значений при наборе)
                    setTypeOfDistrictDinamic(item.hierarchy[1]?.full_name_short);

                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setMunAreas2);
                    setElem(e, setMunAreasToSend2);

                    // помещаем сюда данные для отправки
                    setFiasDataObj2(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids2(prevState => (
                      {
                        ...prevState,
                        guid2ruralurbansettlement: '',
                        guid23populatedpoint: '',
                        guid34street: '',
                        guid45housenumber: '',
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids2(prevState => ({ ...prevState, guid1municipalareas: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setMunicipalAreasArr2(dataMunicipalAreasArr2);

                    // формируем объект для тела запроса данных
                    // как для запроса из насаленный пункт 
                    setRequestObjectPopulatedPoint2({ "address_type": 2, "path": `${item.path}` });
                    // так и для запроса из сельское/городское поселение
                    setRequestObjectRuralUrbanSettlement2({ "address_type": 2, "path": `${item.path}` });

                    // OperationId для отображения либо населенный пункт, либо сельское/городское поселение
                    setOperationIdMunicipalAreas2(item.operation_type_id);

                    // очищаем поля параметров запрсов вниз по иерархии
                    // setRequestObjectRuralUrbanSettlement2({ });
                    // setRequestObjectPopulatedPoint2({ });
                    setRequestObjectStrit2({});
                    setRequestObjectHouseNumber2({});
                    setRequestObjectApartmentNumber2({});
                    // ======================

                    // очищаем поля вниз по иерархии
                    setPopulatedPointArr2([]);
                    setPopPoint2('');
                    // ======================
                    setRuralUrbanSettlementArr2([]);
                    setRuralUrbanSettlement2('');
                    // ======================
                    setStritArr2([]);
                    setStrit2('');
                    // ======================
                    setHouseNumberArr2([]);
                    setHouseNumber2('');
                    // ======================
                    setApartmentNumberArr2([]);
                    setApartmentNumber2('');
                    // ======================

                    removeFocuse();
                  }}>
                  {item.hierarchy[1] ? item.hierarchy[1].full_name_short : 'нет данных'}
                </li>
              ) : <li
                className={st.item_search}
                key={uuidv4()}>ошибка получения данных</li>
            }
          </ul>
        </div>
      </div>

      {/* инпут сельское/городское поселение показываем при выборе м.р-н */}
      {
        operationIdMunicipalAreas2 === 20 ?
          <div className={`${st.wrap_title} ${st.mb30} ${st.pop_point1}`}>
            <p className={ruralUrbanSettlement2 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
              Выбор сельское/городское поселение</p>

            <div className={st.input_search_wrap}>
              <input
                className={st.input_search}
                type='text'
                name='ruralUrbanSettlement2'
                value={ruralUrbanSettlement2}
                onClick={() => {
                  // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
                  if (Object.keys(requestObjectRuralUrbanSettlement2).length)
                    fetchGetDataFias(requestObjectRuralUrbanSettlement2, setDataRuralUrbanSettlementArr2, setRuralUrbanSettlementArr2)
                }}
                onChange={(e) => {
                  // запрет ввода нетого
                  handleInputChangeStop(e, patternNameCity, setRuralUrbanSettlement2);
                  // поиск по списку данных
                  searchInArrayElement(e, dataRuralUrbanSettlementArr2, 2, setRuralUrbanSettlementArr2);
                  // очистка поля для отправки
                  setPopPointToSend2('');
                }}
                placeholder='Введите название поселения'
              />

              <div
                className={st.input_reset}
                key={uuidv4()}
                onMouseDown={() => {
                  // очищаем текущие поля инпута и отпраки
                  setRuralUrbanSettlement2('');
                  setRuralUrbanSettlementToSend2('');

                  // восстанавливаем список данных после поиска
                  setRuralUrbanSettlementArr2(dataRuralUrbanSettlementArr2);

                  // очищаем поля параметров запрсов вниз по иерархии
                  setRequestObjectPopulatedPoint2({});
                  setRequestObjectStrit2({});
                  setRequestObjectHouseNumber2({});
                  setRequestObjectApartmentNumber2({});
                  // ======================

                  // очищаем поля вниз по иерархии
                  setPopulatedPointArr2([]);
                  setPopPoint2('');
                  // ======================
                  setStritArr2([]);
                  setStrit2('');
                  // ======================
                  setHouseNumberArr2([]);
                  setHouseNumber2('');
                  // ======================
                  setApartmentNumberArr2([]);
                  setApartmentNumber2('');
                  // ======================
                  setFiasDataObj2({});
                  // ======================

                  // очистка всех guids кроме верхних по иерархии
                  setGuids2(prevState => (
                    {
                      ...prevState,
                      guid2ruralurbansettlement: '',
                      guid23populatedpoint: '',
                      guid34street: '',
                      guid45housenumber: '',
                      guid56apartmentnumber: ''
                    }
                  ));
                }}>
                Сброс ввода
              </div>

              <ul className={st.list_search}>
                {
                  ruralUrbanSettlementArr2.map(item =>
                    <li
                      className={st.item_search}
                      key={uuidv4()}
                      onMouseDown={(e) => {
                        // получаем выбранное значение в инпуте и в поле для отправки
                        setElem(e, setRuralUrbanSettlement2);
                        setElem(e, setRuralUrbanSettlementToSend2);

                        // помещаем сюда данные для отправки
                        setFiasDataObj2(item);

                        // GUIDS
                        // предварительная очистка нижних по иерархии
                        setGuids2(prevState => (
                          {
                            ...prevState,
                            guid23populatedpoint: '',
                            guid34street: '',
                            guid45housenumber: '',
                            guid56apartmentnumber: ''
                          }
                        ));

                        // GUIDS
                        // помещаем item.object_guid в новый массив guids
                        setGuids2(prevState => ({ ...prevState, guid2ruralurbansettlement: item.object_guid }));

                        // восстанавливаем список данных после поиска
                        setRuralUrbanSettlementArr2(dataRuralUrbanSettlementArr2);

                        // формируем объект для тела запроса данных
                        setRequestObjectPopulatedPoint2({ "address_type": 2, "path": `${item.path}` });

                        // очищаем поля параметров запрсов вниз по иерархии
                        setRequestObjectStrit2({});
                        setRequestObjectHouseNumber2({});
                        setRequestObjectApartmentNumber2({});
                        // ======================

                        // очищаем поля вниз по иерархии
                        setPopulatedPointArr2([]);
                        setPopPoint2('');
                        // ======================
                        setStritArr2([]);
                        setStrit2('');
                        // ======================
                        setHouseNumberArr2([]);
                        setHouseNumber2('');
                        // ======================
                        setApartmentNumberArr2([]);
                        setApartmentNumber2('');
                        // ======================

                        removeFocuse();
                      }}>
                      {item.hierarchy ? item.hierarchy[2].full_name_short : 'нет данных'}
                    </li>
                  )
                }
              </ul>
            </div>
          </div> : null
      }

      {/* инпут населенного пункта г.о. или м.р-н */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={popPoint2 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор населенного пункта м.р-н</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='popPoint2'
            value={popPoint2}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectPopulatedPoint2).length)
                fetchGetDataFias(requestObjectPopulatedPoint2, setDataPopulatedPointArr2, setPopulatedPointArr2)
            }}
            onChange={
              (e) => {
                // запрет ввода нетого
                handleInputChangeStop(e, patternNameCity, setPopPoint2);
                // поиск по списку данных
                searchInArrayElement(e, dataPopulatedPointArr2, indexСhange(typeOfDistrictDinamic, 2, 3), setPopulatedPointArr2);
                // очистка поля для отправки
                setPopPointToSend2('');
              }
            }
            placeholder='Введите название населенного пункта'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отпраки
              setPopPoint2('');
              setPopPointToSend2('');

              // восстанавливаем список данных после поиска
              setPopulatedPointArr2(dataPopulatedPointArr2);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectStrit2({});
              setRequestObjectHouseNumber2({});
              setRequestObjectApartmentNumber2({});
              // ======================

              // очищаем поля вниз по иерархии
              setStritArr2([]);
              setStrit2('');
              // ======================
              setHouseNumberArr2([]);
              setHouseNumber2('');
              // ======================
              setApartmentNumberArr2([]);
              setApartmentNumber2('');
              // ======================
              setFiasDataObj2({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids2(prevState => (
                {
                  ...prevState,
                  guid23populatedpoint: '',
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              populatedPointArr2 ? populatedPointArr2.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setPopPoint2);
                    setElem(e, setPopPointToSend2);

                    // помещаем сюда данные для отправки
                    setFiasDataObj2(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids2(prevState => (
                      {
                        ...prevState,
                        guid34street: '',
                        guid45housenumber: '',
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids2(prevState => ({ ...prevState, guid23populatedpoint: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setPopulatedPointArr2(dataPopulatedPointArr2);

                    // формируем объект для тела запроса данных
                    setRequestObjectStrit2({ "address_type": 2, "path": `${item.path}` });

                    // очищаем поля параметров запрсов вниз по иерархии
                    setRequestObjectHouseNumber2({});
                    setRequestObjectApartmentNumber2({});
                    // ======================

                    // очищаем поля вниз по иерархии
                    setStritArr2([]);
                    setStrit2('');
                    // ======================
                    setHouseNumberArr2([]);
                    setHouseNumber2('');
                    // ======================
                    setApartmentNumberArr2([]);
                    setApartmentNumber2('');
                    // ======================

                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas2 === 0 ?
                      item.hierarchy[2] ? item.hierarchy[2].full_name_short : 'нет данных'
                      :
                      item.hierarchy[3] ? item.hierarchy[3].full_name_short : 'нет данных'
                  }
                </li>
              ) : <li className={st.item_search}>ошибка получения н.п.</li>
            }
          </ul>
        </div>
      </div>

      {/* инпут улица */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={strit2 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор улицы</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='strit2'
            value={strit2}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectStrit2).length)
                fetchGetDataFias(requestObjectStrit2, setDataStritArr2, setStritArr2)
            }}
            onChange={
              (e) => {
                // запрет ввода нетого
                handleInputChangeStop(e, patternNameCity, setStrit2);
                // поиск по списку данных
                searchInArrayElement(e, dataStritArr2, indexСhange(typeOfDistrictDinamic, 3, 4), setStritArr2);
                // очистка поля для отправки
                setStritToSend2('');
              }
            }
            placeholder='Введите название улицы'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отпраки
              setStrit2('');
              setStritToSend2('');

              // восстанавливаем список данных после поиска
              setStritArr2(dataStritArr2);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectHouseNumber2({});
              setRequestObjectApartmentNumber2({});
              // ======================

              // очищаем поля вниз по иерархии
              setHouseNumberArr2([]);
              setHouseNumber2('');
              // ======================
              setApartmentNumberArr2([]);
              setApartmentNumber2('');
              // ======================
              setFiasDataObj2({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids2(prevState => (
                {
                  ...prevState,
                  guid34street: '',
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              stritArr2.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setStrit2);
                    setElem(e, setStritToSend2);

                    // помещаем сюда данные для отправки
                    setFiasDataObj2(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids2(prevState => (
                      {
                        ...prevState,
                        guid45housenumber: '',
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids2(prevState => ({ ...prevState, guid34street: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setStritArr2(dataStritArr2);

                    // формируем объект для тела запроса данных
                    setRequestObjectHouseNumber2({ "address_levels": [10], "address_type": 2, "path": `${item.path}` });

                    // очищаем поля параметров запрсов вниз по иерархии
                    setRequestObjectApartmentNumber2({});
                    // ======================

                    // очищаем поля вниз по иерархии
                    setHouseNumberArr2([]);
                    setHouseNumber2('');
                    // ======================
                    setApartmentNumberArr2([]);
                    setApartmentNumber2('');
                    // ======================

                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas2 === 0 ?
                      item.hierarchy[3] ? item.hierarchy[3].full_name_short : 'нет данных' // показываем при выборе г.о.
                      :
                      item.hierarchy[4] ? item.hierarchy[4].full_name_short : 'нет данных' // показываем при выборе м.р-н
                  }
                </li>
              )
            }
          </ul>
        </div>
      </div>

      {/* инпут номер дома (здание (строение), сооружение) */}
      <div className={`${st.wrap_title} ${st.mb30}`}>
        <p className={houseNumber2 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор номер дома</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='houseNumber2'
            value={houseNumber2}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectHouseNumber2).length)
                fetchGetDataFias(requestObjectHouseNumber2, setDataHouseNumberArr2, setHouseNumberArr2)
            }}

            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternPostalIndex, setHouseNumber2);
              // поиск по списку данных
              searchInArrayElement(e, dataHouseNumberArr2, indexСhange(typeOfDistrictDinamic, 4, 5), setHouseNumberArr2);
              // очистка поля для отправки
              setHouseNumberToSend2('');
            }}
            placeholder='Введите номер дома'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setHouseNumber2('');
              setHouseNumberToSend2('');

              // восстанавливаем список данных после поиска
              setHouseNumberArr2(dataHouseNumberArr2);

              // очищаем поля параметров запрсов вниз по иерархии
              setRequestObjectApartmentNumber2({});

              // очищаем поля вниз по иерархии
              setApartmentNumberArr2([]);
              setApartmentNumber2('');
              // ======================
              setFiasDataObj2({});
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids2(prevState => (
                {
                  ...prevState,
                  guid45housenumber: '',
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              houseNumberArr2.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setHouseNumber2);
                    setElem(e, setHouseNumberToSend2);

                    // помещаем сюда данные для отправки
                    setFiasDataObj2(item);

                    // GUIDS
                    // предварительная очистка нижних по иерархии
                    setGuids2(prevState => (
                      {
                        ...prevState,
                        guid56apartmentnumber: ''
                      }
                    ));

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids2(prevState => ({ ...prevState, guid45housenumber: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setHouseNumberArr2(dataHouseNumberArr2);

                    // формируем объект для тела запроса данных
                    setRequestObjectApartmentNumber2({ "address_type": 2, "path": `${item.path}` });

                    // очищаем поля вниз по иерархии
                    setApartmentNumberArr2([]);
                    setApartmentNumber2('');
                    // ======================

                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas2 === 0 ?
                      (item.hierarchy[4] ? item.hierarchy[4].full_name_short : 'нет данных') // показываем при выборе г.о.
                      :
                      (item.hierarchy[5] ? item.hierarchy[5].full_name_short : 'нет данных') // показываем при выборе м.р-н
                  }
                </li>
              )
            }
          </ul>
        </div>
      </div>

      {/* инпут помещения */}
      <div className={`${st.wrap_title}`}>
        <p className={apartmentNumber2 === '' ? st.wrap_title_p_hide : st.wrap_title_p}>
          Выбор помещения</p>

        <div className={st.input_search_wrap}>
          <input
            className={st.input_search}
            type='text'
            name='apartmentNumber2'
            value={apartmentNumber2}
            onClick={() => {
              // отправляем запрос на получение данных этого поля и вставляем полученное в отображающийся список
              if (Object.keys(requestObjectApartmentNumber2).length)
                fetchGetDataFias(requestObjectApartmentNumber2, setDataApartmentNumberArr2, setApartmentNumberArr2)
            }}
            onChange={(e) => {
              // запрет ввода нетого
              handleInputChangeStop(e, patternPostalIndex, setApartmentNumber2);
              // поиск по списку данных
              searchInArrayElement(e, dataApartmentNumberArr2, indexСhange(typeOfDistrictDinamic, 5, 6), setApartmentNumberArr2);
              // очистка поля для отправки
              setApartmentNumberToSend2('');
            }}
            placeholder='Введите номер помещения'
          />

          <div
            className={st.input_reset}
            key={uuidv4()}
            onMouseDown={() => {
              // очищаем текущие поля инпута и отправки
              setApartmentNumber2('');
              setApartmentNumberToSend2('');

              // восстанавливаем список данных после поиска
              setApartmentNumberArr2(dataApartmentNumberArr2);

              // очищаем поля параметров запрсов вниз по иерархии
              // setRequestObjectНизшийУровеньОтсутствует({});

              // очищаем поля вниз по иерархии
              // setНизшийУровеньОтсутствуетArr2([]);
              // setНизшийУровеньОтсутствует2('');
              // ======================

              // очистка всех guids кроме верхних по иерархии
              setGuids2(prevState => (
                {
                  ...prevState,
                  guid56apartmentnumber: ''
                }
              ));
            }}>
            Сброс ввода
          </div>

          <ul className={st.list_search}>
            {
              apartmentNumberArr2.length ? apartmentNumberArr2.map(item =>
                <li
                  className={st.item_search}
                  key={uuidv4()}
                  onMouseDown={(e) => {
                    // получаем выбранное значение в инпуте и в поле для отправки
                    setElem(e, setApartmentNumber2);
                    setElem(e, setApartmentNumberToSend2);

                    // помещаем сюда данные для отправки
                    setFiasDataObj2(item);

                    // GUIDS
                    // помещаем item.object_guid в новый массив guids
                    setGuids2(prevState => ({ ...prevState, guid56apartmentnumber: item.object_guid }));

                    // восстанавливаем список данных после поиска
                    setApartmentNumberArr2(dataApartmentNumberArr2);

                    // формируем объект для тела запроса данных
                    // формировать объект для тела запроса данных не нужно т.к. следущего запроса не будет

                    // очищаем поля вниз по иерархии
                    // setНизшийУровеньОтсутствуетArr2([]);
                    // setНизшийУровеньОтсутствует2('');
                    // ======================


                    removeFocuse();
                  }}>
                  {
                    operationIdMunicipalAreas2 === 0 ?
                      item.hierarchy[5] ? item.hierarchy[5].full_name_short : 'нет данных' // показываем при выборе г.о.
                      :
                      item.hierarchy[6] ? item.hierarchy[6].full_name_short : 'нет данных' // показываем при выборе м.р-н
                  }
                </li>
              ) : <li className={st.item_search} key={uuidv4()}>Помещений нет</li>
            }
          </ul>
        </div>
      </div>
    </div>
  )
};
